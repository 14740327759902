import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const SubscriptionPlansOptionsSkeleton = () =>{
    return(
        <SkeletonTheme>
            <div className="subscription-plans-options">
                <div className='subscription-plans-options-single'>
                    <Skeleton width={350} height={300} />
                </div>
                <div className='subscription-plans-options-single'>
                    <Skeleton width={350} height={400} />
                </div>
                <div className='subscription-plans-options-single'>
                    <Skeleton width={350} height={300} />
                </div>
            </div>
        </SkeletonTheme>
    )
}

export default SubscriptionPlansOptionsSkeleton