import { createSlice } from "@reduxjs/toolkit";
import conversationsService from "../../services/conversationsService";

const initialState = {
    allConversations: [],
    userConversations: [],
    selectedOutlinkConversationButton: 'current',
    selectedBacklinkConversationButton: 'current',
    loading: false,
    userConversationsLoading: false,
    error: null,
    userConversationsError: null
}

const conversationReducer = createSlice({
    name: 'conversations',
    initialState,
    reducers:{
        setConversations (state, action){
            state.allConversations = action.payload
        },
        setUserConversations (state, action){
            state.userConversations = action.payload
        },
        postConversation (state, action){
            state.userConversations = [...state.userConversations, action.payload]
        },
        updateConversation (state, action){
            const newState = state.userConversations.map( conversation =>
                conversation.id === action.payload.id? action.payload: conversation
            )
            state.userConversations = [...newState]
        },
        archiveConversation(state, action){
            const newState = state.userConversations.map( conversation =>
                conversation.id === action.payload.id? action.payload: conversation
            )
            state.userConversations = [...newState]
        },
        deleteConversation (state, action){
            state.userConversations = state.userConversations.filter( conversation => conversation.id !== action.payload)
        },
        setSelectedOutlinkConversationButton (state, action){
            state.selectedOutlinkConversationButton = action.payload
        },
        setSelectedBacklinkConversationButton (state, action){
            state.selectedBacklinkConversationButton = action.payload
        },
        setLoading (state, action){
            state.loading = action.payload
        },
        setUserConversationsLoading(state, action){
            state.userConversationsLoading = action.payload
        },
        setError (state, action){
            state.error = action.payload
        },
        setUserConversationsError(state, action){
            state.userConversationsError = action.payload
        }
    }
})

export const {  
    setConversations, 
    setUserConversations, 
    postConversation, 
    updateConversation, 
    archiveConversation,
    deleteConversation, 
    setSelectedOutlinkConversationButton,
    setSelectedBacklinkConversationButton,
    setLoading, 
    setUserConversationsLoading,
    setError,
    setUserConversationsError
} = conversationReducer.actions

// Initialize all conversations
export const initializeConversations = () =>{
    return async dispatch => {
        dispatch(setLoading(true))
        
        try {
            const response = await conversationsService.getAll()
            dispatch(setConversations(response.data))
            dispatch(setLoading(false))
        } catch (error) {
            dispatch(setError(error.message))
        }
    }
}

// Initialize user conversations
export const initializeUserConversations = (id) =>{
    return async dispatch => {
        dispatch(setUserConversationsLoading(true))

        try {
            const response = await conversationsService.getUserConversations(id)
            dispatch(setUserConversations(response.data))
            dispatch(setUserConversationsLoading(false))
        } catch (error) {
            dispatch(setUserConversationsError(error.message))
        }
    }
}

// Post new conversation
export const handlePostConversation = (data, config) =>{
    return async dispatch =>{
        try {
            const response = await conversationsService.postConversation(data, config)
            dispatch(postConversation(response.data))
        } catch (error) {
            dispatch(setError(error.message))
        }
    }
}

// Delete a conversation
export const handleDeleteConversation = (id, config) =>{
    return async dispatch =>{
        try {
            await conversationsService.deleteConversation(id, config)
            dispatch(deleteConversation(id))
        } catch (error) {
            dispatch(setError(error.message))
        }
    }
}

// Archive conversation
export const handleArchiveConversation = (id, data, config) =>{
    return async dispatch =>{
        try {
            const response = await conversationsService.archiveConversation(id, data, config)
            dispatch(archiveConversation(response.data))
        } catch (error) {
            dispatch(setError(error.message))
        }
    }
}

export default conversationReducer.reducer