import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getWebsiteName } from '../../../../helpers/helpers'

import {TbWorld} from 'react-icons/tb'

const WebsiteOverviewListSingle = ({ website }) =>{
    // Store
    const allLinks = useSelector(({ links })=> links.allLinks)

    // Find number of outlinks and backlinks for this website
    const linksData = useMemo(()=>{
        const backlinks = allLinks.filter( link => link.targetWebsite && link.targetWebsite.id === website.id)
        const outlinks = allLinks.filter( link => link.sourceWebsite && link.sourceWebsite.id === website.id)

        return {
            backlinks: backlinks.length,
            outlinks: outlinks.length
        }
    }, [allLinks])

    return(
        <div className="website-overview-list-items-single">
            <div className='website-overview-list-items-single-icon'><TbWorld /></div>
            <Link 
                to={`/profile/website-overview/${website.url}`}
                className='website-overview-list-items-single-link'
            >
                {getWebsiteName(website.url)}
            </Link>
            <div>{linksData.outlinks}</div>
            <div>{linksData.backlinks}</div>
        </div>
    )
}

export { WebsiteOverviewListSingle }