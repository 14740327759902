const ProfileWebsiteCheckboxSingle = ({ checkboxValue, selectedItems, setSelectedItems }) => {
    // Check if the category is already selected
    const isChecked = selectedItems.includes(checkboxValue)

    // Handle check box click
    const handleCheckBox = (e) => {
        const isChecked = e.target.checked

        // Temporary array
        let updatedItems = []

        if (isChecked) {
            updatedItems = [...selectedItems, e.target.value]
        } else {
            updatedItems = selectedItems.filter(category => category !== e.target.value)
        }

        // Update selected categories
        setSelectedItems(updatedItems)
    }

    return (
        <div className={`${isChecked? "profile-website-languages-single-active" : "profile-website-languages-single"}`}>
            <input type="checkbox" value={checkboxValue} checked={isChecked} onChange={handleCheckBox} />
            <label>{checkboxValue}</label>
        </div>
    );
};

export { ProfileWebsiteCheckboxSingle }