import { useState } from "react"

import waitlistService from '../../services/waitlistService'
import emailService from "../../services/emailService";

function useWaitlistSubmission (){
    // Local state
    const [email, setEmail] = useState("");
    const [emailIsValid, setEmailIsValid] = useState(true);
    const [success, setSuccess] = useState(null)
    const [error, setError] = useState(null)
    const [validationError, setValidationError] = useState(null)

    // Handle form submission
    const handleSubmit = async (e) =>{
        e.preventDefault()

        // If email is valid, submit form creating the entry
        if(emailIsValid){
            try {
                // Send confirmation email
                const emailResponse = await emailService.waitlistEmail({ email })

                // Check if email was sent successfully
                if(emailResponse.data.status !== 200){
                    setValidationError(emailResponse.data.details)
                } else{
                    // Send request to save details
                    await waitlistService.postWaitlist({ email })

                    // Show success message
                    setSuccess(true)
                    setTimeout(() => {
                        setSuccess(null)
                    }, 3000);
                }

            } catch (error) {                
                setError(error.message)
                setTimeout(() => {
                    setError(null)
                }, 3000);
            }

            // Reset field
            setEmail("")
        }
    }

    // Return values
    return {
        email,
        setEmail,
        emailIsValid,
        setEmailIsValid,
        success,
        error,
        validationError,
        handleSubmit,
    }
}

export default useWaitlistSubmission