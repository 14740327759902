import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import { initializeNiches } from '../../store/reducers/nicheReducer'

import './register.css'

import logo from '../../resources/logo-link.png'

import RegisterForm from './RegisterForm'
import { DashboardFooter } from '../Footer/DashboardFooter'
import { Spinner } from '../../common/Spinner'

const RegisterPage = () =>{
    // Store 
    const dispatch = useDispatch()
    const loading = useSelector(({ niches }) => niches.loading)
    const error = useSelector(({ niches }) => niches.error)

    // Initialize niches for store
    useEffect(()=>{
        dispatch(initializeNiches())
    }, [dispatch])

    // Handle errors and loading
    if(error) return <p>{error}</p>
    if (loading) return <Spinner />

    return(
        <div className="register">
            <Helmet>
                <title>Register page</title>
                <meta name='description' content='Likeny registration page, create your free account' />
            </Helmet>

            <div className="register-content-wrapper">
                <img id='logo' src={logo} alt="logo" />
                <h5>Create your account</h5>    
                <p className='register-form-p'>Gain access to all our features by signing up.</p>

                <RegisterForm />

                <span>Already have an account? <Link to="/login" className='register-login-btn'>Log in</Link></span>
                <div className="register-terms">By signing up you agree to our terms of service and privacy policy.</div>
            </div>

            <DashboardFooter />
        </div>
    )
}

export default RegisterPage