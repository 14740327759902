const BacklinksTableItemSingleSource = ({ connection }) =>{
    return(
        <div className="backlinks-table-items-single-source">
            <a
                className="backlinks-table-items-single-link"
                href={`https://${connection.targetWebsite.url}`}
                target="_blank"
                rel="noopener noreferrer"
            >
                {connection.targetWebsite.url}
            </a>

            <a 
                href={`https://${connection.sourceUrl}`}
                className="backlinks-table-items-single-link"
                target="_blank"
                rel="noopener noreferrer"
            >
                {connection.sourceUrl}
            </a>
        </div>
    )
}

export { BacklinksTableItemSingleSource }