import { createSlice } from "@reduxjs/toolkit";
import websiteService from "../../services/websiteService";

const initialState = {
    allWebsites: [],
    userWebsites: [],
    allWebsitesloading: true,
    userWebsitesLoading: true,
    error: null,
    userWebsitesError: null
}

const websiteSlice = createSlice({
    name: 'websites',
    initialState,
    reducers:{
        setAllWebsites (state, action){
            state.allWebsites = action.payload
        },
        setUserWebsites (state, action){
            state.userWebsites = action.payload
        },
        clearUserWebsites (state, action){
            state.userWebsites = []
        },
        addFirstWebsite (state, action){
            state.userWebsites = [...state.userWebsites, action.payload]
        },
        addWebsite(state, action){
            state.userWebsites = [...state.userWebsites, action.payload]
        },
        deleteWebsite(state, action){
            state.userWebsites = state.userWebsites.filter( website => website.id !== action.payload.id )
        },
        updateWebsite (state, action){
            const updatedWebsite = action.payload

            const websiteIndex = state.userWebsites.findIndex( website => website.id === updatedWebsite.id )

            if (websiteIndex !== -1) {
              state.userWebsites[websiteIndex] = updatedWebsite
            }
        },
        setAllWebsitesLoading (state, action){
            state.allWebsitesloading = action.payload
        },
        setUserWebsitesLoading (state, action){
            state.userWebsitesLoading = action.payload
        },
        setError(state, action) {
            state.error = action.payload
        },
        setUserWebsitesError(state, action){
            state.userWebsitesError = action.payload
        }
    }
})

export const { 
    setAllWebsites, 
    setUserWebsites, 
    clearUserWebsites, 
    updateWebsite, 
    addWebsite, 
    addFirstWebsite, 
    deleteWebsite, 
    setError, 
    setUserWebsitesError,
    setAllWebsitesLoading, 
    setUserWebsitesLoading 
} = websiteSlice.actions

// Initialize all websites
export const initializeAllWebsites = () =>{
    return async dispatch => {
        try {
            const response = await websiteService.getAll()
            dispatch(setAllWebsites(response.data))
            dispatch(setAllWebsitesLoading(false))
        } catch (error) {
            dispatch(setError(error.message))
        }
    }
}

// Initialize user websites
export const initializeUserWebsites = (id, config) =>{
    return async dispatch => {
        try {
            const response = await websiteService.getUserWebsites(id , config)
            dispatch(setUserWebsites(response.data))
            dispatch(setUserWebsitesLoading(false))
        } catch (error) {
            dispatch(setUserWebsitesError(error.message))
        }
    }
}

// Add first website after registering
export const handleAddFirstWebsite = (data) =>{
    return async dispatch => {
        try {
            const response = await websiteService.postFirstWebsite(data)
            dispatch(addFirstWebsite(response.data))
        } catch (error) {
            dispatch(setError(error.message))
        }
    }
}

// Add website
export const handleAddWebsite = (data, config) => {
    return async dispatch => {
        try {
            const response = await websiteService.postWebsite(data, config)
            dispatch(addWebsite(response.data))
        } catch (error) {
            dispatch(setError(error.message))
        }
    }
}

// Delete website
export const handleDeleteWebsite = (id, config) =>{
    return async dispatch =>{
        try {
            const response = await websiteService.deleteWebsite(id, config)
            dispatch(deleteWebsite(response.data))
        } catch (error) {
            dispatch(setError(error.message))
        }
    }
}

// Update website
export const updateWebsiteRequest = (id, data, config) => {
    return async dispatch => {
        try {
            const response = await websiteService.updateWebsite(id, data, config)
            dispatch(updateWebsite(response.data))
        } catch (error) {
            dispatch(setError(error.message))
        }
    }
}

export default websiteSlice.reducer