import { useState } from 'react'
import { Link } from 'react-router-dom'
import './features.css'


import { FeaturesSingle } from './FeaturesSingle'

import { TbMilitaryRank } from 'react-icons/tb'
import { IoIosPeople } from 'react-icons/io'
import { BiTimeFive } from 'react-icons/bi'
import { SiGoogleanalytics } from 'react-icons/si'
import { MdConnectWithoutContact } from 'react-icons/md'

import ui1 from '../../../resources/samples/ui-sample-1.jpg'
import ui2 from '../../../resources/samples/ui-sample-2.jpg'

export default function Features (){
    // Conditionally render waitlist or login section
    const sectionToRender = () =>{
        if(process.env.REACT_APP_API_APP_STAGE === 'waitlist'){
            return <Link to="/waitlist" className='features-bottom-link'>Try now</Link>
        }

        return <Link to="/register" className='features-bottom-link'>Try now</Link>
    }

    return(
        <div className="features">
            <div className="features-top">
                <span className='landing-section-title'>Features</span>
                <h1>Ecosystem for digital entrepreneurs</h1>
                <p>Wondering how our platform will help your business? Here's an overview of the key features that will help your business.</p>
            </div>

            <div className="features-single-wrapper">
                <div className='features-single-wrapper-content'>
                    {features.map( (feature, i) =>
                        <FeaturesSingle key={i} title={feature.title} content={feature.content} icon={feature.icon} />
                    )}
                </div>

                <div className='features-single-wrapper-img'>
                    <img src={ui1} alt="test" />
                    <img src={ui2} alt="test" />
                </div>
            </div>

            <div className="features-bottom">
                <p>Get the best app for boosting your website. Try it yourself!</p>
                {sectionToRender()}
            </div>
        </div>
    )
}

const features = [
    {
        title: "Boost SEO Rankings",
        content: "Reciprocal backlinks are a proven strategy to improve your website's search engine ranking, making it easier for your target audience to find you.",
        icon: <TbMilitaryRank />
    },
    {
        title: "High-Quality Partners",
        content: "Connect with credible and relevant websites, ensuring that the backlinks you receive are from trusted sources in your niche.",
        icon: <IoIosPeople />
    },
    {
        title: "Save Time and Effort",
        content: "Say goodbye to the tedious process of manual outreach and negotiation. LinkSharePro streamlines the backlink exchange process, saving you valuable time.",
        icon: <BiTimeFive />
    },
    {
        title: "Detailed Analytics",
        content: "Access comprehensive analytics to track the performance of your backlinks and make data-driven decisions to optimize your SEO strategy.",
        icon: <SiGoogleanalytics />
    },
    {
        title: "Build Relationships",
        content: "Forge valuable relationships with other webmasters in your industry, fostering collaboration and mutual growth.",
        icon: <MdConnectWithoutContact />
    }
]