import { useState } from "react"
import './tooltip.css'

const Tooltip = ({ text, direction, children }) =>{
    // State
    const [showTooltip, setShowTooltip] = useState(false)

    // Toggle visibility
    const toggleShowTooltip = () =>{
        setShowTooltip(true)
    }

    const toggleHideTooltip = () =>{
        setShowTooltip(false)
    }

    return(
        <div className="tootip-wrapper">
            <span onMouseEnter={toggleShowTooltip} onMouseLeave={toggleHideTooltip}>
                {children}
            </span>

            {showTooltip && (
                <div className={`tooltip-text-${direction}`}>
                    {text}
                </div>
            )}
        </div>
    )
}

export { Tooltip }
