import { useState } from "react"

import ProfileWebsitesAddForm from "./ProfileWebsitesAddForm"
import { WebsiteOverviewAddButton } from "../../../WebsiteOverview/WebsiteOverviewAddButton"

const ProfileWebsitesAdd = ({ setShowAddMessage }) =>{   
    // State
    const [formIsOpen, setFormIsOpen] = useState(false)

    return(
        <div className="profile-websites-add">
            <p className="profile-websites-add-text">Add a new website. You can have as many websites as you choose with lower fees for any additional website.</p>

            <div className="profile-websites-add-btn-wrapper">
                <WebsiteOverviewAddButton setFormIsOpen={setFormIsOpen} />
            </div>

            {/* Add website form */}
            {formIsOpen? <ProfileWebsitesAddForm setFormIsOpen={setFormIsOpen} setShowAddMessage={setShowAddMessage} />: ""}
        </div>
    )
}

export default ProfileWebsitesAdd