import { createSlice } from "@reduxjs/toolkit";
import messagesService from "../../services/messagesService";
import { updateConversation } from "./conversationReducer";

/**
 * 1) Action for updating message as opened
 */

const initialState = {
    userMessages: [],
    conversationMessages: [],
    loading: false,
    error: null,
    postMessageError: null,
    firstStageMessageError: null,
    conversationMessagesLoading: false,
    conversationMessagesError: null,
    deleteMessageError: null,
    openMessagesError: null
}

const messageSlice = createSlice({
    name: 'messages',
    initialState,
    reducers:{
        setUserMessages(state,action){
            state.userMessages = action.payload
        },
        setConversationMessages(state, action){
            state.conversationMessages = action.payload
        },
        postNewMessage (state, action){
            state.conversationMessages = [...state.conversationMessages, action.payload]
        },
        deleteMessage (state, action){
            const newMessages = state.conversationMessages.filter( message => message.id !== action.payload.id )
            state.conversationMessages = [...newMessages]
        },
        openMessage (state, action){
            state.conversationMessages = action.payload
        },
        setLoading(state, action){
            state.loading = action.payload
        },
        setConversationMessagesLoading(state, action){
            state.conversationMessagesLoading = action.payload
        },
        setError(state, action){
            state.error = action.payload
        },
        setPostMessageError(state, action){
            state.postMessageError = action.payload
        },
        setConversationMessagesError(state, action){
            state.conversationMessagesError = action.payload
        },
        setDeleteMessageError(state, action){
            state.deleteMessageError = action.payload
        },
        setOpenMessagesError(state, action){
            state.openMessagesError = action.payload
        }
    }
})

export const { 
    setUserMessages, 
    setConversationMessages,
    postNewMessage, 
    deleteMessage, 
    openMessage, 
    setLoading, 
    setConversationMessagesLoading,
    setError,
    setPostMessageError,
    setConversationMessagesError,
    setDeleteMessageError,
    setOpenMessagesError
} = messageSlice.actions

// Initialize user messages
export const initializeUserMessages = (id, config) => {
    return async dispatch =>{
        dispatch(setLoading(true))
        
        try {
            const response = await messagesService.getUserMessages(id, config)

            dispatch(setUserMessages(response.data))
            dispatch(setLoading(false))
        } catch (error) {
            dispatch(setError(error.message))
        }
    }
}

// Initialize conversation messages 
export const initializeConversationMessages = (id, config) =>{
    return async dispatch =>{
        dispatch(setConversationMessagesLoading(true))

        try {
            const response = await messagesService.getConversationMessages(id, config)

            dispatch(setConversationMessages(response.data))
            dispatch(setConversationMessagesLoading(false))
        } catch (error) {
            dispatch(setConversationMessagesError(error.message))
        }
    }
}

// Post new message
export const handleNewMessage = (data, config) =>{
    return async dispatch => {
        try {
            const response = await messagesService.postMessage(data, config)

            dispatch(postNewMessage(response.data.populatedMessage))
            dispatch(updateConversation(response.data.populatedConversation))
        } catch (error) {
            console.log(error)
            dispatch(setPostMessageError(error.message))
        }
    }
}

// Delete message
export const handleDeleteMessage = (id, config) =>{
    return async dispatch =>{
        try {
            const response = await messagesService.deleteMessage(id, config)
            dispatch(deleteMessage(response.data))
        } catch (error) {
            dispatch(setDeleteMessageError(error.message))
        }
    }
}

// Set message as opened
export const handleMessageOpen = (conversationId, config) =>{
    return async dispatch =>{
        try {
            const response = await messagesService.updateMessagesAsOpened(conversationId, {}, config)
            dispatch(openMessage(response.data))
        } catch (error) {
            dispatch(setOpenMessagesError(error.message))
        }
    }
}

export default messageSlice.reducer