import { useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getNotificationDate } from '../../helpers/helpers'
import { handleNotificationDelete } from '../../store/reducers/notificationReducer'
import { config } from '../../apiConfig'
 
// Import icons
import {AiOutlineClose} from 'react-icons/ai'
import {MdMiscellaneousServices} from 'react-icons/md'
import {MdConnectWithoutContact} from 'react-icons/md'
import {AiOutlineMessage} from 'react-icons/ai'

const NotificationSingle = ({ notification }) =>{
    // Store
    const currentUser = useSelector(({ currentUser }) => currentUser)
    const dispatch = useDispatch()

    // State
    const [notificationHovered, setNotificationHovered] = useState(false)

    const timestamp = useMemo(()=>{
        return getNotificationDate(notification.createdAt)
    }, [])

    // Handle notification delete button
    const handleDelete = () =>{
        dispatch(handleNotificationDelete(notification.id, config(currentUser.token)))
    }
    
    return(
        <div className='notification-single' onMouseEnter={()=>{setNotificationHovered(true)}} onMouseLeave={()=>{setNotificationHovered(false)}}>
            <div className="notification-single-dot"></div>
            
            <div className="notification-single-content">
                {/* <h1>{notification.title}</h1> */}
                <p>{notification.text}</p>
                <small>{timestamp}</small>
            </div>

            <div className="notification-single-img">
                {notification.typeOfNotification === "general" && <MdMiscellaneousServices />}
                {notification.typeOfNotification === "connections" && <MdConnectWithoutContact />}
                {notification.typeOfNotification === "messages" && <AiOutlineMessage />}
            </div>

            {notificationHovered &&
            <button className="notification-single-delete-btn" onClick={handleDelete}>
                <AiOutlineClose />
            </button>}
        </div>
    )
}

export default NotificationSingle