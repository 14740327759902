import { Link } from "react-router-dom"
import { useDispatch } from "react-redux"
import { setMobileSidebarIsOpen } from "../../store/reducers/sidebarReducer"

import logo from '../../resources/logo-link.png'
import {FaBars} from 'react-icons/fa'

import NavUser from "../Nav/User/NavUser"

 
const ProfileNav = () =>{
    // Store 
    const dispatch = useDispatch()

    return(
        <nav className="profile-nav">
            <Link to="/" className="logo">
                <img src={logo} alt="logo" />
                <h1>LinkeBy</h1>
            </Link>
            
            <div className="profile-nav-user">
                <NavUser />
                <button className="nav-user-menu-btn" onClick={()=> dispatch(setMobileSidebarIsOpen(true)) }><FaBars /></button>
            </div>
        </nav>
    )
}

export default ProfileNav