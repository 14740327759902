import { createSlice } from '@reduxjs/toolkit'

// Initialize currentUser to after checking localstorage
const initialState = JSON.parse(localStorage.getItem('currentUser')) || {};

const currentUserSlice = createSlice({
    name: 'currentUser',
    initialState,
    reducers:{
        updateCurrentUser(state, action){
            return action.payload
        },
        clearCurrentUser (state, action){
            return {};
        }
    }
})

export const { updateCurrentUser, clearCurrentUser } = currentUserSlice.actions
export default currentUserSlice.reducer