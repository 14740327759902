import { useSelector, useDispatch } from 'react-redux'
import { setShowChat } from '../../store/reducers/backlinksReducer'

import {BiConversation} from 'react-icons/bi'
import {IoIosArrowDown} from 'react-icons/io'

const BacklinksChatButton = () =>{
    // Store
    const dispatch = useDispatch()
    const showChat = useSelector(({ backlinks }) => backlinks.showChat)
    const activeConnection = useSelector(({ backlinks }) => backlinks.activeConnection)

    // If a connection is not selected, do not render anything
    if(!activeConnection) return null

    return(
        <button 
            className='conversation-chat-btn' 
            onClick={()=>{ dispatch(setShowChat(!showChat)) }}
        >
            {showChat? <IoIosArrowDown />: <BiConversation />}
        </button>
    )
}

export { BacklinksChatButton }