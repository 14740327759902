import { useSelector, useDispatch } from "react-redux"

import { updateWebsiteRequest } from '../../../../../../../../store/reducers/websiteReducer'
import { config } from '../../../../../../../../apiConfig'

const ProfileWebsiteNichesSaveButton = ({ websiteId, selectedCategories, setShowNiches }) =>{
    // Store 
    const dispatch = useDispatch()
    const currentUser = useSelector(({ currentUser }) => currentUser)

    // Save and send the request
    const handleSave = async () =>{
        dispatch(updateWebsiteRequest(websiteId, { niche: selectedCategories }, config(currentUser.token)))
        setShowNiches(false)
    }

    return(
        <button className='profile-websites-languages-save-btn' onClick={handleSave}>Save</button>
    )
}

export { ProfileWebsiteNichesSaveButton }