import { useDispatch, useSelector } from 'react-redux'
import { handleDeleteConnection } from '../../../../../store/reducers/connectionReducer'
import { config } from '../../../../../apiConfig'

import { ErrorMessage } from '../../../../../common/ErrorMessage'

import { AiOutlineClose } from 'react-icons/ai'
import { BiError } from 'react-icons/bi'

const BacklinksTableItemSingleMissing = ({ connectionId }) =>{
    // Store
    const dispatch = useDispatch()
    const currentUser = useSelector(({ currentUser }) => currentUser)
    const error = useSelector(({ connections }) => connections.deleteConnectionError)

    // Handle removing the corrupted connection
    const handleRemoveConnection = async () =>{
        dispatch(handleDeleteConnection(connectionId, config(currentUser.token)))
    }

    // Return message if an error occurs
    if(error) return <ErrorMessage error={error} />

    return(
        <div className="backlinks-table-items-single-missing">
            <div><BiError />  This connection is missing (the connected website does no longer exist)</div>
            <div>
                <button 
                    className='backlinks-table-items-single-missing-btn'
                    onClick={handleRemoveConnection}
                >
                    <AiOutlineClose />
                </button>
            </div>
        </div>
    )
}

export { BacklinksTableItemSingleMissing }