import { useState } from "react"
import { useSelector } from "react-redux"

import { config } from "../../../apiConfig"
import websiteService from "../../../services/websiteService"

// Icons 
import {IoLanguageOutline} from 'react-icons/io5'

// Components
import Options from "../../Forms/Options"


const DatabaseAddNiche = ({ setShowAddNiche, id }) =>{
    // Get categories from store
    const niches = useSelector(({ niches }) => niches.niches)
    const currentUser = useSelector(({ currentUser }) => currentUser)

    // State
    const [niche, setNiche] = useState("")

    // Handle form submit
    const handleSubmit = async (e) =>{
        e.preventDefault()

        // Header and data to send with request
        const data = { niche }

        try {
            // Send request
            await websiteService.activateWebsite(id, data, config(currentUser.token))

            // Reset field and close pop up
            setShowAddNiche(false)
            setNiche("")
        } catch (error) {
            console.log(error)
        }
    }

    return(
        <div className="new-users-comments">
            <form className="new-users-comments-form" onSubmit={handleSubmit}>
                <h1>Add niche</h1>
                <p>Manually add a niche for this website</p>
                <Options icon={<IoLanguageOutline />} list={niches} inputValue={niche} setInputValue={setNiche} />
                <button className="new-users-comments-submit-btn" type="submit">Confirm</button>
                <button className="new-users-comments-close-btn" onClick={()=>{setShowAddNiche(false)}}>X</button>
            </form>
        </div>
    )
}

export default DatabaseAddNiche