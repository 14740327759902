import { useState } from 'react'
import './faq.css'

import { FaqSingle } from './FaqSingle'

const Faq = () =>{
    const [faq, setFaq] = useState([
        {
            title: 'Do I not have to pay for the backlinks?',
            text: 'No, you pay a monthly service and the websites you connect with should and will not ask for money (against our TOC)',
            id:1
        },
        {
            title: 'I just launched my website, can I sign up?',
            text: 'Yes, but make sure you have at least content on your website (e.g. 5 blog posts)',
            id:2
        },
        {
            title: 'What kind of links are we talking about?',
            text: 'We are basically talking about niche edits. However, there is the option for guest posts, image links, and other types of backlinks when a link opportunity arises.',
            id:3
        },
        {
            title: 'What happens when I cancel my account?',
            text: 'Your backlinks will not disappear, but we can’t promise they will stay forever, as the benefit of this portal is that users keep each other accountable that links won’t be removed.',
            id:4
        },
        {
            title: 'I don’t want to link to my competitors or certain niches, is that possible?',
            text: 'Yes, in settings it is possible to exclude certain niches/categories, so you won’t link or get backlinks from websites you don’t want to.',
            id:5
        },
    ])
    
    return(
        <div className="faq">
            <span className='landing-section-title'>FAQ</span>
            <p>What would you like to know about us?</p>

            <div className="faq-content">
                <div className="faq-questions">
                    {faq.map( faq =>
                        <FaqSingle key={faq.id} title={faq.title} text={faq.text} />
                    )}
                </div>
            </div>
        </div>
    )
}

export { Faq }