import { Helmet } from 'react-helmet-async'
import { Link } from 'react-router-dom'

const RegisterAwaitConfirmation = () =>{
    return(
        <div className="register-await-confirmation">
            <Helmet>
                <title>Verify your email adress</title>
                <meta name='description' content='Verify your email to complete your registration' />
            </Helmet>
            
            <h1>Let's verify your email</h1>
            <p>Check your email inbox to verify your account and get started</p>
            <div>
                <button>Resend email</button>
            </div>
            <small>Need help? Visit our <Link to="/support">support page</Link></small>
        </div>
    )
}

export default RegisterAwaitConfirmation