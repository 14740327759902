import { useDispatch, useSelector } from 'react-redux'
import { setSearchKey } from '../../../store/reducers/backlinksReducer'

import { AiOutlineCloseCircle } from 'react-icons/ai'

const BacklinksTopSearch = () =>{
    // Store
    const dispatch = useDispatch()
    const searchKey = useSelector(({ backlinks }) => backlinks.searchKey)

    // Handle seatch query change
    const handleChange = (e) =>{
        const searchQuery = e.target.value.toLowerCase()

        dispatch(setSearchKey(searchQuery))
    }

    // Handle resetting the search query
    const handleSearchReset = () =>{
        dispatch(setSearchKey(""))
    }

    return(
        <div className='backlinks-top-search'>
            <input 
                value={searchKey} 
                type="text" 
                placeholder="Search backlink" 
                onChange={handleChange}
            />
            {searchKey.length !== 0 && <button onClick={handleSearchReset}><AiOutlineCloseCircle /></button>}
        </div>
    )
}

export { BacklinksTopSearch }