import { useMemo } from "react"
import { useSelector } from "react-redux"

// Components
import ProfileWebsitesSingle from '../../Profile/ProfilePages/ProfileInfo/Pages/Websites/ProfileWebsitesSingle'

const SettingsWebsite = ({ selectedWebsite }) =>{
    // store
    const websites = useSelector(({websites}) => websites.userWebsites )

    // Find website to render from the given url
    const websiteToShow = useMemo(()=>{
        return websites.find( website => website.url === selectedWebsite)
    }, [selectedWebsite, websites])

    // In case the website is not found
    if(!websiteToShow) return <p>A match could not be found</p>

    return(
        <div className="profile-websites">
            <p className="profile-info-details-title">{websiteToShow.url}</p>

            <ProfileWebsitesSingle key={websiteToShow.id} website={websiteToShow} />
        </div>
    )
}

export default SettingsWebsite