import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import './backlinks-messages.css'

import { BacklinksMessagesSingle } from "./BacklinksMessagesSingle"

const BacklinksMessages = ({ role }) =>{
    // Store
    const showChat = useSelector(({ backlinks }) => backlinks.showChat)
    const activeConnection = useSelector(({ backlinks }) => backlinks.activeConnection)
    const conversations = useSelector(({ conversations }) => conversations.userConversations)
    const activeButton = useSelector(({ sidebar }) => sidebar.activeButton)

    // Local state
    const [activeConversation, setActiveConversation] = useState(null)

    // Find the conversation that corresponds to the selected conenction, so we can dispay the appropriate chat
    useEffect(()=>{
        if(activeConnection){
            const foundConversation = conversations.find( conversation =>
                (conversation.targetWebsite?.id === activeConnection.targetWebsite?.id )
                && (conversation.sourceWebsite?.id === activeConnection.sourceWebsite?.id)
            )
    
            if(!foundConversation){
                setActiveConversation(null)
                return;
            }
        
            setActiveConversation({...foundConversation})
        }
    }, [activeConnection, activeButton])

    if(!activeConversation) return null

    return(
        <div className={showChat? "conversation-messages-open": "conversation-messages"}>
            <BacklinksMessagesSingle activeConversation={activeConversation} role={role} />
        </div>
    )
}

export { BacklinksMessages }