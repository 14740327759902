import ProfileInfo from "../Profile/ProfilePages/ProfileInfo/Pages/Info/ProfileInfo"
import ProfileWebsites from "../Profile/ProfilePages/ProfileInfo/Pages/Websites/ProfileWebsites"
import SettingsWebsite from "./Websites/SettingsWebsite"

const SettingsContent = ({ activeSetting, selectedWebsite }) =>{
    return(
        <div className="settings-content">
            {activeSetting === "account" && <ProfileInfo />}
            {activeSetting === "websites" && <SettingsWebsite selectedWebsite={selectedWebsite} />}
        </div>
    )
}

export { SettingsContent }