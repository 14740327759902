import { useState, useEffect } from "react"
import { useSelector } from "react-redux"

import { config } from "../../../apiConfig"
import websiteService from "../../../services/websiteService"
import languagesService from "../../../services/languagesService"

// Icons
import {IoLanguageOutline} from 'react-icons/io5'

// Components
import Options from "../../Forms/Options"

const DatabaseAddLanguage = ({setShowAddLanguage, id}) =>{
    const currentUser = useSelector(({ currentUser }) => currentUser)

    // State
    const [lang, setLang] = useState("")
    const [languages, setLanguages] = useState([])

    // Handle form submit
    const handleSubmit = async (e) =>{
        e.preventDefault()

        // Header and data to send with request
        const data = { languageOfWebsite: lang }

        try {
            // Send request
            await websiteService.activateWebsite(id, data, config(currentUser.token))

            // Reset field and close pop up
            setShowAddLanguage(false)
            setLang("")
        } catch (error) {
            console.log(error)
        }
    }

    // Fetch languages list
    useEffect(()=>{
        let isMounted = true

        const fetchLanguages = async () =>{
            try {
                const response = await languagesService.getAll()
                if(isMounted){
                    setLanguages([...response.data])
                }
            } catch (error) {
                if(isMounted){
                    console.log(error)
                }
            }
        }
        fetchLanguages()

        // Cleanup
        return () => { isMounted = false }
    }, [])

    return(
        <div className="new-users-comments">
            <form className="new-users-comments-form" onSubmit={handleSubmit}>
                <h1>Add Language</h1>
                <p>Manually add a language for this website</p>
                <Options icon={<IoLanguageOutline />} list={languages} inputValue={lang} setInputValue={setLang} />

                <button className="new-users-comments-submit-btn" type="submit">Confirm</button>
                <button className="new-users-comments-close-btn" onClick={()=>{setShowAddLanguage(false)}}>X</button>
            </form>
        </div>
    )
}

export default DatabaseAddLanguage