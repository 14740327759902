import {AiFillCheckCircle} from 'react-icons/ai'
import {AiOutlineClose} from 'react-icons/ai'

const SuccessToast = ({title, subtitle, setSuccess}) =>
{
    return(
        <div className="toast">
            <span className='toast-line'></span>

            <div className='toast-icon'><AiFillCheckCircle /></div>

            <div className="toast-text">
                <p>{title}</p>
                <p>{subtitle}</p>
            </div>

            <button onClick={()=>{setSuccess(false)}}><AiOutlineClose /></button>
        </div>
    )
}

export default SuccessToast