import { AiFillCheckCircle } from 'react-icons/ai'
import { MdOutlineBookmarkAdded } from "react-icons/md";

import BacklinksTableItemSingleStatusNew from './BacklinksTableItemSingleStatusNew'

const BacklinksTableItemSingleStatus = ({ connection }) =>{
    // Create a timestamp for 3 days ago
    const cutOffTimestamp = Date.now() - 7 * 24 * 60 * 60 * 1000

    // If the connection is new return appropriate icon
    if(new Date(connection.createdAt).getTime() >  cutOffTimestamp && connection.approved  !== "finalised") return <BacklinksTableItemSingleStatusNew />

    return(
        <div 
            className={connection.approved === "finalised"? "backlinks-table-items-single-status-approved": "backlinks-table-items-single-status-pending"} 
        >
           <span>{connection.approved  === "finalised"? <MdOutlineBookmarkAdded />: <AiFillCheckCircle />}</span>
        </div>
    )
}

export { BacklinksTableItemSingleStatus }