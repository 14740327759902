import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import sessionService from "../../services/sessionService"
import { config } from "../../apiConfig"

import { clearCurrentUser } from "../../store/reducers/currentUserReducer"
import { clearUserWebsites } from "../../store/reducers/websiteReducer"

// Check if saved user exists and if the token is valid
function useVerifyToken (){
    // Store
    const dispatch = useDispatch()
    const currentUser = useSelector(({ currentUser }) => currentUser)

    useEffect(()=>{
        let isMounted = true
    
        async function verifyToken(){

            // If there is no currentUser do not run the check
            if(Object.keys(currentUser).length === 0){
                console.log("No logged user")
                return
            }
    
            try {
                // Send request with token
                await sessionService.verifyToken({}, config(currentUser.token))
            } catch (error) {
                if(isMounted){
                    // Handle 401 errors
                    if(error.response && (error.response.status === 401 || error.response.status === 404)){
                        // Dispatch actions
                        dispatch(clearCurrentUser())
                        dispatch(clearUserWebsites())
    
                        // Clear localstorage and session
                        localStorage.removeItem('currentUser')
                    } else{ // Handle other errors
                        console.log(error)
                    }
                }
            }    
        }

        // Run function
        verifyToken()

        // Cleanup
        return () => { isMounted = false }
    }, [currentUser])
}

export default useVerifyToken