import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import { setActiveButton } from "../../../../../store/reducers/sidebarReducer"
import { setMobileSidebarIsOpen } from "../../../../../store/reducers/sidebarReducer"
import { ProfileSidebarOptionsDropdownSubButtonNotifications } from "./ProfileSidebarOptionsDropdownSubButtonNotifications"

const ProfileSidebarOptionsDropdownSubButton = ({ website, state, userConnections }) =>{
    // Store
    const dispatch = useDispatch()
    const activeButton = useSelector(({ sidebar }) => sidebar.activeButton)

    // Router 
    const navigate = useNavigate()

    // Handle button click
    const handleClick = () =>{
        // Set active button in store
        dispatch(setActiveButton(`${state}/${website.url}`))

        // Open mobile sidebar (if applicable)
        dispatch(setMobileSidebarIsOpen())

        // Navigate to requested link
        navigate(`/profile/${state}/${website.url}`)
    }

    return(
        <button 
            className={`${activeButton === state+"/"+website.url? "profile-sidebar-options-nested-sub-btn-active": "profile-sidebar-options-nested-sub-btn"}`}
            onClick={handleClick}
        >
            {website.url}
            <ProfileSidebarOptionsDropdownSubButtonNotifications website={website} state={state} userConnections={userConnections} />
        </button>
    )
}

export { ProfileSidebarOptionsDropdownSubButton }