import { createSlice } from "@reduxjs/toolkit";
import suggestionService from "../../services/suggestionService";

const initialState = {
    suggestions: [],
    activeSuggestion: {},
    loading: true,
    activeSuggestionLoading: true,
    error: null,
    activeSuggestionError: null
}

const suggestionsSlice = createSlice({
    name: 'suggestions',
    initialState,
    reducers:{
        setSuggestions(state, action){
            state.suggestions = action.payload
        },
        setActiveSuggestion(state, action){
            state.activeSuggestion = action.payload
        },
        setLoading(state, action){
            state.loading = action.payload
        },
        setActiveSuggestionLoading(state, action){
            state.activeSuggestionLoading = action.payload
        },
        setError(state, action){
            state.error = action.payload
        },
        setActiveSuggestionError(state, action){
            state.activeSuggestionError = action.payload
        }
    }
})

export const { 
    setSuggestions, 
    setActiveSuggestion, 
    setLoading, 
    setActiveSuggestionLoading, 
    setError, 
    setActiveSuggestionError 
} = suggestionsSlice.actions

// Get all suggestions
export const initializeSuggestions = () =>{
    return async dispatch =>{
        try {
            const response = await suggestionService.getAll()

            dispatch(setSuggestions(response.data))
            dispatch(setLoading(false))
        } catch (error) {
            dispatch(setError(error.message))
        }
    }
}

// Get suggestion by connectionId
export const getSuggestionByConnection = (id, config) =>{
    return async dispatch =>{
        try {
            const response = await suggestionService.getByConnection(id, config)

            dispatch(setActiveSuggestion(response.data))
            dispatch(setActiveSuggestionLoading(false))
        } catch (error) {
            dispatch(setActiveSuggestionError(error.message))
        }
    }
}

// Create new suggestion
export const handleCreateSuggestion = (data, config) => {
    return async dispatch =>{
        try {
            await suggestionService.postSuggestion(data, config)
        } catch (error) {
            dispatch(setError(error.name))
        }
    }
}

export default suggestionsSlice.reducer