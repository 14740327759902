import { useDispatch, useSelector } from "react-redux"

import { updateWebsiteRequest } from '../../../../../../../../store/reducers/websiteReducer'
import { config } from '../../../../../../../../apiConfig'

const ProfileWebsiteLanguagesSaveButton = ({ websiteId, selectedLanguages, setShowLanguages }) =>{
    // Store
    const dispatch = useDispatch()
    const currentUser = useSelector(({ currentUser }) => currentUser)

    // Save and send the request
    const handleSave = async () =>{
        dispatch(updateWebsiteRequest(websiteId, { languageOfWebsite: selectedLanguages }, config(currentUser.token)))
        setShowLanguages(false)
    }

    return(
        <button className='profile-websites-languages-save-btn' onClick={handleSave}>Save</button>
    )
}

export { ProfileWebsiteLanguagesSaveButton }