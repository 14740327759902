import { useSelector } from "react-redux"
import { Link } from "react-router-dom"

import { FaBars } from 'react-icons/fa'

import NavUser from './User/NavUser'
import WaitlistLink from "./WaitlistLink/WaitlistLink"

export default function NavAuth ({ setMobileMenuIsOpen }){
    /// Get currentUser from store
    const currentUser = useSelector(({ currentUser }) => currentUser )

    // Conditionally render waitlist or login section
    const sectionToRender = () =>{
        if(process.env.REACT_APP_API_APP_STAGE === 'waitlist'){
            return <WaitlistLink />
        }

        return Object.keys(currentUser).length === 0?
            <div className="nav-account">
                <Link to="/login" className='login-link'>Login</Link>
                <Link to="/register" className='signup-link'>Sign up for free</Link>
            </div>
            : <NavUser />
    }

    return(
        <div className="nav-right">
            {sectionToRender()}
            <button className='nav-mobile-menu-btn' onClick={()=>{ setMobileMenuIsOpen(true) }}><FaBars /></button>
        </div>    
    )
}