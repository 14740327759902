import { useState } from 'react'
import './guide.css'

import GuideSingle from './GuideSingle'
import { GuideOption } from './GuideOption'

import ui1 from '../../../resources/samples/ui-matching.jpg'
import ui2 from '../../../resources/samples/ui-backlink-connects.jpg'
import ui3 from '../../../resources/samples/ui-backlinks.jpg'
import ui4 from '../../../resources/samples/ui-communication.jpg'

export default function Guide (){
    // Local state
    const [option, setOption] = useState("MATCHING")

    return(
        <div className="guide">
            <div className="guide-top">
                <span className='landing-section-title'>UX</span>
                <h1>Simplifying the process</h1>
                <p>We streamline the entire process, making it a breeze for website owners to boost their online presence.</p>
            </div>

            <div className="guide-content">
                <div className="guide-content-nav">
                    <GuideOption label="Matching" value="MATCHING" option={option} setOption={setOption} />
                    <GuideOption label="Backlink connects" value="BACKLINK CONNECTS" option={option} setOption={setOption} />
                    <GuideOption label="Backlinks" value="BACKLINKS" option={option} setOption={setOption} />
                    <GuideOption label="Communication" value="COMMUNICATION" option={option} setOption={setOption} />
                </div>

                {guideData.map( (item, i) => item.id === option && <GuideSingle key={i} item={item} /> )}
            </div>
        </div>
    )
}

const guideData = [
    {
        title: "Generating a backlink",
        point1: "The process of finding suitable matches for your potential backlinks is fully automated and handled by us.",
        point2: "We use a complex algorithm to generate the best matches in the form of a 'Connection' and notify you each time that happens.",
        img: ui1,
        id: "MATCHING",
        count: 1
    },
    {
        title: "Getting a backlink",
        point1: "You have absolute power over which links you want to procceed with and get a backlink from.",
        point2: "With control over all the details of a backlink, all you need to do is set your preferences in the forms provided, letting your match know your desired settings for the backlink.",
        img: ui2,
        id: "BACKLINK CONNECTS",
        count: 2
    },
    {
        title: "Managing backlinks",
        point1: "We keep track of all the links exchanged between you and other users to give you an insightful view in the form of statistics.",
        point2: "You have complete control over your active links, with actions such as reporting and removing a link.",
        img: ui3,
        id: "BACKLINKS",
        count: 3
    },
    {
        title: "Communicating with other website owners",
        point1: "We have everything covered, if you need to discuss about cetain details with you backlink matches.",
        point2: "Our chat is integrated with each backlink to make the process easier.",
        img: ui4,
        id: "COMMUNICATION",
        count: 4
    },
]