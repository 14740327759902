import { ResponsiveContainer, PieChart, Pie } from "recharts"

const ProfileLandingChartsPie = ({ pieData }) =>{
    return(
        <ResponsiveContainer width="50%" height="100%">
            <PieChart width={50} height={50}>
                <Pie data={pieData} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={65} fill="#fff" />
            </PieChart>
        </ResponsiveContainer>
    )
}   

export default ProfileLandingChartsPie