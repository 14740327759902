import { useState } from "react"

import {MdExpandMore} from 'react-icons/md'

const FaqSingle = ({ title, text }) =>{
    // Local state
    const [showAnswer, setShowAnswer] = useState(false)

    return(
        <div className="faq-single" onClick={()=>{ setShowAnswer(!showAnswer) }}>
            <div className="faq-single-question">
                <h5>{title}</h5>
                <MdExpandMore className={`${showAnswer? "faq-single-answer-icon-open": "faq-single-answer-icon"}`} />
            </div>

            <div className={`${showAnswer? "faq-single-answer-show": "faq-single-answer"}`}>
                <p>{text}</p>
            </div>
        </div>
    )
}

export { FaqSingle }