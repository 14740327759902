export const config = ( token ) =>{
    return{
        headers: { Authorization : `bearer ${token}`}
    }
}

export const multiDataConfig = ( token ) =>{
    return{
        headers: {
            'Content-Type': 'multipart/form-data',
            Authorization : `bearer ${token}`
        }
    }
}