import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import '../profile-websites-actions.css'

import { initializeLanguages } from '../../../../../../../../store/reducers/languagesReducer'

import { ProfileWebsiteCheckboxSingle } from '../ProfileWebsiteCheckboxSingle'
import { ProfileWebsiteLanguagesSaveButton } from './ProfileWebsiteLanguagesSaveButton'
import { ProfileWebsiteLanguagesCloseButton } from './ProfileWebsiteLanguagesCloseButton'
import { ProfileWebsiteNichesWarning } from '../Niches/ProfileWebsiteNichesWarning'
 
const ProfileWebsiteLanguages = ({ website, setShowLanguages }) =>{
    // Dispatch
    const dispatch = useDispatch()
    const languages = useSelector(({ languages }) => languages.languages)
    const languagesLoading = useSelector(({ languages }) => languages.loading)
    const languagesError = useSelector(({ languages }) => languages.error)

    // State
    const [selectedLanguages, setSelectedLanguages] = useState(website.languageOfWebsite)

    // Initialize languages
    useEffect(()=>{
        dispatch(initializeLanguages())
    }, [dispatch])

    // Handle error and loading
    if(languagesError) return <p>{languagesError}</p>
    if(languagesLoading) return null

    return(
        <div className="profile-languages-wrapper">
            <div className="profile-website-languages">
                <h1>Select the languages of your website</h1>
                <p>You can choose as many languages as you want if they represent your website.</p>
                <div className="profile-website-languages-options">
                    {languages.map (language => 
                        <ProfileWebsiteCheckboxSingle 
                            key={language.id}
                            checkboxValue={language.title}
                            selectedItems={selectedLanguages}
                            setSelectedItems={setSelectedLanguages}
                        />)
                    }
                </div>

                <ProfileWebsiteLanguagesSaveButton websiteId={website.id} selectedLanguages={selectedLanguages} setShowLanguages={setShowLanguages} />
                <ProfileWebsiteLanguagesCloseButton setShowLanguages={setShowLanguages} />
                <ProfileWebsiteNichesWarning length={selectedLanguages.length} keyword="languages" />
            </div>
        </div>
    )
}

export default ProfileWebsiteLanguages