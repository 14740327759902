import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { updateCurrentUser } from '../../../store/reducers/currentUserReducer'
import { useParams } from "react-router-dom"
import './register-payment.css'

import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import paymentService from '../../../services/paymentService'

import { DashboardFooter } from '../../Footer/DashboardFooter'
import { RegisterPaymentForm } from './RegisterPaymentForm'
import { Spinner } from '../../../common/Spinner'

import img from '../../../resources/subscription.jpg'

const RegisterPayment = () => {
    // Store
    const dispatch = useDispatch()
    const currentUser = useSelector(({ currentUser }) => currentUser)

    // Local state
    const [stripePromise, setStripePromise] = useState(null)
    const [clientSecret, setClientSecret] = useState(null)
    const [error, setError] = useState(null)

    // Retrieve priceId from params
    const priceId = useParams().price

    // Get stripe keys from server
    useEffect(()=>{
        let isMounted = true
        
        // Async function to load keys and initialize stripe
        async function initializeStripe (){
            try {
                const response = await paymentService.getStripeKey()

                if(isMounted){
                    setStripePromise(loadStripe(response.data.publishableKey))
                }
            } catch (error) {
                if(isMounted){
                    console.log(error.name)
                }
            }
        }
        // Run function
        initializeStripe()

        // Cleanup effect
        return () => { isMounted = false }
    }, [])

    // Create subscription for initial plan choice
    useEffect(()=>{
        let isMounted = true

        // Function to create the subscription
        async function createStripeSubscription (){    
            try {
                // Send request for creating subscription
                const response = await paymentService.createSubscription({ priceId, customerId: currentUser.userForToken.customerId })

                if(isMounted){
                    setClientSecret(response.data.clientSecret)

                    // Retrieve currentUser from locastorage and update
                    const storedUser = JSON.parse(localStorage.getItem('currentUser' || '{}'))
                    storedUser.userForToken = response.data.updatedUser

                    // Save updated currentUser in localstorage and store
                    localStorage.setItem('currentUser', JSON.stringify(storedUser))
                    dispatch(updateCurrentUser(storedUser))
                }
            } catch (error) {
                if(isMounted){
                    console.log(error.message)
                    setError(error.message)
                }
            }
        }
        createStripeSubscription()

        // Cleanup
        return () => { isMounted = false }
    }, [])

    if(!clientSecret || !stripePromise) return <Spinner />
    if(error) return <p>{error}</p>

    return(
        <div className='register-payment'>
            <div className="register-payment-content">
                <div><img src={img} alt="" /></div>

                <Elements stripe={stripePromise} options={{ clientSecret }}>
                    <RegisterPaymentForm />
                </Elements>
            </div>

            <DashboardFooter />
        </div>
    )
}

export { RegisterPayment }