import { Link } from 'react-router-dom'
import './interface.css'

// Resources
import wave from '../../../resources/svg/wave.svg'

// Icons
import { AiOutlineBell, AiOutlineWifi, AiOutlineCheckCircle } from 'react-icons/ai'
import { BsBatteryFull } from 'react-icons/bs'
import { MdSignalCellular4Bar } from 'react-icons/md'

export default function Interface (){
    // Conditionally render waitlist or login section
    const sectionToRender = () =>{
        if(process.env.REACT_APP_API_APP_STAGE === 'waitlist'){
            return <Link to="/waitlist" className='interface-link'>Try for free</Link>
        }

        return <Link to="/register" className='interface-link'>Try for free</Link>
    }

    return(
        <div className="interface">            
            <div className="inteface-bottom">
                <div className='interface-content'>
                    <span className='landing-section-title'>App</span>
                    <h1>Introducing our backlink platform</h1>
                    <p>Our web based app provides you with all the necessary tools to manage your backlinks. With a slick and easy to use dashboard that fast tracks the whole process from joining to getting your first backlink.</p>

                    <div>
                        <li><AiOutlineCheckCircle  /> Effortless backlink management</li>
                        <li><AiOutlineCheckCircle  /> Real-time analytics</li>
                        <li><AiOutlineCheckCircle  /> Dedicated support</li>
                        <li><AiOutlineCheckCircle  /> Mobile friendly</li>
                    </div>

                    {sectionToRender()}
                </div>

                <div className='interface-sample'>
                    <div className='interface-sample-bar'>
                        <span>00:00</span>
                        <div>
                            <AiOutlineWifi />
                            <MdSignalCellular4Bar />
                            <BsBatteryFull />
                        </div>
                    </div>

                    <div className="interface-sample-shape">
                        <div id='interface-shape-1'>
                            <div>
                                <h5>Hi John</h5>
                                <p>Welcome back</p>
                            </div>

                            <span><AiOutlineBell /></span>
                        </div>
                        <div id='interface-shape-2'>
                            <h5>Your websites</h5>
                            <span>2</span>
                            <small>View all websites</small>
                        </div>
                        <div id='interface-shape-3'>
                            <h5>Your backlinks</h5>
                            <span>212</span>
                            <small>View all backlinks</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
