import './support-categories.css'

import {CgWebsite} from 'react-icons/cg'
import {AiOutlineLink} from 'react-icons/ai'
import {TbPlugConnected} from 'react-icons/tb'
import {MdManageAccounts} from 'react-icons/md'

const SupportCategories = () =>{
    return(
        <div className="support-categories">
            <h5>Categories</h5>

            <div className="support-categories-content">
                <div className='support-categories-single'>
                    <div className="support-categories-single-top">
                        <CgWebsite style={{fontSize: '1.5rem'}} />
                        <p>Websites</p>
                    </div>
                    <div className='support-categories-single-sub'>
                        <small>Adding website</small>
                        <small>Edit website</small>
                        <small>Update</small>
                    </div>
                </div>
                <div className='support-categories-single'>
                    <div className="support-categories-single-top">
                        <AiOutlineLink style={{fontSize: '1.5rem'}} />
                        <p>Links</p>
                    </div>
                    <div className='support-categories-single-sub'>
                        <small>Backlinks</small>
                        <small>Outlinks</small>
                    </div>
                </div>
                <div className='support-categories-single'>
                    <div className="support-categories-single-top">
                        <TbPlugConnected style={{fontSize: '1.5rem'}} />
                        <p>Connections</p>
                    </div>
                    <div className='support-categories-single-sub'>
                        <small>Connect with friends</small>
                        <small>Manage connections</small>
                        <small>Delete connection</small>
                    </div>
                </div>
                <div className='support-categories-single'>
                    <div className="support-categories-single-top">
                        <MdManageAccounts style={{fontSize: '1.5rem'}} />
                        <p>Account</p>
                    </div>
                    <div className='support-categories-single-sub'>
                        <small>Update profile</small>
                        <small>Add info</small>
                        <small>Sign out</small>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SupportCategories