import { useState } from "react"
import { Helmet } from "react-helmet-async"

import ProfileNav from "../Profile/ProfileNav"
import AdminPanel from "./AdminPanel"
import AdminSidebar from "./Sidebar/AdminSidebar"

const AdminPage = () =>{
    // State for button option
    const [option, setOption] = useState("new-websites")

    return(
        <div className="admin-page">
            <Helmet>
                <title>Admin dashboard</title>
                <meta name='description' content='Manage users and websites in your admin dashboard' />
            </Helmet>

            <ProfileNav />

            <AdminSidebar option={option} setOption={setOption} />

            <div className="admin-page-content">
                <AdminPanel option={option} />
            </div>
        </div>
    )
}

export default AdminPage