import { BiError } from 'react-icons/bi'

const ErrorMessage = ({ error }) =>{
    return(
        <div className="error">
            <BiError id='error-icon' />
            <p style={{ color: '#ff3333' }}>Something undexpected occured: {error}</p>
        </div>
    )
}

export { ErrorMessage }