import { useEffect, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"

import { initializeConversationMessages } from "../../../store/reducers/messageReducer"
import { config } from '../../../apiConfig'

import { Spinner } from '../../../common/Spinner'
import { BacklinksMessagesSingleForm } from "./BacklinksMessagesSingleForm"
import { BacklinksMessagesSingleTop } from './BacklinksMessagesSingleTop'
import { BacklinksMessagesSingleTexts } from './Texts/BacklinksMessagesSingleTexts'

const BacklinksMessagesSingle = ({ activeConversation, role }) =>{
    // Store
    const dispatch = useDispatch()
    const currentUser = useSelector(({ currentUser }) => currentUser)
    const loading = useSelector(({ messages }) => messages.conversationMessagesLoading)
    const error = useSelector(({ messages }) => messages.conversationMessagesError)

    // Get the user that participates in the conversation
    const participant = useMemo(()=>{
        if(activeConversation){            
            return currentUser.userForToken.id === activeConversation.sender.id? activeConversation.reciever: activeConversation.sender
        }
    }, [activeConversation])

    // Get all messages for selected conversation
    useEffect(()=>{
        if(activeConversation){
            dispatch( initializeConversationMessages(activeConversation.id , config(currentUser.token)) )
        }
    }, [dispatch, activeConversation])
    
    // Handle errors and loading
    if(error) return <p>{error}</p>
    if(loading) return <Spinner />
    if(!participant) return null

    return(
        <div className="conversation-messages-single">
            <BacklinksMessagesSingleTop participant={participant} />
            <BacklinksMessagesSingleTexts stage={activeConversation.stage} />
            <BacklinksMessagesSingleForm conversation={activeConversation} participant={participant} role={role} />
        </div>
    )
}

export { BacklinksMessagesSingle }