import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import registerService from '../../../services/registerService'
import emailService from '../../../services/emailService'

import svg from '../../../resources/svg/mail.svg'

const FinishRegistrationFailed = () =>{
    const navigate = useNavigate()

    // Store
    const currentUser = useSelector(({ currentUser }) => currentUser)

    // Local state
    const [validationError, setValidationError] = useState(null)
    const [isProcessing, setIsProcessing] = useState(false)

    const handleRequestVerification = async () =>{
        if(!currentUser){
            return
        }

        try {
            setIsProcessing(true)

            // Send request to update temporary token
            const response = await registerService.updateTemporaryToken({ email: currentUser.userForToken.email })

            // Send email with updated token
            const emailResponse = await emailService.verificationEmail({ email: response.data.email, token: response.data.temporaryToken })
        
            // Check if email was sent successfully
            if(emailResponse.data.status !== 200){
                setValidationError(emailResponse.data.details)
            } else{
                // Navigate to next page
                navigate('/register/confirmation', {replace: true})
            }

            setIsProcessing(false)
        } catch (error) {
            console.log(error)
            setIsProcessing(false)
        }
    }

    return(
        <div className="finish-register-failed">
            <img src={svg} alt="mail" />
            <h1>Your email has not been verified</h1>
            <p>Your data could not be retrieved. This is likely due to an unverified email which renders the registration incomplete, or an expired token in the verification email.</p>
            <p>Please check your email inbox or request a <button onClick={handleRequestVerification} disabled={isProcessing} >new verification email</button> to complete your registration.</p>
            <small className='finish-register-failed-email-error'>{validationError}</small>
        </div>
    )
}

export { FinishRegistrationFailed }