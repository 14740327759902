import { useEffect } from "react"
import { useDispatch } from "react-redux"

import { updateCurrentUser } from "../../store/reducers/currentUserReducer"

function useServerSideEvents (){
    // Store
    const dispatch = useDispatch()

    useEffect(()=>{
        // Get the event source from the route
        const eventSource = new EventSource(`${process.env.REACT_APP_API_BASE_URL}webhooks/sse`)

        // Check for error events
        eventSource.onerror = () =>{
            // Doing nothing will automatically retry a connection

            // Close the connection
            eventSource.close()
        }

        // Check for message events
        eventSource.onmessage = async (event) =>{
            if(!event.data){
                return
            }

            try {
                // Parse the updated user from event data
                const parsedUser = await JSON.parse(event.data)

                console.log("Ran")

                // Retrieve currentUser from locastorage and update
                const storedUser = JSON.parse(localStorage.getItem('currentUser' || '{}'))
                storedUser.userForToken = parsedUser

                // Save updated currentUser in localstorage and store
                localStorage.setItem('currentUser', JSON.stringify(storedUser))
                dispatch(updateCurrentUser(storedUser))

                console.log("User updated")

            } catch (error) {
                console.log(error.message)
            }
        }

        // Cleanup closing the connection
        return () =>{ eventSource.close() }
    }, [])
}

export default useServerSideEvents