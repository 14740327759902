import { BacklinksTop } from "../Backlinks/BacklinksTop/BacklinksTop"
import { BacklinksTable } from "../Backlinks/Table/BacklinksTable"
import { BacklinksChatButton } from "../Backlinks/BacklinksChatButton"
import { BacklinksMessages } from "../Backlinks/Messages/BacklinksMessages"
import OutlinkOptions from "../OutlinkOptions/OutlinkOptions"

const Outlinks = () =>{
    return(
        <div className="backlinks-page">
            <BacklinksTop title="Outlinks" />
            <BacklinksTable role="target" />
            <BacklinksChatButton />
            <BacklinksMessages />
            <OutlinkOptions />
        </div>
    )
}

export { Outlinks }