const DashboardPageError = () =>{
    const handleRefresh = () =>{
        window.location.reload(true)
    }

    return(
        <div className="dashboard-error-page">
            <h1>Oops, something went wrong</h1>
            <p>The dashboard could not be accessed</p>
            <button onClick={handleRefresh}>Try again</button>
        </div>
    )
}

export { DashboardPageError }