import axios from 'axios'
const baseUrl = `${process.env.REACT_APP_API_BASE_URL}emails`

const verificationEmail = (data) => axios.post(`${baseUrl}/verify-email`, data)

const passwordResetEmail = (data) => axios.post(`${baseUrl}/password-reset`, data)

const contactEmail = (data) => axios.post(`${baseUrl}/contact-us`, data)

const waitlistEmail = (data) => axios.post(`${baseUrl}/waitlist-email`, data)

const launchUpdateEmail = () => axios.post(`${baseUrl}/launch-update`)

const emailService = { verificationEmail, passwordResetEmail, contactEmail, waitlistEmail, launchUpdateEmail }
export default emailService
