import { useState } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { updateCurrentUser } from '../../store/reducers/currentUserReducer'
import { useDispatch } from 'react-redux'
import { Helmet } from 'react-helmet-async'


import loginService from '../../services/loginService'
import './login.css'

// Icons
import {CgProfile} from 'react-icons/cg'
import logo from '../../resources/logo-link.png'

// Components
import CheckBox from './CheckBox'
import LoginPassword from './LoginPassword'
import ErrorMessage from '../Popups/ErrorMessage'

const Login = () =>{
    // Navigate
    const navigate = useNavigate()
    const dispatch = useDispatch()

    // State for input
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [showPass, setShowPass] = useState(false)
    const [error, setError] = useState("")

    // Handle form submission
    const handleSubmit = async (e) =>{
        e.preventDefault()

        // Create an object with the credentials typed
        const credentials = { email, password }
                
        try {
            // POST the credentials to server
            const response = await loginService.postCredentials(credentials)

            // Save returned user to localstorage and change state
            localStorage.setItem('currentUser', JSON.stringify(response.data))
            dispatch(updateCurrentUser(response.data))

            // Create session for logged user saving his token
            sessionStorage.setItem('token', JSON.stringify(response.data.token))
            
            // Go to next page
            navigate("/profile", {replace: true})

        } catch (error) {
            // Handle 401 errors
            if(error.response && error.response.status === 401){
                const errorMessage = error.response.data.error

                if(errorMessage === 'User not found'){
                    setError(errorMessage)
                } else if(errorMessage === 'Wrong password'){
                    setError(errorMessage)
                } else if(errorMessage === 'Account not verified'){
                    navigate("/register/unverified")
                }
            } else{
                // Handle errors different to 401
                console.log(error.message)
            }
        }
    }

    return(
        <div className="login-page">
            <Helmet>
                <title>Linkeby | Login Page</title>
                <meta name="description" content="Sign in to your account" />
            </Helmet>
            
            <img src={logo} alt="logo" />
            <p className='login-page-p'>Sign in to your account</p>
            <small>Glad to have you back!</small>

            <form id="login-form" onSubmit={handleSubmit}>
                <div className='login-input-wrapper'>
                    <CgProfile className='login-icons' />

                    <input 
                        className='login-text-input'
                        type="text" 
                        placeholder='Email' 
                        value={email} 
                        onChange={({target})=>{setEmail(target.value)}}
                        onMouseEnter={()=>{ setError("") }}
                    />
                </div>

                <LoginPassword password={password} setPassword={setPassword} showPass={showPass} setShowPass={setShowPass} />

                <CheckBox />

                <button type="submit" className='form-btn'>Login</button>

                {/* Show error message */}
                <ErrorMessage message={error} />
            </form>

            <Link to="/profile/password-reset" className='forgot-pass-btn'>Forgot password</Link>

            <div className="login-register-redirect">
                <p>Don't have an account?</p>
                <Link className='login-register-redirect-link' to="/register" >Sign up</Link>
            </div>

            <div className="login-terms">By logging in you agree to our terms of services</div>
        </div>
    )
}   

export default Login