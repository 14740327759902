import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import { handlePostConversation } from '../../store/reducers/conversationReducer'
import { postNewConnection } from '../../store/reducers/connectionReducer'
import { handlePostNotification } from '../../store/reducers/notificationReducer'

import { config } from '../../apiConfig'

const InitialConnection = () =>{
  // Store
  const connections = useSelector(({ connections }) => connections.allConnections)
  const userWebsites = useSelector(({ websites }) => websites.userWebsites)
  const allWebsites = useSelector(({ websites }) => websites.allWebsites)
  const currentUser = useSelector(({ currentUser }) => currentUser)
  const dispatch = useDispatch()

  // Function to post connection and conversation
  const generateConnections = async (website) =>{
    // Filter websites to pick a connection from
    const filteredWebsites = allWebsites.filter(w =>
      w.owner &&
      !w.owner.isAdmin && 
      w.owner.id !== currentUser.userForToken.id &&
      !connections.some(connection => 
        (connection.targetWebsite?.id === w.id && connection.sourceWebsite?.owner === currentUser.userForToken.id) ||
        (connection.sourceWebsite?.id === w.id && connection.targetWebsite?.owner === currentUser.userForToken.id)
      )   
    )

    // Pick a random user from that filtered array
    const randomIndex = filteredWebsites.length? Math.floor(Math.random() * filteredWebsites.length): null
    const randomId = randomIndex !== null? filteredWebsites[randomIndex].id: null

    if(randomId !== null){
      // Data to pass with requests
      const connectionsData = { sourceWebsiteId: website.id, targetWebsiteId: randomId }
      const conversationsData = { reciever: filteredWebsites[randomIndex].owner.id, sourceWebsite: website.id, targetWebsite: randomId }
      const notificationData = { owner: website.owner.id, title: "New Connection", text: `You've got your first backlink connection for ${website.url}`, typeOfNotification: "connections" }  

      // Dispatch all api calls
      dispatch(postNewConnection(connectionsData, config(currentUser.token)))
      dispatch(handlePostConversation(conversationsData, config(currentUser.token)))  
      dispatch(handlePostNotification(notificationData))
    } else{
      console.log("No available connections")
    }   
  }

    // Run initial connection check and execution
  useEffect(()=>{    
    if(userWebsites.length > 0){
      let initialConnections = JSON.parse(localStorage.getItem('initialConnections') || "[]")

      userWebsites.forEach( website => {
        const websiteHasConnections = initialConnections.find( doc => doc.id === website.id)

        //If the website has no connection at all procceed to create one
        if(!websiteHasConnections){
          generateConnections(website)
    
          // Save localstorage state
          initialConnections = [...initialConnections, { id: website.id }]
          localStorage.setItem("initialConnections", JSON.stringify(initialConnections))
        }
      })
    } 
  }, [userWebsites])

  return null 
}

export { InitialConnection }