import { useCallback, useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';

const WalkthroughTour = ({ pageName }) =>{
    // Store
    const pages = useSelector(({walkthrough}) => walkthrough.pages)

    // state
    const [enabled, setEnabled] = useState(false)
    const [initialStep, setInitialStep] = useState(0)
    const walkthroughState = JSON.parse(localStorage.getItem('WalkthroughState') || "[]")

    // Find steps for chosen component
    const steps = useMemo(()=>{
        let foundPage = pages.find( page => page.page === pageName )
        return foundPage.steps
    }, [pages])

    // Check storage for walkthough state to decide if the walkthrough is shown or not
    useEffect(()=>{
        const foundWalkthroughPage = walkthroughState.find( p => p.page === pageName)

        if(!foundWalkthroughPage){
            setEnabled(true)
        }
    }, [])

    // Callback for saving the walkthrough state so it doesn't show up again
    const changeLocalStorage = useCallback(()=>{
        const newPage = { page: pageName, enabled: false }
        walkthroughState.push(newPage)
        localStorage.setItem('WalkthroughState', JSON.stringify(walkthroughState))
    }, [])

    // Handle walkthrough step exit. If user exits at the last step don't show walkthrough again
    const handleWalkthroughExit = () => {
        if(initialStep === steps.length){
            changeLocalStorage()
        }
    }

    // Handle step change
    const handleWalkthroughChange = () =>{
        setInitialStep(initialStep + 1)
    }

    return(
        <Steps
            enabled={enabled}
            steps={steps}
            initialStep={0}
            onExit={handleWalkthroughExit}
            onChange={handleWalkthroughChange}
            onComplete={changeLocalStorage}
        />
    )
}

export { WalkthroughTour }