const SettingsSidebarOption = ({ activeSetting, setActiveSetting, state }) =>{
    return(
        <button 
            onClick={()=>{ setActiveSetting(state) }}
            className={`${activeSetting === state? "settings-sidebar-option-active": "settings-sidebar-option"}`}
        >
            {state}
        </button>
    )
}

export { SettingsSidebarOption }