import {BiCategoryAlt} from 'react-icons/bi'

const NicheOptions = ({ niche, setNiche, niches }) =>{
    return(
        <div className="finish-registration-form-input">
            <BiCategoryAlt className='finish-form-icon' />
            <select onChange={({target})=>{setNiche(target.value)}} required value={niche}>
                <option value="">Choose a niche</option>
                {niches.map( category =>
                    <option key={category.id} value={category.title}>{category.title}</option>
                )}
            </select>
        </div>
    )
}

export default NicheOptions