import { useSelector } from 'react-redux'
import './profile-info.css'

// Main page
const ProfileInfo = () => {    
    // Store
    const user = useSelector(({currentUser}) => currentUser.userForToken )

    return(
        <div className="profile-info">
            <div className="profile-info-details">
            <p className="profile-info-details-title">Your information</p>
            <small className="profile-info-details-sub">Some info may be visible to other people using Linkeby</small>
                <div className="profile-info-details-divs">
                    <div>
                        <span>User</span> 
                        <p>{user.name} {user.surname}</p>
                    </div>
                    <div>
                        <span>Email</span>
                        <p>{user.email}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfileInfo