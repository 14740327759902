import { configureStore } from '@reduxjs/toolkit'

import userReducer from './reducers/userReducer'
import currentUserReducer from './reducers/currentUserReducer'
import notificationReducer from './reducers/notificationReducer'
import websiteReducer from './reducers/websiteReducer'
import conversationReducer from './reducers/conversationReducer'
import messageReducer from './reducers/messageReducer'
import connectionReducer from './reducers/connectionReducer'
import suggestionsReducer from './reducers/suggestionsReducer'
import planReducer from './reducers/planReducer'
import helpReducer from './reducers/helpReducer'
import linksReducer from './reducers/linksReducer'
import sidebarReducer from './reducers/sidebarReducer'
import walkthroughReducer from './reducers/walkthroughReducer'
import languagesReducer from './reducers/languagesReducer'
import backlinksReducer from './reducers/backlinksReducer'
import logReducer from './reducers/logReducer'
import nicheReducer from './reducers/nicheReducer'
import errorReducer from './reducers/errorReducer'
import successReducer from './reducers/successReducer'

const store = configureStore({
    reducer: {
      users: userReducer,
      currentUser: currentUserReducer,
      notifications: notificationReducer,
      websites: websiteReducer,
      conversations: conversationReducer,
      connections: connectionReducer,
      messages: messageReducer,
      suggestions: suggestionsReducer,
      plans: planReducer,
      helpArticles: helpReducer,
      links: linksReducer,
      sidebar: sidebarReducer,
      walkthrough: walkthroughReducer,
      languages: languagesReducer,
      backlinks: backlinksReducer,
      logs: logReducer,
      niches: nicheReducer,
      error: errorReducer,
      success: successReducer
    }
  })

export default store