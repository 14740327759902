import { useMemo, useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { initializeNotifications, handleNotificationsOpen } from "../../../store/reducers/notificationReducer"
import { config } from '../../../apiConfig'

// Icons
import {AiOutlineBell} from 'react-icons/ai'

// Components
import Notifications from '../../Notifications/Notifications'
import { ErrorMessageLine } from '../../../common/ErrorMessageLine'

/**
 * Nav bar notifications component
 */
const NavUserNotifications = () =>{
    // Store
    const currentUser = useSelector(({ currentUser }) => currentUser )
    const notifications = useSelector(({ notifications }) => notifications.notifications)
    const notificationsLoading = useSelector(({ notifications }) => notifications.loading)
    const notificationsError = useSelector(({ notifications }) => notifications.error)
    const dispatch = useDispatch()

    // Refs
    const wrapperRef = useRef()
    
    // State
    const [showNotifications, setShowNotifications] = useState(false)

    // Find the notifications that are not opened yet
    const notOpenedNotificationsCount = useMemo(()=>{
        const notOpenedNotifications = notifications.filter( notification => !notification.isOpened)
        return notOpenedNotifications.length
    }, [notifications])

    // Handle notifications open button
    const handleOpeningNotifications = () =>{
        dispatch(handleNotificationsOpen(config(currentUser.token)))    
        setShowNotifications(!showNotifications)
    }

    // Fetch all notifications that belong to this user
    useEffect(()=>{
        dispatch(initializeNotifications(currentUser.userForToken.id, config(currentUser.token)))
    }, [dispatch, currentUser])

    // Hide notifications on click outside
    useEffect(()=>{
        function handleClickOutside(e) {
            if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
              setShowNotifications(false)
            }
        }
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        }
    }, [wrapperRef])

    // Handle loading and errors
    if(notificationsError) return <ErrorMessageLine error={notificationsError} />
    if(notificationsLoading) return null

    return(
        <div className="nav-user-notifications" ref={wrapperRef}>
            <button className="nav-user-notifications-btn" onClick={handleOpeningNotifications}>
                <AiOutlineBell />
                {notOpenedNotificationsCount > 0 && <div className="nav-user-notifications-count">{notOpenedNotificationsCount}</div>}
            </button>
            {showNotifications? <Notifications notifications={notifications} currentUser={currentUser} />: ""}
        </div>
    )
}

export { NavUserNotifications }