import { RiLockPasswordLine } from 'react-icons/ri'
import { IoIosCloseCircleOutline } from "react-icons/io";
import { FaRegCheckCircle } from "react-icons/fa";

export default function FormInputPassword ({ inputValue, setInputValue, passwordValidityError, setPasswordValidityError }){
    // Check password strength and validity
    const checkValidity = () =>{
        // RegEx for password complexity
        const complexityRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{10,}$/

        // Check password length
        if(inputValue.length < 10){
            setPasswordValidityError('Password should have at least 10 characters')
            return false
        } 
        
        // Check if password matches RegEx
        if(!complexityRegex.test(inputValue)){
            setPasswordValidityError('Password should contain at least one uppercase letter, one lowercase letter and one number')
            return false
        }

        // Reset error if password is OK
        setPasswordValidityError(null)
        return true
    }

    return(
        <div className="form-input-text">
            <RiLockPasswordLine />

            <input 
                type="password" 
                placeholder="Enter password" 
                value={inputValue} 
                onKeyUp={checkValidity}
                onChange={({target})=>{setInputValue(target.value)}}
                required 
            />

            {passwordValidityError? <IoIosCloseCircleOutline style={{ color: 'red' }} />: <FaRegCheckCircle style={{ color: 'green' }} />}
        </div>
    )
}