import { ComingSoon } from '../../common/ComingSoon/ComingSoon'
import Nav from '../../components/Nav/Nav'

const Product = () =>{
    return(
        <div className="product">
            <Nav />
            <ComingSoon />
        </div>
    )
}

export { Product }