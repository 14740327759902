import { Helmet } from 'react-helmet-async'

import './landing.css'

import Nav from '../Nav/Nav'
import Footer from '../Footer/Footer'
import { FeedbackOpenButton } from '../Feedback/FeedbackOpenButton'
import { Hero } from './Hero/Hero'
import { About } from './About'
import Features from './Features/Features'
import Interface from './Interface/Interface'
import Partners from './Partners/Partners'
import { Faq } from './Faq/Faq'
import { Companies } from './Companies/Companies'
import Guide from './Guide/Guide'

const Landing = () =>{
    return(
        <div className="landing">
            <Helmet>
                <title>Linkeby homepage</title>
                <meta name="description" content="Simplifying the hardest part of SEO" />
            </Helmet>
            <Nav />
            
            {/* <FeedbackOpenButton /> */}

            <Hero />
            {/* <Companies /> */}
            <About />
            <Features />
            <Interface />
            <Guide />
            <Faq />
            <Partners />
            <Footer />
        </div>
    )
}

export { Landing }