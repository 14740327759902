import { useRef, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'

import paymentService from '../../../services/paymentService'
import { updateCurrentUser } from '../../../store/reducers/currentUserReducer'
import { getDateFromUnix } from '../../../helpers/helpers'
import { config } from '../../../apiConfig'

import { CiSettings } from 'react-icons/ci'
import { BsFillTrashFill } from 'react-icons/bs'

import { Spinner } from '../../../common/Spinner'
import { ConfirmAction } from '../../../common/ConfirmAction'


const BillingOverviewPlan= () =>{
    const navigate = useNavigate()

    const wrapperRef = useRef()

    // Store
    const dispatch = useDispatch()
    const currentUser = useSelector(({ currentUser }) => currentUser)

    // Local state
    const [isProcessing, setIsProcessing] = useState(false)
    const [showSettings, setShowSettings] = useState(false)
    const [confirmMessageIsOpen, setConfirmMessageIsOpen] = useState(false)

    // Function to handle unsubscribe button click
    const handleUnsubscribe = async () =>{
        setConfirmMessageIsOpen(true)
        
        try {
            setIsProcessing(true)

            // Send request to unsubscribe
            const response = await paymentService.cancelSubscription(
                { subscriptionId: currentUser.userForToken.subscription.id }, 
                config(currentUser.token)
            )

            // Update currentUser in localStorage and store
            const storedUser = JSON.parse(localStorage.getItem('currentUser' || '{}'))
            storedUser.userForToken = response.data.updatedUser

            // Save updated currentUser in localstorage and store
            localStorage.setItem('currentUser', JSON.stringify(storedUser))
            dispatch(updateCurrentUser(storedUser))
            
            setIsProcessing(false)

            // Redirect to homepage
            navigate("/", { replace: true })

        } catch (error) {
            setIsProcessing(false)
            console.log(error.message)
        }
    }

    // Hide profile options when clicking outside
    useEffect(()=>{
        function handleClickOutside (e){
            if(wrapperRef.current && !wrapperRef.current.contains(e.target)){
                setShowSettings(false)
            }
        }
        document.addEventListener('click', handleClickOutside)
        return () =>{
            document.removeEventListener('click', handleClickOutside)
        }
    }, [wrapperRef])

    // Return loading state while action is processing
    if(isProcessing) return <Spinner />

    return(
        <div className="billing-plan-overview">
            <div className='billing-plan-overview-content'>
                <div className='billing-plan-overview-content-top'>
                    <h5>Current Plan</h5>

                    <div className='billing-plan-overview-btns'>
                        {/* <Link to="/profile/billing/plans" className='billing-plan-overview-top-link'>Upgrade</Link> */}
                    
                        <div className='billing-plan-overview-top-btns-settings' ref={wrapperRef}>
                            <button onClick={()=>{ setShowSettings(!showSettings) }}><CiSettings /></button>
                            {showSettings && 
                            <div>
                                <button onClick={()=>{ setConfirmMessageIsOpen(true) }}>Unsubscribe</button>
                            </div>}
                        </div>
                    </div>
                </div>

                <div className='billing-plan-overview-content-info'>
                    <div>
                        <p>Premium {currentUser.userForToken.subscription.price === 2999? "Monthly": "Annual"}</p>
                        <small>Renews on <span>{getDateFromUnix(currentUser.userForToken.subscription.end)}</span></small>
                    </div>
                    <small>
                        ${currentUser.userForToken.subscription.price/100} /
                        {currentUser.userForToken.subscription.price === 2999? "month": "year"}
                    </small>
                </div>
            </div>

            {confirmMessageIsOpen && 
            <ConfirmAction 
                icon={<BsFillTrashFill />} 
                text="Are you sure you want to unsubscribe? If you proceed you will lose all access to our features." 
                color="#ff3333" 
                handleFunction={handleUnsubscribe}
                setMessageIsOpen={setConfirmMessageIsOpen}
            />}
        </div>
    )
}

export { BillingOverviewPlan }