import { useState } from "react"
import { useSelector } from "react-redux"

import { BacklinksMessagesSingleTextsMessageOptions } from './Options/BacklinksMessagesSingleTextsMessageOptions'

const BacklinksMessagesSingleTextsMessageContent = ({ showTime, setShowTime, message, setError }) =>{
    // Store
    const currentUser = useSelector(({ currentUser }) => currentUser)

    // Local state
    const [showOptions, setShowOptions] = useState(false)

    return(
        <div 
            className={`${message.sender.id === currentUser.userForToken.id? "conversation-messages-single-messages-sender-message": "conversation-messages-single-messages-reciever-message"}`}
            onClick={()=>{setShowTime(!showTime)}}
            onMouseEnter={()=>{ setShowOptions(true) }}
            onMouseLeave={()=>{ setShowOptions(false) }}
        >
            <p>{message.text}</p>

            {/* Options for single message, like deleting */}
            {showOptions && 
            <BacklinksMessagesSingleTextsMessageOptions 
                message={message} 
                setError={setError}
            />}
        </div>
    )
}

export { BacklinksMessagesSingleTextsMessageContent }