const ConfirmAction = ({ icon, text, color, handleFunction, setMessageIsOpen }) =>{
    return(
        <div className="confirm-action">
            <div className="confirm-action-wrapper">
                <div className="confirm-action-wrapper-color" style={{ background: color }}></div>

                <div className="confirm-action-text">
                    <div className="confirm-action-text-icon" style={{ background: color }}>{icon}</div>
                    <p>{text}</p>
                </div>

                <div className="confirm-action-btns">
                    <button onClick={()=>{ setMessageIsOpen(false) }}>Cancel</button>
                    <button style={{ background: color }} onClick={handleFunction}>Confirm</button>
                </div>
            </div>
        </div>
    )
}

export { ConfirmAction }