import gsap from "gsap";

import { IoMdClose } from "react-icons/io";

export default function BacklinkOptionsCloseButton ({ setShowBacklinkSettings, wrapperRef }){
    // Handle closing button
    const handleClosing = () =>{
        // Animation for ending
        gsap.fromTo(
            wrapperRef.current, 
            { scaleX: 1, scaleY: 1, borderRadius: '5px' }, 
            { scaleY: 0, scaleX: 0, borderRadius: '50%', duration: 0.1 }
        )

        // Delay closing until animation has finished
        setTimeout(() => {
            setShowBacklinkSettings(false)
        }, 100);
    }

    return(
        <button 
            className="backlink-options-close-btn" 
            onClick={handleClosing}
        >
            <IoMdClose />
        </button>
    )
}