import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { handleDeleteMessage } from '../../../../../store/reducers/messageReducer'
import { config } from '../../../../../apiConfig'

import { AiFillCloseCircle } from 'react-icons/ai'

const BacklinksMessagesSingleTextsMessageOptionsDelete = ({ message, setError }) =>{
    // Store
    const dispatch = useDispatch()
    const currentUser = useSelector(({ currentUser }) => currentUser)
    const error = useSelector(({ messages }) => messages.deleteMessageError)

    // State
    const [processing, setProcessing] = useState(false)

    // Handle delete button click
    const handleDelete = () =>{
        // Set processing to true so user cant reclick
        setProcessing(true)

        // Dispatch action to delete message
        dispatch(handleDeleteMessage(message.id, config(currentUser.token)))
        
        // If request fails, set error and reset it
        if(error){
            setError(`Could not delete message: ${error}`)

            setTimeout(() => {
                setError(null)
            }, 2000);
        }

        // Reset processing
        setProcessing(false)
    }

    // If current user is not the sender of the message, do not show option to delete
    if(message.sender.id !== currentUser.userForToken.id || message.isImportant) return null
    
    return(
        <button onClick={handleDelete} disabled={processing}><AiFillCloseCircle /></button>
    )
}

export { BacklinksMessagesSingleTextsMessageOptionsDelete }