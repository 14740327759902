import { Helmet } from 'react-helmet-async'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

import ForgotPasswordResetForm from './ForgotPasswordResetForm'

const ForgotPasswordReset = () => {
    const users = useSelector(({ users }) => users.users )

    // Retrieve id from params
    const id = useParams().id

    // Find user with id
    const user = users.find( user => user.id === id)

    return(
        <div className="forgot-password-reset">
            <Helmet>
                <title>Reset your password</title>
                <meta name='description' content='Enter a new password to reset your account' />
            </Helmet>
            
            <p className="forgot-password-title">Reset password</p>
            <small> <span>{user?.name}, </span> let's reset your password</small>
            <ForgotPasswordResetForm user={user} />
        </div>
    )
}

export default ForgotPasswordReset