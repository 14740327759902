import { OnboardingCloseButton } from "./OnboardingCloseButton"

const OnboardingStep = ({ setShowOnboarding, step, setStep, stepToSet, title, text, subtext }) =>{
    return(
        <div className="onboarding-intro">
            <div className="onboarding-intro-text">
                <h1>{title}</h1>
                <p>{text}</p>
                <p>{subtext}</p>
            </div>

            <div className="onboarding-intro-btns">
                <div>
                    <span className={`${step==="0"? "onboarding-intro-span-active": "onboarding-intro-span"}`}></span>
                    <span className={`${step==="1"? "onboarding-intro-span-active": "onboarding-intro-span"}`}></span>
                    <span className={`${step==="2"? "onboarding-intro-span-active": "onboarding-intro-span"}`}></span>
                    <span className={`${step==="3"? "onboarding-intro-span-active": "onboarding-intro-span"}`}></span>
                    <span className={`${step==="4"? "onboarding-intro-span-active": "onboarding-intro-span"}`}></span>
                </div>

                {step === "0" &&
                <div className="onboarding-intro-btns-start">
                    <OnboardingCloseButton className='onboarding-intro-skip-btn' label="Skip"  setShowOnboarding={setShowOnboarding}/>
                    <button className='onboarding-intro-btn' onClick={()=>{setStep(stepToSet)}}>Start tour</button>
                </div>}
                {step !== "0" && step !== "4" && <button className='onboarding-intro-btn' onClick={()=>{setStep(stepToSet)}}>Next</button>}
                {step === "4" && <OnboardingCloseButton className="onboarding-intro-btn" label="Finish" setShowOnboarding={setShowOnboarding} />}
            </div>
        </div>
    )
}

export { OnboardingStep }