import { useState, useRef } from "react"
import { useSelector } from "react-redux"

// Hooks 
import useClickOutside from "../../../hooks/useClickOutside"

// Import components
import NavUserOptions from "./NavUserOptions"
import { NavUserNotifications }from "./NavUserNotifications"
import { NavUserProfile } from "./NavUserProfile"

/**
 * Nav user section component
 */
const NavUser = () =>{
    // Get currentUser from store
    const currentUser = useSelector(({ currentUser }) => currentUser )
    
    // Refs
    const wrapperRef = useRef()

    // State
    const [showOptions, setShowOptions] = useState(false)

    // Hide profile options when clicking outside
    const handleClickOutside = () =>{
        setShowOptions(false)
    }
    useClickOutside(wrapperRef, handleClickOutside)

    return(
        <div className="nav-user" ref={wrapperRef}>       
            {/* User's name */}
            <p><span>{currentUser.userForToken.name} {currentUser.userForToken.surname.substring(0,1)}.</span></p>
            
            {/* Notifications */}
            <NavUserNotifications />
            
            {/* User profile */}
            <div className="nav-user-profile-wrapper">
                <NavUserProfile showOptions={showOptions} setShowOptions={setShowOptions} />
                {showOptions && <NavUserOptions />}
            </div>
        </div>
    )
}

export default NavUser