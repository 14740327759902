import { useState } from 'react'
import './billing.css'

// Components
import { BillingNav } from './BillingNav'
import { DashboardFooter } from '../Footer/DashboardFooter'
import { BillingOverview } from './Overview/BillingOverview'
import { BillingInvoices } from './Invoices/BillingInvoices'

const Billing = () =>{
    // Local state
    const [activeButton, setActiveButton] = useState("overview")

    return(
        <div className="billing">
            <h1>Billing</h1>
            
            <BillingNav activeButton={activeButton} setActiveButton={setActiveButton} />

            {activeButton === "overview" && <BillingOverview />}
            {activeButton === "invoices" && <BillingInvoices />}

            <DashboardFooter />
        </div>
    )
}

export { Billing }