import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'

import { handleAddFirstWebsite } from '../../../store/reducers/websiteReducer'
import { updateCurrentUser } from '../../../store/reducers/currentUserReducer'
import registerService from '../../../services/registerService'

// Import icons
import {TbWorld} from 'react-icons/tb'


import NicheOptions from './NicheOptions'

const FinishRegistrationForm = ({ userToBeUpdated }) =>{  
    // Get filtered links from store, to be used in select of form 
    const niches = useSelector(({ niches }) => niches.niches)
    const dispatch = useDispatch()

    // Navigate
    const navigate = useNavigate()

    // State for input
    const [url, setUrl] = useState("")
    const [niche, setNiche] = useState("")
    const [isProcessing, setIsProcessing] = useState(false)

    // Handle form submission, by creating a new website, sending a verification email
    const handleSubmit = async (e) =>{
        e.preventDefault()

        // Create objects with new data
        const data = { filteredBacklinkCategories: niches, filteredOutlinkCategories: niches }

        try {
            setIsProcessing(true)

            // Send requests for register, website and notification
            const response = await registerService.updateUser(userToBeUpdated.id, data)
            dispatch(handleAddFirstWebsite({ owner: userToBeUpdated.id, url, niche }))

            // Save the time of creation in localstorage
            // const userTimestamp = new Date(response.data.userForToken.createdAt)
            // const userUpdatedTimestamp = new Date(userTimestamp.getTime() - 1000 * 60 * 60 * 24)

            // const timestampObject = {
            //     id: response.data.userForToken.id,
            //     timestamp: userUpdatedTimestamp.toISOString() // Convert the modified timestamp to the desired format: "2023-05-18T09:53:27.361Z"
            // }

            // const storedTimeStamps = JSON.parse(localStorage.getItem('storedTimeStamps') || "[]")
            // storedTimeStamps.push(timestampObject)
            // localStorage.setItem('storedTimeStamps', JSON.stringify(storedTimeStamps))

            // Save returned user to localstorage and change state
            localStorage.setItem('currentUser', JSON.stringify(response.data))
            dispatch(updateCurrentUser(response.data))

            // Reset fields
            setUrl("")
            setNiche("")

            // Go to next page
            // navigate("/register/plans", {replace: true})
            navigate("/profile", { replace: true })

            setIsProcessing(false)
        } catch (error) {
            console.log(error)
            setIsProcessing(false)
        }
    }

    return(
        <form id="finish-registration-form" onSubmit={handleSubmit}>            
            <div className="finish-registration-form-input">
                <TbWorld className='finish-form-icon' />
                <input 
                    type="text" 
                    placeholder='Website (URL)' 
                    value={url}
                    onChange={({target})=>{setUrl(target.value)}}   
                    required
                />
            </div>
            
            <NicheOptions niche={niche} setNiche={setNiche} niches={niches} />

            <button type='submit' className='register-btn' disabled={isProcessing}>
                <span>
                    {isProcessing? "Saving...": "Save"}
                </span>
            </button>
        </form>
    )
}

export default FinishRegistrationForm