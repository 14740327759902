import { Link } from 'react-router-dom'
import './waitlist.css'
import { useSelector } from 'react-redux'

import NavUser from '../User/NavUser'

export default function WaitlistLink (){
    // Store
    const currentUser = useSelector(({ currentUser }) => currentUser)

    // If current user is logged in, return user icon instead
    if(Object.keys(currentUser).length !== 0) return <NavUser />

    return(
        <div className='nav-waitlist'>
            <Link to="/waitlist" className='waitlist-btn'>Join waitlist</Link>
            <Link to="/login" className='login-btn'>Login</Link>
        </div>
    )
}