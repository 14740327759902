import { AiOutlineCheckCircle } from 'react-icons/ai'

const RegisterPlansInfo = () =>{
    return(
        <div className="register-plans-info">
            <div className="register-plans-info-content">
                <h5>Become a valued member</h5>
                <p>By purchasing our premium package you get access to all our feautures with no restrictions!</p>
                <div>
                    <li><AiOutlineCheckCircle id='checkout-plans-icon' /> Add multiple websites</li>
                    <li><AiOutlineCheckCircle id='checkout-plans-icon' /> Effortless backlink management</li>
                    <li><AiOutlineCheckCircle id='checkout-plans-icon' /> Real-time analytics</li>
                    <li><AiOutlineCheckCircle id='checkout-plans-icon' /> 24/7 Support</li>
                </div>
            </div>
        </div>
    )
}

export { RegisterPlansInfo }