import { useMemo } from "react"
import { useSelector } from "react-redux"

function useShowSidebarDropdown( state ){
    // Store
    const backlinksDropdownOpen = useSelector(({ sidebar }) => sidebar.backlinksDropdownOpen)
    const outlinksDropdownOpen = useSelector(({ sidebar }) => sidebar.outlinksDropdownOpen)

    // Create a holding variable for determining which dropdown to show
    const showDropdown = useMemo(()=>{
        if((state === 'outlink-connections' && outlinksDropdownOpen) || (state === 'backlink-connections' && backlinksDropdownOpen)){
            return true
        } else {
            return false
        }
    }, [backlinksDropdownOpen, outlinksDropdownOpen, state])

    return showDropdown
}

export default useShowSidebarDropdown