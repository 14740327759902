import { createSlice } from "@reduxjs/toolkit";
import helpService from '../../services/helpService'

const initialState = {
    helpArticles: [],
    loading: true,
    error: null
}

const helpSlice = createSlice({
    name:'helpArticles',
    initialState,
    reducers:{
        setHelpArticles(state, action){
            state.helpArticles = action.payload
        },
        setLoading(state, action){
            state.loading = action.payload
        },
        setError(state, action){
            state.action = action.payload
        }
    }
})

export const { setHelpArticles, setLoading, setError } = helpSlice.actions

export const initializeHelpArticles = () =>{
    return async dispatch => {
        try {
            const response = await helpService.getAll()
            dispatch(setHelpArticles(response.data))
            dispatch(setLoading(false))
        } catch (error) {
            dispatch(setError(error.message))
        }
    }
}

export default helpSlice.reducer