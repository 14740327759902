import { RiListSettingsLine } from "react-icons/ri";

export default function BacklinkOptionsCollapsed ({ setShowBacklinkSettings }){
    return(
        <button 
            className="backlink-options-collapsed"
            onClick={()=>{ setShowBacklinkSettings(true) }}
        >
            <RiListSettingsLine />
        </button>
    )
}

