import { IoMdClose } from "react-icons/io";

export default function CloseModalButton ({ handler }){
    const handleClose = () =>{
        if(handler){
            handler()
        }
    }

    return(
        <button 
            className="close-modal-btn"
            onClick={handleClose}
        >
            <IoMdClose />
        </button>
    )
}