const GuideOption = ({ value, label, option, setOption }) =>{
    return(
        <button 
            className={`${option===value? "guide-content-nav-btn-active": "guide-content-nav-btn"}`}
             onClick={()=>{setOption(value)}}
        >
            {label}
        </button>
    )
}

export { GuideOption }