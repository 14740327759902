import { useSelector } from "react-redux"

import { AiOutlineMail } from 'react-icons/ai'


const RegisterFormEmailInput = ({ email, setEmail, showEmailError, setShowEmailError }) =>{
    // Store
    const users = useSelector(({ users }) => users.users )

    // Check email validity
    const checkEmailValidity = () =>{
        const emailExists = users.find(user => user.email === email)

        if(emailExists){
            setShowEmailError(true)
        } else{
            setShowEmailError(false)
        }
    }

    return(
            <div className="register-form-input">
                <AiOutlineMail className='register-icons' />
                <input 
                    type="email" 
                    placeholder='Email' 
                    value={email}
                    onKeyUp={checkEmailValidity}    
                    onChange={({target})=>{setEmail(target.value)}}
                    required
                />
            </div>
    )
}

export { RegisterFormEmailInput }