import { useEffect } from 'react'
import './contact.css'

import Nav from "../../components/Nav/Nav"
import { ContactForm } from './ContactForm'
import Footer from '../../components/Footer/Footer'

const Contact = () =>{
    // Scroll to top when component renders
    useEffect(()=>{
        window.scrollTo(0, 0)
    }, [])

    return(
        <div className="contact">
            <Nav />

            <header className='contact-header'>
                <h1>Contact Us</h1>
                <p>Our team is here to find the right solution for you, discuss various feature and pricing options, and connect you with the info you need</p>
            </header>

            <ContactForm />

            <Footer />
        </div>
    )
}

export { Contact }