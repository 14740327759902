import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { initializeAllLinks } from '../../../store/reducers/linksReducer'
import { config } from '../../../apiConfig'

import './profile-landing.css'

// Components
import ProfileLandingStats from './ProfileLandingStats/ProfileLandingStats'
import ProfileLandingActivity from "./ProfileLandingActivity/ProfileLandingActivity"
import ProfileLandingSkeleton from './ProfileLandingSkeleton'

const ProfileLanding = () =>{
    // Store
    const currentUser = useSelector( state => state.currentUser)
    const links = useSelector(({links}) => links.allLinks)
    const linksLoading = useSelector(({links}) => links.loading)
    const linksError = useSelector(({links}) => links.error)
    const dispatch = useDispatch()

    // Get all links from database
    useEffect(()=>{
        dispatch(initializeAllLinks(config(currentUser.token)))
    }, [dispatch, currentUser.token])

    // Show skeleton while loading
    if (linksLoading) return <ProfileLandingSkeleton />
    if (linksError) return <p>Error: {linksError}</p>

    return(
        <div className="profile-landing">
            <div className="profile-landing-content">
                <div className="profile-landing-header">
                    <h1>Good afternoon, <span>{currentUser.userForToken.name}!</span></h1>
                </div>
                <ProfileLandingStats links={links} />
                <ProfileLandingActivity links={links} />
            </div>
        </div>
    )
}

export default ProfileLanding