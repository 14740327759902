import { useStripe } from "@stripe/react-stripe-js";
import { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"

import { updateCurrentUser } from "../../../../store/reducers/currentUserReducer"
import registerService from "../../../../services/registerService"
import { config } from '../../../../apiConfig'

import { Spinner } from "../../../../common/Spinner";

import svg from '../../../../resources/svg/payment.svg'
import { BsArrowRight } from 'react-icons/bs'

const RegisterPaymentSuccessIntent = ({ clientSecret }) =>{
    // Stipe
    const stripe = useStripe()

    // Store
    const dispatch = useDispatch()
    const currentUser = useSelector(({ currentUser }) => currentUser)

    // Local state
    const [message, setMessage] = useState(null)

    // Function to handle payment success
    const handlePaymentSuccess = async (paymentIntent, isMounted) =>{
        try {
            // Send request for updating user
            const response = await registerService.updateUserAnyData({ 
                billing: paymentIntent.shipping.address, 
                isSubscribed: true 
            },
            config(currentUser.token)
        )
        
            if(isMounted){
            // Retrieve currentUser from locastorage and update
            const storedUser = JSON.parse(localStorage.getItem('currentUser' || '{}'))
            storedUser.userForToken = response.data

            // Save updated currentUser in localstorage and store
            localStorage.setItem('currentUser', JSON.stringify(storedUser))
            dispatch(updateCurrentUser(storedUser))

            setMessage("Payment was successful!")
            }
        } catch (error) {
            if(isMounted){
                console.log(error.message)
            }
        }
    }

    // Call function to handle payment intent that updates user with data from the payment
    useEffect(()=>{
        let isMounted = true

        async function handlePaymentIntent(){
            if(!stripe){
                return;
            }
        
            await stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) =>{
                if (paymentIntent.status === 'succeeded') {
                    handlePaymentSuccess(paymentIntent, isMounted)
                } else if (paymentIntent.status === 'processing'){
                    setMessage("Payment processing. We'll update you when payment is received.")
                } else if (paymentIntent.status === 'requires_payment_method'){
                    setMessage("Payment failed. Please try another payment method.")
                      // Redirect your user back to your payment page to attempt collecting
                      // payment again
                } else{
                    setMessage("Something went wrong.")
                }
            })
        }
        handlePaymentIntent()
    
        // Cleanup
        return () =>{ isMounted = false }
    }, [stripe])

    // Return spinner if stripe hasn't loaded
    if(!stripe || !message) return <Spinner />

    return(
        <div className="register-payment-success">
            <span>Thank you</span>
            <img src={svg} alt="success" />
            <h1>{message}</h1>
            <p>Your registration is complete, you can now start using Linkeby. Start elevating your website.</p>
            <Link to="/profile" className="register-payment-success-link">Go to Dashboard <BsArrowRight /></Link>
        </div>
    )
}

export { RegisterPaymentSuccessIntent }