import axios from "axios";
const baseUrl = `${process.env.REACT_APP_API_BASE_URL}plans`

const getAll = () => axios.get(baseUrl)

const postPlan = ( data ) => axios.post(baseUrl, data)

const editPlan = ( id, data ) => axios.put(`${baseUrl}/${id}`, data)

const planService = { getAll, postPlan, editPlan }
export default planService