import { useRef, useState } from 'react'
import useClickOutside from '../../../../../../hooks/useClickOutside'

import { BsThreeDotsVertical } from 'react-icons/bs'

const BacklinksTableItemSingleActions = ({ connectionStatus }) =>{
    // State
    const [showOptions, setShowOptions] = useState(false)

    // Refs
    const wrapperRef = useRef()

    // Hide notifications on click outside
    const handleClickOutside = () =>{
        setShowOptions(false)
    }
    useClickOutside(wrapperRef, handleClickOutside)

    // Check if connection is "approved" before we render the actual content
    if(connectionStatus === "pending") return <div><BsThreeDotsVertical className="backlinks-table-items-icon-disabled" /></div>

    return(
        <div className="backlinks-table-items-single-actions" ref={wrapperRef}>
            <button className="backlinks-table-items-single-actions-btn" onClick={()=>{ setShowOptions(!showOptions) }}><BsThreeDotsVertical /></button>

            {showOptions &&
            <div className='backlinks-table-items-single-actions-options'>
                <button>Report</button>
            </div>}
        </div>
    )
}

export { BacklinksTableItemSingleActions }