import { useState } from "react"
import { useStripe, useElements, PaymentElement, AddressElement } from "@stripe/react-stripe-js"

const RegisterPaymentTrialForm = () =>{
    // Stripe
    const stripe = useStripe()
    const elements = useElements()

    // Locals state
    const [message, setMessage] = useState(null)
    const [isProcessing, setIsProcessing] = useState(false)

    // Handle form submission
    const handleSubmit = async (e) =>{
        e.preventDefault()

        // Check if stripe and elements are initialised
        if(!stripe || !elements){
            return;
        }

        setIsProcessing(true)

        elements.submit()

        const result = stripe.createPaymentMethod({
            elements,
        })

        console.log(result)

        // Set error if error occurs
        // if(error){
        //     setMessage(error.message)
        // } else{
        //     setMessage("Unexpected state")
        // }

        setIsProcessing(false)
    }

    return(
        <div>
            <form onSubmit={handleSubmit}>   
                <div className="checkout-form-wrapper">
                    <h4>Billing details</h4>
                    <AddressElement options={{mode: 'shipping'}} />
                </div>

                <div className="checkout-form-wrapper">
                    <h4>Payment details</h4>
                    <PaymentElement />
                </div>          

                <button type="submit" disabled={isProcessing}>
                    <span>
                        {isProcessing? "Processing...": "Pay"}
                    </span>
                </button>

                <small style={{ color: "#ff3333" }}>{message}</small>
            </form>
        </div>
    )
}

export { RegisterPaymentTrialForm }