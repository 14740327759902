import { useSelector } from "react-redux"

import useShowSidebarDropdown from "../../../../../hooks/sidebar/useShowSidebarDropdown"

import { ProfileSidebarOptionsDropdownSubButton } from './ProfileSidebarOptionsDropdownSubButton'
import { ProfileSidebarOptionsDropdownMainButton } from './ProfileSidebarOptionsDropdownMainButton'

const ProfileSidebarOptionsDropdown = ({ icon, title, state, userConnections }) =>{
    // Store
    const userWebsites = useSelector(({ websites }) => websites.userWebsites)

    // Get showDropdown state from custom hook
    const showDropdown = useShowSidebarDropdown(state)

    return(
        <div className="profile-sidebar-options-nested">
            <ProfileSidebarOptionsDropdownMainButton icon={icon} title={title} state={state} />

            {showDropdown &&
                <div className="profile-sidebar-options-nested-sub-btns">
                    {userWebsites.map( website => 
                        <ProfileSidebarOptionsDropdownSubButton 
                        key={website.id} 
                        website={website} 
                        state={state}
                        userConnections={userConnections}
                        />
                    )}
                </div>
            }
        </div>
    )
}

export { ProfileSidebarOptionsDropdown }