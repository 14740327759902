import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { initializePlans } from "../../../store/reducers/planReducer"

import SubscriptionPlansOptionsSingle from "./SubscriptionPlansOptionsSingle"
import SubscriptionPlansOptionsSkeleton from "./SubscriptionPlansOptionsSkeleton"

const SubscriptionPlansOptions = ({ activePlan }) =>{
    // Store
    const plans = useSelector(({ plans }) => plans.plans )
    const loading = useSelector(({ plans }) => plans.loading )
    const dispatch = useDispatch()

    // Get plans from database
    useEffect(()=>{
        dispatch(initializePlans())
    }, [dispatch])

    if (loading) return <SubscriptionPlansOptionsSkeleton />

    return(
        <div className="subscription-plans-options">
            <SubscriptionPlansOptionsSingle plan={plans[1]} activePlan={activePlan} />
        </div>
    )
}

export default SubscriptionPlansOptions