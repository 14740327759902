import './backlinks-table-nav.css'

import { BacklinksTableNavOptions } from './BacklinksTableNavOptions'

const BacklinksTableNav = ({ connectionsFilter, setConnectionsFilter, role }) =>{
    return(
        <div className='backlinks-table-nav'>
            <BacklinksTableNavOptions 
                connectionsFilter={connectionsFilter} 
                setConnectionsFilter={setConnectionsFilter} 
                role={role}
            />
        </div>
    )
}

export { BacklinksTableNav }