import axios from "axios"
import store from '../store/store'
import { setError } from '../store/reducers/errorReducer'

const api = axios.create({
    baseUrl: process.env.REACT_APP_API_BASE_URL
})

api.interceptors.response.use(
    (response) => response, 
        (error)=>{
            if(error.response){
                if(error.response.data.message){
                    store.dispatch(setError(error.response.data.message))
                } else if(error.response.data.error){
                    store.dispatch(setError(error.response.data.error))
                } else if(error.response.statusText){
                    store.dispatch(setError(error.response.statusText))
                }
                
            } else if(error.request){
                console.log("Reqeust error", error.request)
            } else{
                console.log("Error", error.message)
            }

            return Promise.reject(error)
        }
)

export default api