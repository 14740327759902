import { Link } from "react-router-dom"

const RegisterPlansOption = ({ value, price }) =>{
    return(
        <div className="register-plans-option">
            <div>
                <h5>{value} Plan {value === "Annual" && <span>BEST VALUE - SAVE 30%</span>}</h5>
                <p>{value === "Monthly"? "$29.99": "$279.99"}</p>
                <small>{value === "Monthly"? "Auto-renews every month, no further actions required": "Cancel anytime, no additional fees"}</small>
            </div>

            <div className="register-plans-option-links">
                {/* <Link to={`/register/plans/trial/${price}`}>14-day Trial</Link> */}
                <Link to={`/register/plans/payment/${price}`} className="register-plans-options-link">Buy</Link>
            </div>
        </div>
    )
}

export { RegisterPlansOption }