import avatar from '../../../resources/samples/avatar.png'
import avatar2 from '../../../resources/samples/avatar2.png'
import stats1 from '../../../resources/samples/stats1.jpg'
import stats2 from '../../../resources/samples/stats2.jpg'

import { MdScreenSearchDesktop, MdAttachFile } from 'react-icons/md'
import { AiOutlineUser, AiOutlineLink, AiOutlineSend, AiOutlineMessage } from 'react-icons/ai'
import {FiLink} from 'react-icons/fi'
import { TbTrendingUp } from 'react-icons/tb'

export default function HeroUI (){
    return(
        <div className="hero-sample">
            <div className="hero-sample-ui">
                <div className="hero-sample-ui-sidebar">
                    <div className="hero-sample-ui-sidebar-top">
                        <div style={{ width:'100%', height: '14px', background: '#ffe100', marginBottom:'0.5em', borderRadius:'15px' }}></div>
                        <div><div></div><div></div></div>
                        <div style={{ background: '#49ff46' }}></div>
                        <div><div></div><div></div></div>
                        <div><div></div><div></div></div>
                    </div>
                    <div className="hero-sample-ui-sidebar-bottom">
                        <div></div>
                    </div>
                </div>
                <div className="hero-sample-ui-content">
                    <div className="hero-sample-ui-content-nav">
                        <div></div>
                        <div></div>
                    </div>
                    <div className="hero-sample-ui-content-dash">
                        <div className="hero-sample-ui-content-top">
                            <div>
                                <div><img src={stats2} alt="" /></div>
                            </div>
                            <div>
                                <div style={{ width:'50%', height: '4px', background: '#35009e' }}></div>
                                <div style={{ width:'100%', height: '2px', background: 'grey', marginBottom:'0.5em' }}></div>
                                <div style={{ width:'60%', height: '4px', background: '#35009e' }}></div>
                                <div style={{ width:'90%', height: '2px', background: 'grey', marginBottom:'0.5em' }}></div>
                                <div style={{ width:'20%', height: '6px', background: 'grey', marginBottom:'0.5em' }}></div>
                                <div style={{ width:'100%', height: '4px', background: '#35009e' }}></div>
                            </div>
                            <div>
                                <div style={{ width:'50%', height: '4px', background: '#35009e' }}></div>
                                <div style={{ width:'100%', height: '2px', background: 'grey', marginBottom:'0.5em' }}></div>
                                <div style={{ width:'20%', height: '6px', background: 'grey', marginBottom:'0.5em' }}></div>
                                <div style={{ width:'60%', height: '4px', background: '#35009e' }}></div>
                                <div style={{ width:'90%', height: '2px', background: 'grey', marginBottom:'0.5em' }}></div>
                                <div style={{ width:'100%', height: '4px', background: '#35009e' }}></div>
                            </div>
                        </div>
                        <div className="hero-sample-ui-content-bottom">
                            <div>
                                <div>
                                    <div style={{ width:'60%', height: '4px', background: '#35009e' }}></div>
                                    <div style={{ width:'80%', height: '2px', background: 'grey' }}></div>
                                    <div style={{ width:'50%', height: '2px', background: 'grey', marginBottom:'0.5em' }}></div>
                                    <div style={{ width:'50%', height: '10px', background: '#35009e', borderRadius: '5px' }}></div>
                                </div>
                                <div><img src={stats1} alt="" /></div>
                            </div>
                            <div>
                                <div>
                                    <div style={{ width:'50%', height: '4px', background: '#35009e' }}></div>
                                    <div style={{ width:'90%', height: '2px', background: 'grey' }}></div>
                                    <div style={{ width:'100%', height: '2px', background: 'grey', marginBottom:'0.5em' }}></div>
                                    <div style={{ width:'60%', height: '4px', background: '#35009e' }}></div>
                                    <div style={{ width:'50%', height: '10px', background: '#35009e', borderRadius: '5px' }}></div></div>
                                <div><img src={stats1} alt="" /></div>
                        </div>
                        </div>
                        <div className="hero-sample-ui-content-bottom-bar">
                            <div style={{ width:'15%', height: '4px', background: 'grey' }}></div>
                            <div style={{ width:'65%', height: '4px', background: 'grey' }}></div>
                            <div style={{ width:'20%', height: '10px', background: '#35009e', borderRadius: '5px' }}></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="hero-sample-sidebar">
                <div className="hero-sample-sidebar-div"><MdScreenSearchDesktop /> Dashboard</div>
                <div className="hero-sample-sidebar-div"><AiOutlineUser /> Profile</div>
                <div className="hero-sample-sidebar-div"><AiOutlineLink /> Outlinks</div>
                <div className="hero-sample-sidebar-div"><FiLink /> Outlink Connects</div>
                <div className="hero-sample-sidebar-div"><AiOutlineLink /> Backlinks</div>
                <div className="hero-sample-sidebar-div"><FiLink /> Backlinks Connects</div>

                <div className="hero-sample-growth">
                    <span>10 new backlinks</span>
                    <TbTrendingUp />
                </div>

                <div className="hero-sample-connection">
                    <div></div>
                    <MdAttachFile />
                    <AiOutlineSend />
                </div>

                <div className="hero-sample-avatar2">
                    <img src={avatar2} alt="avatar" />
                </div>
            </div>

            <div className="hero-sample-dash">
                <div className="hero-sample-dash-div">
                    <span>Outlinks</span>
                    <h5>45k</h5>
                    <small>14k new outlinks last week</small>
                </div>
                <div className="hero-sample-dash-div">
                    <span>Backlinks</span>
                    <h5>50k</h5>
                    <small>13k new backlinks last week</small>
                </div>
                <div className="hero-sample-dash-div">
                    <span>Websites</span>
                    <h5>20k</h5>
                    <small>10k new websites last week</small>
                </div>
                <div className="hero-sample-avatar">
                    <img src={avatar} alt="avatar" />
                </div>
            </div>

            <div className="hero-sample-message">
                <span><AiOutlineMessage /></span>
            </div>
        </div>
    )
}