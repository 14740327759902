const SubscriptionPlansTop = ({ activePlan, setActivePlan }) =>{
    return(
        <div className="subscription-plans-top">
            <div>
                <button 
                    className={`${activePlan==="monthly"? "subscription-plans-top-btn-active": "subscription-plans-top-btn"}`}
                    onClick={()=>{setActivePlan("monthly")}}
                >Monthly
                </button>
                <button 
                    className={`${activePlan==="yearly"? "subscription-plans-top-btn-active": "subscription-plans-top-btn"}`}
                    onClick={()=>{setActivePlan("yearly")}}
                >Yearly
                </button>
            </div>
            <small>*Our prices are calculated in USD</small>
        </div>
    )
}

export default SubscriptionPlansTop