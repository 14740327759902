import { FeedbackRating } from "./FeedbackRating"

const Feedback = () =>{
    return(
        <div className="feedback">
            <h1>Your feedback is vital and we take every suggestion into consideration</h1>

            <FeedbackRating />

            <div className="feedback-comments">
                <label htmlFor="textarea">What was the reason for your score?</label>
                <textarea name="textarea" cols="30" rows="10"></textarea>
                <button>Send</button>
            </div>
        </div>
    )
}

export { Feedback }