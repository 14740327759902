import { useSelector } from 'react-redux'

import {IoIosArrowForward} from 'react-icons/io'

const SupportFaqsSingle = ({ article, setActiveFaq }) =>{
    return(
        <div className="support-faqs-single" onClick={()=>{setActiveFaq(article)}}>
            <p>{article.title}</p>
            <button className='support-faqs-single-link'><IoIosArrowForward /></button>
        </div>
    )
}

const SupportFaqs = ({ setActiveFaq }) =>{
    // Store
    const helpArticles = useSelector(({helpArticles}) => helpArticles.helpArticles)

    return(
        <div className="support-faqs">
            <h5>Popular questions</h5>
            <div className="support-faqs-wrapper">
                {helpArticles.map( article => 
                    <SupportFaqsSingle 
                        key={article.id} 
                        article={article} 
                        setActiveFaq={setActiveFaq} 
                    /> 
                )}
            </div>
        </div>
    )
}

export default SupportFaqs