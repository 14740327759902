import { Link } from 'react-router-dom'
import './footer.css'

import {BsFacebook} from 'react-icons/bs'
import {AiFillTwitterCircle} from 'react-icons/ai'
import {AiFillInstagram} from 'react-icons/ai'
import { Copyright } from './Copyright'

const Footer = () =>{
    return(
        <footer className="footer">
            <div className="footer-top">
                <div className="footer-top-divs">
                    <div>
                        <h5>Main</h5>
                        <Link to="/" className='footer-top-links'>Home</Link>
                        <Link to="/" className='footer-top-links'>Features</Link>
                        <Link to="/about" className='footer-top-links'>About Us</Link>
                        <Link to="/" className='footer-top-links'>Pricing</Link>
                        <Link to="/contact" className='footer-top-links'>Contact Us</Link>
                    </div>

                    <div>
                        <h5>User</h5>
                        <Link className='footer-top-links'>Account</Link>
                        <Link className='footer-top-links'>Settings</Link>
                        <Link className='footer-top-links'>Manage account</Link>
                    </div>

                    <div>
                        <h5>Extras</h5>
                        <Link className='footer-top-links'>Help</Link>
                        <Link className='footer-top-links'>Support</Link>
                    </div>

                    <div>
                        <h5>Contact</h5>
                        <Link className='footer-top-links'>Send an email</Link>
                        <Link className='footer-top-links'>Find adress</Link>
                        <Link className='footer-top-links'>Call customer service</Link>
                    </div>
                </div>

                <div className="footer-bottom-socials-wrapper">
                    <Link className='footer-bottom-socials'><BsFacebook /></Link>
                    <Link className='footer-bottom-socials'><AiFillInstagram /></Link>
                    <Link className='footer-bottom-socials'><AiFillTwitterCircle /></Link>
                </div>
            </div>

            <div className="footer-bottom">
                <Copyright />

                <div className="footer-bottom-legal">
                    <Link to="/terms" className='footer-bottom-links'>Terms & Conditions</Link>
                    <Link to="/privacy" className='footer-bottom-links'>Privacy Policy</Link>
                </div>
            </div>
        </footer>
    )
}

export default Footer