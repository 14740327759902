import { getDateFromTimestamp } from "../../../../helpers/helpers"

// Components
import { BacklinksTableItemSingleStatus } from "./Fields/BacklinksTableItemSingleStatus"
import BacklinksTableItemSingleChat from './Fields/BacklinksTableItemSingleChat'
import { BacklinksTableItemSingleMissing } from "./Fields/BacklinksTableItemSingleMissing"
import { BacklinksTableItemSingleActions } from "./Fields/Actions/BacklinksTableItemSingleActions"
import { BacklinksTableItemSingleSource } from "./Fields/BacklinksTableItemSingleSource"
import { BacklinksTableItemSingleTarget } from "./Fields/BacklinksTableItemSingleTarget"
import BacklinksTableItemSingleApproval from "./Fields/Approval/BacklinksTableItemSingleApproval"

const BacklinksTableItemSingle = ({ connection, selectedConnectionItem, setSelectedConnectionItem, role }) =>{
    // Handle clicking on single connection item
    const handleMouseDown = async () =>{
        // Change the selected item in state
        setSelectedConnectionItem(connection.id)
    }

    // If one of the websites has been deleted do not render
    if(!connection.sourceWebsite || !connection.targetWebsite) return <BacklinksTableItemSingleMissing connectionId={connection.id} />

    return(
        <div className="backlinks-table-items-single-wrapper">
            <div 
                className={`
                    ${connection.isNewlyCreated? "backlinks-table-items-single-new": "backlinks-table-items-single"} 
                    ${selectedConnectionItem === connection.id && "backlinks-table-items-single-active"}`
                } 
                onMouseDown={handleMouseDown}
            >
                <BacklinksTableItemSingleStatus connection={connection} />
                <BacklinksTableItemSingleSource connection={connection} />
                <BacklinksTableItemSingleTarget connection={connection} />
                <div>{connection?.backlinkType}</div>
                <div>{connection.completedAt? getDateFromTimestamp(connection.completedAt): "-"}</div>
                <BacklinksTableItemSingleChat connection={connection} />
                <BacklinksTableItemSingleActions connectionStatus={connection.approved} />
            </div>

            {connection.approved === "pending" &&  <BacklinksTableItemSingleApproval sourceWebsite={connection.sourceWebsite} connectionId={connection.id} />}
        </div>
    )
}

export { BacklinksTableItemSingle }