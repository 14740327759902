export const getLastWeekStats = (data) =>{
    // Get the timestamp for one week ago
    const oneWeekAgo = new Date().getTime() - 7 * 24 * 60 * 60 * 1000; 

    // Create an array of timestamps for each day in the past week
    const timestamps = Array.from({ length: 7 }, (_, i) => {
        const timestamp = oneWeekAgo + (i * 24 * 60 * 60 * 1000);
        return timestamp;
    })
  
    // Create an object with keys for each timestamp and values initialized to 0
    const countsByDay = timestamps.reduce((obj, timestamp) => {
        obj[timestamp] = 0;
        return obj;
    }, {})
  
    // Group entries by the day they were created and count the number of users in each group
    data.forEach(d => {
        // Get the timestamp of each entry
        const createdAt = new Date(d.createdAt).getTime()

        // Find which day corresponds to the entry createAt
        const dayIndex = Math.floor((createdAt - oneWeekAgo) / (24 * 60 * 60 * 1000))

        if (dayIndex >= 0 && dayIndex < timestamps.length) {
            const timestamp = timestamps[dayIndex];
            countsByDay[timestamp]++
        }
    })
  
    // Convert countsByDay object to an array of objects with 'timestamp' and 'count' properties
    return Object.entries(countsByDay).map(([timestamp, count]) => ({
        timestamp: new Date(Number(timestamp)),
        count: count
    }))
}