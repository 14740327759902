const OnboardingCloseButton = ({ className, label, setShowOnboarding }) =>{
    // If user skips the onboarding or it has finished, save the result on storage and don't render the component
    const handleCloseOnboarding = () =>{
        localStorage.setItem('showOnboarding', JSON.stringify(false))
        setShowOnboarding(false)
    }

    return(
        <button className={className} onClick={handleCloseOnboarding}>{label}</button>
    )
}

export { OnboardingCloseButton }