import axios from "axios";
const baseUrl = `${process.env.REACT_APP_API_BASE_URL}websites`

const getAll = () => axios.get(baseUrl)

const getUserWebsites = (id, config) => axios.get(`${baseUrl}/${id}`, config)

const postFirstWebsite = (data) => axios.post(`${baseUrl}/first`, data)

const postWebsite = (data, config) => axios.post(baseUrl, data, config)

const activateWebsite = (id, data, config) => axios.put(`${baseUrl}/${id}`, data, config)

const deleteWebsite = (id, config) => axios.delete(`${baseUrl}/${id}`, config)

const updateWebsite = (id, data, config) => axios.put(`${baseUrl}/edit/${id}`, data, config)

const websiteService = { getAll, getUserWebsites, postWebsite, postFirstWebsite, activateWebsite, deleteWebsite, updateWebsite }
export default websiteService