import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const WebsiteDetailsSkeleton = () =>{
    return(
        <SkeletonTheme baseColor="#ccc" highlightColor="#e6e1e1">
            <div className="website-details">
                <div className="website-details-header">
                    <h1><Skeleton width={100} /></h1>
                    <div className="website-details-header-btn"><Skeleton width={100} /></div>
                </div>

                <div className="website-details-summary">
                    <div className='website-details-summary-card'><Skeleton /></div>
                    <div className='website-details-summary-card'><Skeleton /></div>
                    <div className='website-details-summary-options'><Skeleton width={50} /></div>
                </div>
            
                <div className="website-details-stats"><Skeleton width={'100%'} height={'100%'} /></div>

                <div className="website-details-links">
                    <Skeleton width={'100%'} height={'100%'} />
                </div>
            </div>
        </SkeletonTheme>
    )
}

 export { WebsiteDetailsSkeleton }