import { useEffect, useRef, useState } from "react"

import {BsSearch} from 'react-icons/bs'

import logo from '../../../resources/logo-link.png'

const SupportSearch = () =>{
    // local state
    const [inputValue, setInputValue] = useState("")
    const [data, setData] = useState([]) 
    const [showSearchResults, setShowSearchResults] = useState(false)

    // refs
    const wrapperRef = useRef(null)

    // Close results on click outside
    useEffect(()=>{
        function handleClickOut (e){
            if(wrapperRef.current && !wrapperRef.current.contains(e.target)){
                setShowSearchResults(false)
            }
        }

        window.addEventListener('click', handleClickOut)

        return () => window.removeEventListener('click', handleClickOut)
    }, [wrapperRef])

    return(
        <div className="support-search" ref={wrapperRef}>
            <div className="support-search-img"><img src={logo} alt="logo" /></div>

            <h5 className="profile-dashboard-title">How can we help today?</h5>

            <form className="support-search-form">
                <div className="support-search-input-wrapper">
                    <div className="support-search-input" onFocus={()=>{setShowSearchResults(true)}}>
                        <input type="text" placeholder="Search" />
                        <BsSearch />
                    </div>

                    {showSearchResults &&
                    <div className="support-search-input-results">
                        {data.length === 0 && <p>No results</p>}
                    </div>}
                </div>
            </form>
        </div>
    )
}

export default SupportSearch