import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { handleDeleteWebsite } from "../../../../../store/reducers/websiteReducer"
import { config } from "../../../../../apiConfig"

const WebsiteDetailsDangerZoneDelete = ({ websiteId, setShowDeleteAlert, deleteConfirmed, setDeleteConfirmed }) =>{
    // Store
    const currentUser = useSelector(({ currentUser }) => currentUser)
    const dispatch = useDispatch()

    // Router
    const navigate = useNavigate()

    // Handle delete
    const handleDelete = () =>{
        // Send request to delete website
        dispatch(handleDeleteWebsite(websiteId, config(currentUser.token)))

        // Set delete confirmation to false again
        setDeleteConfirmed(false)
        setShowDeleteAlert(false)
        
        // Navigate to website overview page
        navigate("/profile/website-overview", { replace: true })
    } 

    // Check for delete confirmation and perform action
    useEffect(()=>{
        // Check if action to delete was confirmed and procced with deletion
        if(deleteConfirmed){
            handleDelete()
        }
    }, [deleteConfirmed])

    return(
        <div>
            <div>
                <h5>Delete website</h5>
                <p>This action is permanent. All links and data associated with this website will be lost</p>
            </div>

            <button 
                className='website-details-danger-zone-options-delete-btn'
                onClick={()=>{setShowDeleteAlert(true)}}
            >
                Delete website
            </button>
        </div>
    )
}

export { WebsiteDetailsDangerZoneDelete }