import { Link } from "react-router-dom"

const RegisterPaymentFailure = () =>{
    return(
        <div className="register-payment-failure">
            <h1>You are already subscribed</h1>
            <p>You tried to access the payment page but it seems that you already have a subscription.</p>
            <small>If you want to update your payment method or plan details, visit our <Link className="register-payment-link" to="/profile/billing">Billing</Link> page.</small>
            <small>Alternatively return to <Link className="register-payment-link" to="/profile">Dashboard</Link></small>
        </div>
    )
}   

export { RegisterPaymentFailure }