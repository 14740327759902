import { useState } from "react"

import BacklinksTableItemSinglePendingButtonsClose from "./BacklinksTableItemSinglePendingButtonsClose"
import CheckboxOption from "../../../../Forms/CheckboxOption"

export default function BacklinksTableItemSinglePendingButtons ({ setShowModal }){    
    // Local state
    const [dontShow, setDontShow] = useState(false)

    // Checkbox handler function
    const checkboxHandler = () =>{        
        setDontShow(!dontShow)
    }

    return(
        <div className="backlinks-table-items-single-pending-btns">
            <CheckboxOption value={dontShow} handler={checkboxHandler} label="Don't show again" color="white" />
            <BacklinksTableItemSinglePendingButtonsClose dontShow={dontShow} setShowModal={setShowModal} />
        </div>
    )
}