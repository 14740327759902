import { Link } from 'react-router-dom'

export default function GuideSingle ({ item }){
    // Conditionally render waitlist or login section
    const sectionToRender = () =>{
        if(process.env.REACT_APP_API_APP_STAGE === 'waitlist'){
            return <Link to="/waitlist" className='guide-single-link'>Get started</Link>
        }

        return <Link to="/register" className='guide-single-link'>Get started</Link>
    }

    return(
        <div className="guide-single">
            <div className="guide-single-img">
                <div className='guide-single-img-top'></div>
                <img src={item.img} alt="ui" />
            </div>

            <div className="guide-single-text">
                <h5><span>{item.count}</span>{item.title}</h5>

                <div>
                    <p>{item.point1}</p>
                </div>
                <div>
                    <p>{item.point2}</p>
                </div>
                
                {sectionToRender()}
            </div>
        </div>
    )
}
