import { Helmet } from 'react-helmet-async'
import './register-plans.css'

import { AiFillInfoCircle } from 'react-icons/ai'

import Nav from '../../Nav/Nav'
import { RegisterPlansInfo } from './RegisterPlansInfo'
import { RegisterPlansOption } from "./RegisterPlansOption"
import { DashboardFooter } from '../../Footer/DashboardFooter'

import img from '../../../resources/subscription.jpg'

const RegisterPlans = () =>{
    return(
        <div className="register-plans">
            <Helmet>
                <title>Select your plan</title>
                <meta name='description' content='Select your subscription plan with Linkeby' />
            </Helmet>

            {/* <Nav /> */}

            <div className="register-plans-content">        
                <div><img src={img} alt="" /></div>
                <RegisterPlansInfo />

                <div className="register-plans-warning">
                    <div><AiFillInfoCircle /></div>
                    <p>This is for testing pursposes, you will not be charged when buying a plan. Please select any of the options.</p>
                </div>

                <div className="register-plans-options">
                    <RegisterPlansOption value="Monthly" price={process.env.REACT_APP_API_MONTHLY_PRICE_ID} />
                    <RegisterPlansOption value="Annual" price={process.env.REACT_APP_API_YEARLY_PRICE_ID} />
                </div>
            </div>

            <DashboardFooter />
        </div>
    )
}

export { RegisterPlans }