import { useDispatch } from 'react-redux'
import { setShowChat } from '../../../store/reducers/backlinksReducer'
import { getFirstLetter } from '../../../helpers/helpers'

// Icons
import {AiFillInfoCircle} from 'react-icons/ai'
import {BsThreeDotsVertical} from 'react-icons/bs'
import {IoIosArrowDown} from 'react-icons/io'

const BacklinksMessagesSingleTop = ({ participant }) =>{
    // Store
    const dispatch = useDispatch()

    return(
        <div className="conversation-messages-single-top">
            <div>{getFirstLetter(participant.name)}</div>

            <div>Chat with <span>{participant.name}</span></div>

            <div>
                <button><BsThreeDotsVertical /></button>
                <button><AiFillInfoCircle /></button>
                <button onClick={()=>{ dispatch(setShowChat(false)) }}><IoIosArrowDown /></button>
            </div>
        </div>
    )
}

export { BacklinksMessagesSingleTop }