import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import './new-users.css'

// Import components
import NewWebsitesSingle from './NewWebsitesSingle'
import NewWebsitesComments from './NewWebsitesComments'

/**
 * New users wrapper component
 */
const NewWebsites = () =>{
    // Get data from store
    const websites = useSelector( state => state.websites.allWebsites )

    // State
    const [filteredWebsites, setFilteredWebsites] = useState([])
    const [showCommentForm, setShowCommentForm] = useState(false)
    const [websiteToComment, setWebsiteToComment] = useState("")

    // Filter out the admin and only get new users
    useEffect(()=>{
        const filtered = websites.filter( website => website.status === "pending" && website.owner?.isAdmin !== true)
        setFilteredWebsites(filtered)

        return () =>{
            setFilteredWebsites([])
        }
    }, [websites])

    return(
        <div className="new-users">
            <h1 className='admin-h1'>New websites <span>({filteredWebsites.length})</span></h1>
            <p className='admin-sub'>Recently registered users, awaiting for website review and DR assignment.</p>

            <div className="new-users-table">
                <div className="new-users-titles">
                    <div><p>Website</p></div>
                    <div><p>User</p></div>
                    <div><p>Email</p></div>
                    <div><p>Existing user</p></div>
                    <div><p>Actions</p></div>
                </div>

                {filteredWebsites.length > 0 &&
                    <div className="new-users-single-wrapper">
                    {filteredWebsites.map( website => 
                        <NewWebsitesSingle
                            key={website.id}  
                            website={website}
                            setShowCommentForm={setShowCommentForm}
                            setWebsiteToComment={setWebsiteToComment}
                        />
                    )}
                </div>}
            </div>

            {showCommentForm && <NewWebsitesComments setShowCommentForm={setShowCommentForm} id={websiteToComment} value="" />}

            {filteredWebsites.length === 0? <div className='new-users-empty'><p>All websites have been given a DR</p></div>: ""}
        </div>
    )
}

export default NewWebsites