import './forms.css'

const Textarea = ({icon, placeholder, inputValue, setInputValue}) =>
{
    return(
        <div className="textarea">
            {icon}
            <textarea placeholder={placeholder} value={inputValue} onChange={({target})=>{setInputValue(target.value)}}></textarea>
        </div>
    )
}

export default Textarea