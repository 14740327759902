import { useState } from "react"

const ProfileWebsiteNichesWarning = ({ length, keyword }) =>{
    // Local state
    const [showMessage, setShowMessage] = useState(true)

    if(length !== 0) return null
    if(!showMessage) return null

    return(
        <div className="profile-website-niches-warning">
            <div>
                <p>Warning, by not having any {keyword} selected, your matching might be affected. It is advided to select at least one.</p>
                <button onClick={()=>{ setShowMessage(false) }}>OK, I understand</button>
            </div>
        </div>
    )
}

export { ProfileWebsiteNichesWarning }