import { useSelector } from "react-redux"
import './website-details-links.css'

import { WebsiteDetailsLinksSingle } from "./WebsiteDetailsLinksSingle"

const WebsiteDetailsLinks = ({ websiteId }) =>{
    // Store
    const allWebsiteLinks = useSelector(({ links }) => links.allWebsiteLinks)

    return(
        <div className="website-details-links">
            <h1>All website links</h1>

            <div className="website-details-links-list">
                <div className="website-details-links-titles">
                    <div></div>
                    <div>From</div>
                    <div>To</div>
                    <div>Placed</div>
                    <div>Date</div>
                </div>

                {allWebsiteLinks.map( link => <WebsiteDetailsLinksSingle key={link.id} link={link} websiteId={websiteId} />)}

                {allWebsiteLinks.length === 0 && <div className="website-details-links-empty"><p>No links for this website yet</p></div>}
            </div>
        </div>
    )
}

export { WebsiteDetailsLinks }