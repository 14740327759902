import { MdFileUpload } from "react-icons/md";

export default function FormInputFile ({ inputValue, setInputValue }){
    const handleFileChange = (e) =>{
        const file = e.target.files[0]

        setInputValue(file)
    }

    return(
        <label className="input-file">
            <input type="file" name='file' onChange={handleFileChange}/>
            <p>{inputValue && inputValue.name? inputValue.name: "No file chosen"}</p>
            <span><MdFileUpload /> Select file</span>
        </label>
    )
}