import useSortFilteredConnections from '../../../../hooks/backlinks/useSortFilteredConnections'

import { BacklinksTableItemSingle } from './BacklinksTableItemSingle'
import { BacklinksTableItemsListEmpty } from './BacklinksTableItemsListEmpty'

export default function BacklinksTableItemsList({ connectionsFilter, selectedConnectionItem, setSelectedConnectionItem, role }){
    // Get sorted filtered connections from hook
    const [filteredConnections, connectionsToRender] = useSortFilteredConnections(connectionsFilter, role)

    // Return nothing if connections array is null
    if(!filteredConnections) return null

    //If there are no connections, show message instaed 
    if(filteredConnections.length === 0) return <BacklinksTableItemsListEmpty />

    return(
        <>
            {connectionsToRender.map( connection => 
                <BacklinksTableItemSingle 
                    key={connection.id} 
                    connection={connection}
                    selectedConnectionItem={selectedConnectionItem}
                    setSelectedConnectionItem={setSelectedConnectionItem}
                    role={role}
                />
            )}
        </>
    )
}