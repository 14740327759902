import BacklinkOptionsFormInputCheck from "./BacklinkOptionsFormInputCheck";

import { Tooltip } from "../../../common/Tooptip/Tooltip";
import BacklinkOptionsFormInputCopy from "./BacklinkOptionsFormInputCheckCopy";

export default function BacklinkOptionsFormInputHOC ({ identifier, inputValue, setInputValue, label, children }){ 
    return(
        <div className="backlink-options-form-input-container">
            <label>{label}</label>

            <div>
                {children}

                <div className="backlink-options-form-input-container-btns">
                    <Tooltip text="Your preference for this field matches the suggestion" direction="top">
                        <BacklinkOptionsFormInputCheck inputValue={inputValue} identifier={identifier} />
                    </Tooltip>

                    <BacklinkOptionsFormInputCopy identifier={identifier} setInputValue={setInputValue} />
                </div>
            </div>
        </div>
    )
}