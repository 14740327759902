const Options = ({ title, icon, list, inputValue, setInputValue}) =>
{
    return(
        <div className="form-options">
            {icon}
            
            <select onChange={({target})=>{setInputValue(target.value)}} required value={inputValue}>
                <option value="">{title}</option>
                {list.map( item =>
                    <option key={item.id} value={item.title}>{item.title}</option>
                )}
            </select>
        </div>
    )
}

export default Options