import { useRef, useState } from 'react'
import './backlinks-table-items.css'

// Hooks
import useClickOutside from '../../../../hooks/useClickOutside'

// Components
import BacklinksTableItemsHeader from './BacklinksTableItemsHeader'
import BacklinksTableItemsList from './BacklinksTableItemsList'
import BacklinksTableItemSinglePending from './Pending/BacklinksTableItemSinglePending'
import BacklinksTableItemSinglePendingModal from './Pending/BacklinksTableItemSinglePendingModal'

const BacklinksTableItems = ({ connectionsFilter, role }) =>{    
    // Local state
    const [selectedConnectionItem, setSelectedConnectionItem] = useState(null)
    const [showPendingModal, setShowPendingModal] = useState(false)

    // Refs
    const wrapperRef = useRef()

    // Reset the selected item when user clicks outside
    const handleOutsideClick = () => {
        setSelectedConnectionItem(null)
    }

    useClickOutside(wrapperRef, handleOutsideClick)

    return(
        <div className='backlinks-table-items-wrapper' ref={wrapperRef}>
            {/* First row of table, with titles */}
            <BacklinksTableItemsHeader />
            
            {/* Connections list */}
            <BacklinksTableItemsList
                connectionsFilter={connectionsFilter}
                selectedConnectionItem={selectedConnectionItem} 
                setSelectedConnectionItem={setSelectedConnectionItem} 
                role={role} 
            />

            {/* Modal showing how many non-approved connections this website has */}
            <BacklinksTableItemSinglePending role={role} setShowPendingModal={setShowPendingModal} />
            {showPendingModal? <BacklinksTableItemSinglePendingModal setShowPendingModal={setShowPendingModal} />: ""}
        </div>
    )
}

export { BacklinksTableItems }