import { useState } from 'react'
import { useSelector } from 'react-redux'
import { getTime } from '../../../../helpers/helpers'

// Components
import { BacklinksMessagesSingleTextsMessageContent } from './BacklinksMessagesSingleTextsMessageContent'
import { BacklinksMessagesSingleTextsAutomated } from './BacklinksMessagesSingleTextsAutomated'

const BacklinksMessagesSingleTextsMessage = ({ message, isLast }) =>{   
    // Store
    const currentUser = useSelector( state => state.currentUser )

    // State 
    const [showTime, setShowTime] = useState(false)
    const [error, setError] = useState(null)

    if(message.isAutomated) return <BacklinksMessagesSingleTextsAutomated message={message} />

    return(
        <div className="conversation-messages-single-messages-wrapper">
            <div className={`${message.sender.id === currentUser.userForToken.id? "conversation-messages-single-messages-sender-wrapper": "conversation-messages-single-messages-reciever-wrapper"}`}>
                <BacklinksMessagesSingleTextsMessageContent 
                    showTime={showTime} 
                    setShowTime={setShowTime} 
                    message={message} 
                    setError={setError}
                />
                
                {/* Show message timestamp when clicked */}
                {!isLast && showTime? <small className="conversation-messages-single-messages-time">{getTime(message.createdAt)}</small>: ""}
                
                {/* Show last message timestamp */}
                {isLast? <small className="conversation-messages-single-messages-time">{getTime(message.createdAt)}</small>: ""}                
            
                {/* Show errors for failed requests */}
                <p style={{ color: 'red' }}>{error}</p>
            </div>
        </div>
    )
}

export { BacklinksMessagesSingleTextsMessage }