import { useDispatch, useSelector } from "react-redux";

import { handleDeleteConnection } from "../../../../../../store/reducers/connectionReducer";
import { config } from '../../../../../../apiConfig'

import { Tooltip } from "../../../../../../common/Tooptip/Tooltip";

import { IoMdClose } from "react-icons/io";

const BacklinksTableItemSingleApprovalReject = ({ connectionId }) =>{
    // Store
    const dispatch = useDispatch()
    const currentUser = useSelector(({ currentUser }) => currentUser)

    // Handle connection rejection
    const handleRejection = () =>{
        dispatch(handleDeleteConnection(connectionId, config(currentUser.token)))
    }

    return(
        <Tooltip text="Reject connection. This connection will be deleted" direction="left">
            <button id="backlinks-table-items-single-reject-btn" onClick={handleRejection}><IoMdClose /></button>
        </Tooltip>
    )
}   

export { BacklinksTableItemSingleApprovalReject }