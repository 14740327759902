import api from "./api";
const baseUrl = `${process.env.REACT_APP_API_BASE_URL}suggestions`

const getAll = () => api.get(baseUrl)

const getByConnection = (id, config) => api.get(`${baseUrl}/connection/${id}`, config)

const postSuggestion = (data, config) => api.post(baseUrl, data, config)

const suggestionService = { 
    getAll, 
    getByConnection,
    postSuggestion 
}

export default suggestionService