import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import passwordResetService from '../../services/passwordResetService'

import ErrorMessage from '../Popups/ErrorMessage'

import { RiLockPasswordLine } from 'react-icons/ri'
import { IoIosCloseCircleOutline } from "react-icons/io";
import { FaRegCheckCircle } from "react-icons/fa";

const ForgotPasswordResetForm = ({ user }) =>{   
    // Navigate     
    const navigate = useNavigate()

    // State for input
    const [password, setPassword] = useState("")
    const [passwordCheck, setPasswordCheck] = useState("")
    // const [showPass, setShowPass] = useState(false)
    const [passwordValidityError, setPasswordValidityError] = useState(null)
    const [showError, setShowError] = useState(false)


    // Check if password matches
    const checkPasswordMatch = () =>{
        if(password !== passwordCheck){
            setShowError(true)
        } else{
            setShowError(false)
        }
    }

    // Check password strength and validity
    const checkValidity = () =>{
        // RegEx for password complexity
        const complexityRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{10,}$/

        // Check password length
        if(password.length < 10){
            setPasswordValidityError('Password should have at least 10 characters')
            return false
        } 
        
        // Check if password matches RegEx
        if(!complexityRegex.test(password)){
            setPasswordValidityError('Password should contain at least one uppercase letter, one lowercase letter and one number')
            return false
        }

        // Reset error if password is OK
        setPasswordValidityError(null)
        return true
    }

    // Handle submit
    const handleSubmit = async (e) =>{
        e.preventDefault()
        
        const data = { id: user.id, password }

        try {
            // Send request
            await passwordResetService.updatePassword(data, user.id)
            
            // Reset fields
            setPassword("")
            setPasswordCheck("")

            // Navigate to login page
            navigate("/login", {replace: true})

        } catch (error) {
            console.log(error)
        }
    }

    return(
        <form id="forgot-password-reset-form" onSubmit={handleSubmit}>
            <div className="form-input-text">
                <RiLockPasswordLine />

                <input 
                    type="password" 
                    placeholder="Enter password" 
                    value={password} 
                    onKeyUp={checkValidity}
                    onChange={({target})=>{setPassword(target.value)}}
                    required 
                />

                {passwordValidityError? <IoIosCloseCircleOutline style={{ color: 'red' }} />: <FaRegCheckCircle style={{ color: 'green' }} />}
            </div>
            {passwordValidityError? <p className='register-email-error'>{passwordValidityError}</p>: ""}
            
            <div className="form-input-text">
                <RiLockPasswordLine />

                <input 
                    type="password" 
                    placeholder="Enter password" 
                    value={passwordCheck} 
                    onKeyUp={checkPasswordMatch}
                    onChange={({target})=>{setPasswordCheck(target.value)}}
                    required 
                />

            {password !== passwordCheck? <IoIosCloseCircleOutline style={{ color: 'red' }} />: <FaRegCheckCircle style={{ color: 'green' }} />}
            </div>

            {showError? <ErrorMessage message="Password does not match" /> : ""}

            <button type="submit" className='change-password-btn'>Change password</button>
        </form>
    )
}

export default ForgotPasswordResetForm