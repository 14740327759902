import { useState, useEffect } from "react"
import { useSelector } from "react-redux"

function useFilterPendingConnections (role){
    // Store
    const websiteConnections = useSelector(({ connections }) => connections.websiteConnections)

    // State
    const [notApprovedConnectionsCount, setNotApprovedConnectionsCount] = useState(null)

    // Find how many connections are not approved (pending state)
    useEffect(()=>{
        // Filter connections that are "pending". role="source" means this will only be shown on backlinks and not outlinks
        let array = [...websiteConnections.filter( connection => connection.approved === "pending" && role === 'source' )]

    setNotApprovedConnectionsCount(array.length)
    }, [websiteConnections])

    return [notApprovedConnectionsCount]
}

export default useFilterPendingConnections