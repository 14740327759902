import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { handleNewMessage } from "../../../store/reducers/messageReducer"
import { config } from "../../../apiConfig"

// Icons
import {AiOutlineSend} from 'react-icons/ai'
import { ErrorMessageChat } from "../../../common/ErrorMessageChat"

const BacklinksMessagesSingleForm = ({ conversation, participant }) =>{
    // Store
    const dispatch = useDispatch()
    const currentUser = useSelector(({ currentUser }) => currentUser)
    const error = useSelector(({ messages }) => messages.postMessageError)

    // Local state
    const [text, setText] = useState("")

    // Handle form submission
    const handleSubmit = async (e) =>{
        e.preventDefault()

        dispatch(handleNewMessage({
            sender: currentUser.userForToken.id,
            reciever: participant.id,
            conversation: conversation.id,
            text
        }, 
        config(currentUser.token))
    )
        
        // Reset field
        setText("")
    }

    // Handle errors
    if(error) return <ErrorMessageChat error={error} />

    return(
        <form id="conversation-messages-form" onSubmit={handleSubmit}>
            <input type="text" placeholder='Write a reply...' value={text} onChange={({target})=>{setText(target.value)}} />
            <button className='profile-conversation-send-btn' type='submit'><AiOutlineSend /></button>
        </form>
    )
}

export { BacklinksMessagesSingleForm }