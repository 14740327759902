import axios from "axios";
const baseUrl = `${process.env.REACT_APP_API_BASE_URL}register`


const createUser = (user) => axios.post(baseUrl, user)

const getAll = () => axios.get(baseUrl)

const updateUser = (id, data) => axios.put(`${baseUrl}/${id}`, data)

const updateUserAnyData = (data, config) => axios.post(`${baseUrl}/update-user`, data, config)

const updateTemporaryToken = (data) => axios.post(`${baseUrl}/update-temporary-token`, data)

const deleteFailedUser = (email) => axios.delete(`${baseUrl}/failed/${email}`)

const deleteUser = (id, config) => axios.delete(`${baseUrl}/${id}`, config)

const resetPassword = (id, password) => axios.put(`${baseUrl}/${id}`, password)

const activateUser = (id, data, config) => axios.put(`${baseUrl}/activate/${id}`, data, config)

const registerService = { 
    getAll, 
    createUser, 
    updateUser, 
    updateUserAnyData, 
    updateTemporaryToken,
    deleteFailedUser,
    deleteUser, 
    resetPassword, 
    activateUser 
}

export default registerService