import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { FaCheckCircle } from "react-icons/fa";

export default function BacklinkOptionsFormInputCheck ({ inputValue, identifier }){
    // Store
    const activeSuggestion = useSelector(({ suggestions }) => suggestions.activeSuggestion)

    // State
    const [isMatched, setIsMatched] = useState(false)

    // Check if the input matches the equivalent suggestion field
    useEffect(()=>{
        if(activeSuggestion && (inputValue === activeSuggestion[identifier])){
            setIsMatched(true)
        } else{
            setIsMatched(false)
        }
    }, [inputValue, activeSuggestion])

    // If there is no suggestion available, do not render
    if(!activeSuggestion) return null

    return(
        <FaCheckCircle 
            className={`${isMatched? "backlink-options-form-check-icon-green": "backlink-options-form-check-icon"}`}     
        />
    )
}