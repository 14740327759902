import { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"

import registerService from "../../../services/registerService"
import websiteService from "../../../services/websiteService"
import { setUsers } from "../../../store/reducers/userReducer"

// Assets
import { CgProfile } from 'react-icons/cg'
import { TbWorld } from 'react-icons/tb'

// Components
import FormInputText from '../../Forms/FormInputText'
import ErrorMessage from "../../Popups/ErrorMessage"
import WaitlistCompleteFormEmail from "./WaitlistCompleteFormEmail"
import FormInputPassword from "../../Forms/FormInputPassword"

export default function WaitlistCompleteForm ({ email }){
    // Store
    const dispatch = useDispatch()
    const niches = useSelector(({ niches }) => niches.niches)

    // Navigate
    const navigate = useNavigate()

    // Local state
    const [name, setName] = useState("")
    const [surname, setSurname] = useState("")
    const [newEmail, setNewEmail] = useState(email)
    const [url, setUrl] = useState("")
    const [pass, setPass] = useState("")
    const [isProcessing, setIsProcessing] = useState(false)
    const [showEmailError, setShowEmailError] = useState(null)
    const [passwordValidityError, setPasswordValidityError] = useState(null)
    const [error, setError] = useState(null)

    const handleSubmit = async (e) =>{
        e.preventDefault()

        // Create user object with the retrieved input
        const newUser = { 
            name, 
            surname, 
            email: newEmail, 
            password: pass, 
            filteredBacklinkCategories: 
            niches, filteredOutlinkCategories: niches 
        }
        
        try {
            setIsProcessing(true)

            // Create a new user
            const response = await registerService.createUser(newUser)

            // Create first website for user
            await websiteService.postFirstWebsite({ owner: response.data.id, url })

            // Add new user to users state
            dispatch(setUsers(response.data))

            // Navigate to Verification Page passing the temporary token generated when user was created
            navigate(`/register/email-verification/${response.data.temporaryToken}`)

            // Reset fields
            setIsProcessing(false)
            setUrl("")
            setPass("")
        } catch (error) {            
            if(error.response && error.response.status === 403){
                setError("Website cannot be added as it already exists.");
                setUrl("");

                // Rollback user creation
                await registerService.deleteFailedUser(newEmail)

                setIsProcessing(false) // Stop processing here
            return; // Exit the function
            } else{
                console.log(error)
                setError(error.message)
                setIsProcessing(false)
            }
        }
    }

    return(
        <form id="waitlist-register-form" onSubmit={handleSubmit}>
            {/* Name and surname input */}
            <div className='finish-registration-name'>
                <FormInputText 
                    icon={<CgProfile className='finish-form-icon' />} 
                    placeholder="Name"
                    inputValue={name}
                    setInputValue={setName}
                />

                <FormInputText 
                    placeholder="Name"
                    inputValue={surname}
                    setInputValue={setSurname}
                />      
            </div>

            {/* Email input and email error message */}
            <WaitlistCompleteFormEmail email={newEmail} setEmail={setNewEmail} setShowEmailError={setShowEmailError} />
            {showEmailError? <ErrorMessage message="This email is already being used" /> : ""}
            
            {/* Website input */}
            <FormInputText 
                icon={<TbWorld className='finish-form-icon' />}
                placeholder='Website (URL)'
                inputValue={url}
                setInputValue={setUrl}
            />

            {/* Password input and password error message */}
            <FormInputPassword 
                inputValue={pass} 
                setInputValue={setPass} 
                passwordValidityError={passwordValidityError}
                setPasswordValidityError={setPasswordValidityError} 
            />
            {passwordValidityError? <p className='register-email-error'>{passwordValidityError}</p>: ""}

            {/* Submit button */}
            <button 
                type="submit" 
                className="register-btn" 
                disabled={isProcessing || showEmailError || passwordValidityError}
            >
                <span>
                    {isProcessing? "Saving...": "Create account"}
                </span>
            </button>

            {/* Error messages */}
            <p className='register-email-error'>{error}</p>
        </form>
    )
}