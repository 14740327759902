import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import useCopyToClipboard from '../../../hooks/useCopyToClipboard'

import { Tooltip } from '../../../common/Tooptip/Tooltip'

import { FiCopy } from 'react-icons/fi'

export default function BacklinkOptionsFormInputCopy ({ identifier, setInputValue }){
    // Retrieve suggestion
    const activeSuggestion = useSelector(({ suggestions }) => suggestions.activeSuggestion)

    // Local state
    const [suggestionExists, setSuggestionExists] = useState(false)

    // Hook for copying to clipboard
    const [copiedText, copy] = useCopyToClipboard()

    // Handle button click
    const handleCopyButton = (e) =>{
        e.preventDefault()

        // Copy value to clipboard
        copy(activeSuggestion[identifier])

        setInputValue(activeSuggestion[identifier])
    }

    useEffect(()=>{
        const exists = activeSuggestion? true: false

        setSuggestionExists(exists)
    }, [activeSuggestion])

    return(
        <Tooltip text={suggestionExists? "Copy suggestion": "No suggestion available"} direction="top">
            <button disabled={!suggestionExists} onClick={handleCopyButton}><FiCopy /></button>
        </Tooltip>
    )
}