import { BacklinksTableItemSingleApprovalAccept } from "./BacklinksTableItemSingleApprovalAccept";
import BacklinksTableItemSingleApprovalHeader from "./BacklinksTableItemSingleApprovalHeader";
import { BacklinksTableItemSingleApprovalReject } from "./BacklinksTableItemSingleApprovalReject";

export default function BacklinksTableItemSingleApproval ({ sourceWebsite, connectionId }){
    return(
        <div className="backlinks-table-items-single-approval">
            <BacklinksTableItemSingleApprovalHeader sourceWebsite={sourceWebsite} />
            <div className="backlinks-table-items-single-approval-btns">
                <BacklinksTableItemSingleApprovalAccept connectionId={connectionId} />
                <BacklinksTableItemSingleApprovalReject connectionId={connectionId} />
            </div>
        </div>
    )
}