import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import './existing-users.css'

import { initializeNiches } from '../../../store/reducers/nicheReducer'

// icons
import {BsSortAlphaDown} from 'react-icons/bs'

// Components
import DatabaseSingle from './DatabaseSingle'
import DatabaseToolbar from './DatabaseToolbar'

/**
 * Websites wrapper page in admin panel
 */
const Database = () =>{
    // Get data from store
    const dispatch = useDispatch()
    const websites = useSelector(({websites}) => websites.allWebsites )
    const loading = useSelector(({ niches }) => niches.loading)
    const error = useSelector(({ niches }) => niches.error)

    // State
    const [filteredWebsites, setFilteredWebsites] = useState(websites)
    const [showNiche, setShowNiche] = useState(true)
    const [showLang, setShowLang] = useState(true)
    const [activeSort, setActiveSort] = useState("")

    // Get filtered links
    useEffect(()=>{ 
        dispatch(initializeNiches()) 
    }, [dispatch])

    // Show filtered websites depending on option clicked
    useEffect(()=>{
        if(showNiche && showLang){
            setFilteredWebsites(websites)
        } else if(showNiche && !showLang){
            setFilteredWebsites( websites.filter( website => !website.languageOfWebsite))
        } else if(!showNiche && showLang){
            setFilteredWebsites( websites.filter( website => !website.niche))
        } else {
            setFilteredWebsites( websites.filter( website => !website.niche && !website.languageOfWebsite))
        }
        
    },[websites, showNiche, showLang])

    // Sort websites alphabetically depending on property
    useEffect(() => {
        if (activeSort === "website") {
          setFilteredWebsites([...filteredWebsites].sort((a, b) => (a.url > b.url) ? 1 : ((b.url > a.url) ? -1 : 0)));
        } else if (activeSort === "user") {
          setFilteredWebsites([...filteredWebsites].sort((a, b) => (a.owner.name > b.owner.name) ? 1 : ((b.owner.name > a.owner.name) ? -1 : 0)));
        } else if (activeSort === "email") {
          setFilteredWebsites([...filteredWebsites].sort((a, b) => (a.owner.email > b.owner.email) ? 1 : ((b.owner.email > a.owner.email) ? -1 : 0)));
        } else if (activeSort === "dr") {
          setFilteredWebsites([...filteredWebsites].sort((a, b) => (a.DR > b.DR) ? 1 : ((b.DR > a.DR) ? -1 : 0)));
        } else if (activeSort === "niche") {
          setFilteredWebsites([...filteredWebsites].sort((a, b) => (a.niche > b.niche) ? 1 : ((b.niche > a.niche) ? -1 : 0)));
        } else if (activeSort === "language") {
          setFilteredWebsites([...filteredWebsites].sort((a, b) => (a.languageOfWebsite > b.languageOfWebsite) ? 1 : ((b.languageOfWebsite > a.languageOfWebsite) ? -1 : 0)));
        }
    }, [websites, activeSort]);

    // Handle errors and loading
    if(error) return <p>{error}</p>
    if(loading) return null

    return(
        <div className="existing-users">
            <h1 className="admin-h1">All websites <span>({websites.length})</span></h1>
            <p className='admin-sub'>A list of all the websites registered in the database.</p>

            <div className="new-users-table">
                <DatabaseToolbar 
                    showNiche={showNiche} 
                    setShowNiche={setShowNiche} 
                    showLang={showLang} 
                    setShowLang={setShowLang} 
                    activeSort={activeSort}
                    setActiveSort={setActiveSort}
                />

                <div className='new-users-titles existing-users-titles'>
                    <div onClick={()=>{setActiveSort("website")}}><p>Website</p> {activeSort === "website" && <BsSortAlphaDown style={{color: 'green'}} />}</div>
                    <div onClick={()=>{setActiveSort("user")}}><p>User</p> {activeSort === "user" && <BsSortAlphaDown style={{color: 'green'}} />}</div>
                    <div onClick={()=>{setActiveSort("email")}}><p>Email</p> {activeSort === "email" && <BsSortAlphaDown style={{color: 'green'}} />}</div>
                    <div onClick={()=>{setActiveSort("dr")}}><p>DR</p> {activeSort === "dr" && <BsSortAlphaDown style={{color: 'green'}} />}</div>
                    <div onClick={()=>{setActiveSort("niche")}}><p>Niche</p> {activeSort === "niche" && <BsSortAlphaDown style={{color: 'green'}} />}</div>
                    <div onClick={()=>{setActiveSort("language")}}><p>Language</p> {activeSort === "language" && <BsSortAlphaDown style={{color: 'green'}} />}</div>
                    <div><p>Actions</p></div>
                </div>

                <div className="new-users-single-wrapper">
                    {filteredWebsites.map( website => 
                        <DatabaseSingle
                            key={website.id} 
                            website={website}
                        /> 
                    )}
                </div>

                {websites.length === 0? <div className='new-users-empty'><p>All websites have been given a DR</p></div>: ""}            
            </div>
        </div>
    )
}

export default Database