import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import './backlink-options-form.css'

import { getSuggestionByConnection } from '../../../store/reducers/suggestionsReducer';
import { handleUpdateConnection } from '../../../store/reducers/connectionReducer';
import { setSuccess } from '../../../store/reducers/successReducer';

import { config } from '../../../apiConfig';
import { getCurrentDate } from '../helpers'

import Options from '../../Forms/Options'
import BacklinkOptionsFormInputHOC from './BacklinkOptionsFormInputHOC';
import FormInputText from '../../Forms/FormInputText';
import FormInputFile from '../../Forms/FormInputFile'

export default function BacklinkOptionsForm (){
    // Store
    const dispatch = useDispatch()
    const currentUser = useSelector(({ currentUser }) => currentUser)
    const activeConnection = useSelector(({ backlinks }) => backlinks.activeConnection)
    const loading = useSelector(({ suggestions }) => suggestions.activeSuggestionLoading)

    // Local state
    const [targetUrl, setTargetUrl] = useState("")
    const [backlinkType, setBacklinkType] = useState("")
    const [referringPage, setReferringPage] = useState("")
    const [anchorText, setAnchorText] = useState("")
    const [selectedFile, setSelectedFile] = useState(null)

    // Send request to GET suggestion that matches activeConnection
    useEffect(()=>{
        if(activeConnection){
            dispatch(getSuggestionByConnection(activeConnection.id, config(currentUser.token)))
        }
    }, [dispatch, activeConnection])

    // Handle form submission
    const handleSubmit = async (e) =>{
        e.preventDefault()

        const formData = new FormData()
        formData.append('attachedFile', selectedFile)
        formData.append('sourceUrl', referringPage)
        formData.append('targetAnchorUrl', targetUrl)
        formData.append('targetAnchorText', anchorText)
        formData.append('backlinkType', backlinkType)
        formData.append('approved', 'finalised')
        formData.append('completedAt', getCurrentDate())

        // Dispatch action to send request
        dispatch(handleUpdateConnection(activeConnection.id, formData, config(currentUser.token)))

        // Show success message
        dispatch(setSuccess("Connection updated successfully"))
    }

    // If component is loading suggestions, show message
    if(loading) return <p>Loading</p>

    return(
        <form id='backlink-options-form' encType='multipart/form-data' onSubmit={handleSubmit}>
            <div className="backlink-options-form-div">
                <BacklinkOptionsFormInputHOC inputValue={targetUrl} setInputValue={setTargetUrl} identifier="targetUrl" label="Target URL">
                    <FormInputText placeholder="www.website.com" inputValue={targetUrl} setInputValue={setTargetUrl} />
                </BacklinkOptionsFormInputHOC>
           
                <BacklinkOptionsFormInputHOC inputValue={referringPage} setInputValue={setReferringPage} identifier="referringPage" label="Referring page">
                    <FormInputText placeholder="www.website.com" inputValue={referringPage} setInputValue={setReferringPage} />
                </BacklinkOptionsFormInputHOC>
            </div>
            
            <BacklinkOptionsFormInputHOC inputValue={anchorText} setInputValue={setAnchorText} identifier="anchorText" label="Anchor text">
                <FormInputText placeholder="Your anchor text" inputValue={anchorText} setInputValue={setAnchorText} />
            </BacklinkOptionsFormInputHOC>

            <BacklinkOptionsFormInputHOC inputValue={backlinkType} setInputValue={setBacklinkType} identifier="backlinkType" label="Backlink type">
                <Options title="Guest post" list={optionsList} inputValue={backlinkType} setInputValue={setBacklinkType} />
            </BacklinkOptionsFormInputHOC>

            {backlinkType === 'Guest'?  <FormInputFile inputValue={selectedFile} setInputValue={setSelectedFile} />: ""}
            
            <div className='backlink-options-form-btn'>
                <button type='submit' className="primary-btn" style={{ width: 'max-content' }}>Save</button>
            </div>
        </form>
    )
}

// Options for backlink type select
const optionsList = [
    {
        id: 1,
        title: "Guest"
    },
    {
        id: 2,
        title: "Embedded"
    },
    {
        id: 3,
        title: "Dofollow"
    },
    {
        id: 4,
        title: "Nofollow"
    },
]