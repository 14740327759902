const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
const monthsPrefix = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]

export function getFirstName (text) {
    return text[0].toUpperCase() + text.substring(1)
}

export function getFirstLetter (text) {
    return text[0].toUpperCase()
}

// Convert unix to date
export function getDateFromUnix (date){
    let d = new Date(date * 1000)

    return `${d.getDate()} ${months[d.getMonth()]} ${d.getFullYear()}`
}

// Convert timestamp into date
export function getDate (date) {
    return date.substring(8,10) + " " + months[Number(date.substring(5,7)) - 1] + " " + date.substring(0,4)
}

// Convert ISO to date
export function getDateFromISO (date) {
    return  months[date.getMonth()] + " " + date.getDate() + ", " + date.getFullYear()
}

// Convert website date
export function getWebsiteDate (date) {
    const day = Number(date.substring(0,2))
    let ending;
    if(day === 1){
        ending = "st"
    } else if (day === 2){
        ending = "nd"
    } else if (day === 3){
        ending = "rd"
    } else {
        ending = "th"
    }

    return day + ending + " " + months[Number(date.substring(3,5) - 1)] + " " + date.substring(6,11)
}

export function getWebsiteName (text) {
    if(text.substring(1,3) === 'wwww'){
        return text.substring(4, text.length)
    } 
    return text   
}

// Convert timestamp to time difference 
export function getNotificationDate(date) {
    const dateNow = new Date()
    const timestamp = new Date(date)
    const difference = (dateNow.getTime() - timestamp.getTime()) / 1000

    if(difference < 60){
        return `${Math.floor(difference)} seconds ago`
    } 
    else if(difference > 60 && difference < 60*60){
        return `${Math.floor(difference / 1000)} minutes ago`
    }
    else if(difference > 60*60 && difference <60*60*24){
        return `${Math.floor(difference / ( 60 * 60))} hours ago`
    }
    else if(difference > 60*60*24){
        return date.substring(8,10) + " " + months[Number(date.substring(5,7)) - 1] + " " + date.substring(0,4)
    }
}

// Convert timestamp into time
export function getTime (timestamp)  {
    // Check the timestamps in hours
    const dateNow = new Date()
    const time = new Date(timestamp)
    const difference = (dateNow.getTime() - time.getTime()) / 1000 / 60 / 60

    // If its more than 24 hours return the date
    if(difference > 24){
        return timestamp.substring(8,10) + " " + months[Number(timestamp.substring(5,7)) - 1]
    } else{
        return timestamp.substring(11,13) + ":" + timestamp.substring(14,16)
    }
}

export function getDateFromTimestamp (timestamp){
    return timestamp.substring(8,10) + " " + monthsPrefix[Number(timestamp.substring(5,7)) - 1] + " " + timestamp.substring(0,4)
}

// Get min score so it's not smaller that 0
export function getMinScore (score) {
    let min = Number(score) - 20

    if(min < 0){
        min = 0
    } 

    return min
}

// Get max score so it's not larger than 100
export function getMaxScore (score) {
    let max = Number(score) + 20

    if(max > 100){
        max = 100
    }

    return max
}

// Generate uncofrimed link message
export function generateUnconfirmedLinksMessage (count){
    if(count === 0){
        return "All links have been confirmed. This greatly improves you trust rate"
    } else if(count === 1){
        return "1 link is unconfirmed. This might affect your trust rate"
    } else{
        return `${count} links are unconfirmed. This might affect your trust rate`
    }
}

export function generateRandomColor (){
    const colors = [
        "#5400ff",
        "#7633FF",
        "#35009e"
    ]

    return colors[Math.floor(Math.random() * colors.length)]
}