import { useState } from "react"

import ProfileWebsitesAddForm from "../../Profile/ProfilePages/ProfileInfo/Pages/Websites/ProfileWebsitesAddForm"

const SettingsSidebarAddWebsite = ({ setShowAddMessage }) =>{   
    // State
    const [formIsOpen, setFormIsOpen] = useState(false)

    return(
        <>
            <button className="settings-sidebar-add-btn" onClick={()=>{ setFormIsOpen(true) }}>Add website</button>
            {formIsOpen && <ProfileWebsitesAddForm setFormIsOpen={setFormIsOpen} setSuccess={setShowAddMessage} />}
        </>
    )
}

export { SettingsSidebarAddWebsite }