import './support.css'

const Support = () =>
{
    return(
        <div className="support">
            Support
        </div>
    )
}   

export default Support