import { useState } from "react"

// Icons
import {MdOutlineExpandMore} from 'react-icons/md'
import {BiMessageAltAdd} from 'react-icons/bi'

// Components
import DatabaseSingleActions from "./DatabaseSingleActions"
import DatabaseAddNiche from "./DatabaseAddNiche"
import DatabaseAddLanguage from "./DatabaseAddLanguage"
import NewWebsitesComments from '../NewWebsites/NewWebsitesComments'

/**
 * Single website component to display in list
 */
const DatabaseSingle = ({ website }) =>{
    // State
    const [showDetails, setShowDetails] = useState(false)
    const [showAddNiche, setShowAddNiche] = useState(false)
    const [showAddLanguage, setShowAddLanguage] = useState(false)
    const [showCommentForm, setShowCommentForm] = useState(false)
    const [websiteSelected, setWebsiteSelected] = useState(false)

    if(!website.owner) return null

    return(
        <div className="new-users-single" onClick={()=>{setShowDetails(!showDetails); setWebsiteSelected(!websiteSelected)}}>
            <div className="new-users-single-content existing-users-single-content" style={{background: `${websiteSelected? "#eef0f3": "white"}`}}>
                <div>
                    <a href={`http://${website.url}`} target="_blank">{website.url? website.url.substring(4, website.url.length): "-"}</a>
                </div>

                <div>
                    <p style={{textTransform: 'capitalize'}}>{website.owner.name + " " + website.owner.surname}</p>
                </div>

                <div>
                    <p>{website.owner.email}</p>
                </div>

                <div>
                    <small className={`${website.status !== "spam"? "existing-users-single-dr": "existing-users-single-dr-spam"}`}>
                        {website.status === "spam" && "Spam"}
                        {website.DR !== 0 && website.DR}
                        {website.DR === 0 && website.status !== "spam"? "N/A": ""}
                    </small>
                </div>

                <div>
                    <p>{website.niche? website.niche: ""}</p>
                    {true && 
                    <button className="existing-user-single-add-btn" onClick={()=>{setShowAddNiche(true)}}><BiMessageAltAdd />
                    </button>}
                </div>

                <div>
                    <p>{website.languageOfWebsite? website.languageOfWebsite: ""}</p>
                    {!website.languageOfWebsite && 
                    <button className="existing-user-single-add-btn" onClick={()=>{setShowAddLanguage(true)}}><BiMessageAltAdd />
                    </button>}
                </div>
                
                <div className='new-users-single-actions-btn'>
                    <button onClick={()=>{setShowDetails(!showDetails); setWebsiteSelected(!websiteSelected)}}>
                        <MdOutlineExpandMore style={{ transform: `${showDetails? "rotate(180deg)": "rotate(0deg)"}` }} />
                    </button>
                </div>
            </div>

            {showDetails? <DatabaseSingleActions website={website} setShowCommentForm={setShowCommentForm} />: ""}
            {showAddNiche && <DatabaseAddNiche setShowAddNiche={setShowAddNiche} id={website.id} />}
            {showAddLanguage && <DatabaseAddLanguage setShowAddLanguage={setShowAddLanguage} id={website.id} />}
            {showCommentForm && <NewWebsitesComments setShowCommentForm={setShowCommentForm} id={website.id} value={website.comment || ""} />}
        </div>
        )
}

export default DatabaseSingle