import { useDispatch, useSelector } from "react-redux";

import { handleApproveConnection } from "../../../../../../store/reducers/connectionReducer"
import { config } from '../../../../../../apiConfig'

import { Tooltip } from "../../../../../../common/Tooptip/Tooltip"

import { FaCheck } from "react-icons/fa";

const BacklinksTableItemSingleApprovalAccept = ({ connectionId }) =>{
    // Store
    const dispatch = useDispatch()
    const currentUser = useSelector(({ currentUser }) => currentUser)

    // Handle button click for approving a connection
    const handleApproval = () =>{
        dispatch(handleApproveConnection(connectionId, { status: 'approved' }, config(currentUser.token)))
    }

    return(
        <Tooltip text="Accept connection to procceed." direction="top">
            <button id="backlinks-table-items-single-accept-btn" onClick={handleApproval}><FaCheck /></button>
        </Tooltip>
    )
}

export { BacklinksTableItemSingleApprovalAccept }