import {AiOutlineCheckCircle } from 'react-icons/ai'

// Confirmation message popup
const ProfileWebsitesConfirmation = ({title, text}) =>{
    return(
        <div className="profile-websites-confirmation">
            <AiOutlineCheckCircle style={{ color: 'green', fontSize: '2rem'}} />
            
            <div>
                <p>{title}</p>
                <small>{text}</small>
            </div>
        </div>
    )
}

export default ProfileWebsitesConfirmation