import { Tooltip } from "../../../../common/Tooptip/Tooltip"

export default function BacklinksTableItemsHeader(){
    return(
        <div className="backlinks-table-items-titles">
            {backlinksTableHeaderData.map( item =>
                <Tooltip key={item.identifier} text={item.tooltip} direction={item.tooltipDirection}>
                    <div>{item.title}</div>
                </Tooltip>
            )}
        </div>
    )
}

const backlinksTableHeaderData = [
    {
        identifier: 1,
        title: "#",
        tooltip: "",
        tooltipDirection: "top"
    },
    {
        identifier: 2,
        title: "Website and referring page",
        tooltip: "Displays the website where the backlink will be placed",
        tooltipDirection: "top"
    },
    {
        identifier: 3,
        title: "Anchor and target url",
        tooltip: "Displays the website link that will be hosted",
        tooltipDirection: "top"
    },
    {
        identifier: 4,
        title: "Backlink type",
        tooltip: "Type of agreed backlink",
        tooltipDirection: "top"
    },
    {
        identifier: 5,
        title: "Created at",
        tooltip: "Progress of backlink connection and date once it is finilised",
        tooltipDirection: "top"
    },
    {
        identifier: 6,
        title: "Chat",
        tooltip: "Open chat to decide backlink details",
        tooltipDirection: "left"
    },
    {
        identifier: 7,
        title: "",
        tooltip: "",
        tooltipDirection: "left"
    },
]