import './partners.css'

// Assets
import avatar from '../../../resources/samples/avatar.png'
import avatar2 from '../../../resources/samples/avatar2.png'
import avatar3 from '../../../resources/avatar3.png'

const Partners = () =>{
    return(
        <div className="partners">
            <div className="partners-top">
                <p>Join over 10,000 users worldwide that are using Linkeby</p>
                <div className="partners-top-reviews">
                    <div>
                        <div>
                            <img src={avatar} alt="avatar" />
                        </div>
                        <div>
                            <img src={avatar2} alt="avatar2" />
                        </div>
                        <div>
                            <img src={avatar3} alt="avatar3" />
                        </div>
                    </div>
                    <small>Nick and others have already joined</small>
                </div>
            </div>
            
            <div className="partners-bottom">
                <h4>Get started today</h4>
                <p>Join us and get a free 1-month trial with all our features</p>
                <div className='partners-bottom-join'>
                    <input type="text" placeholder='Email' />
                    <button>Start for free</button>
                </div>
            </div>
        </div>
    )
}

export default Partners