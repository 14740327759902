import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

// Filter website connections (connection for a single website) 
// depending on selected filter chosen and sort by createdAt date
function useSortFilteredConnections (connectionsFilter, role){
    // Store
    const searchKey = useSelector(({ backlinks }) => backlinks.searchKey)
    const websiteConnections = useSelector(({ connections }) => connections.websiteConnections)

    // State
    const [filteredConnections, setFilteredConnections] = useState(null)
    const [connectionsToRender, setConnectionsToRender] = useState([])

    // Filter and sort connections array to render
    useEffect(()=>{
        let array = []

        // Filter array depending on option selected
        if(connectionsFilter === "All"){
            array = [...websiteConnections.filter( connection => { 
                // If we are filtering backlink connections, we don't want to show pending connections since they need to be approved first
                if(role === "source"){
                    return connection.approved !== "pending"
                }

                // If we are showing outlink connections we show all the available connections
                return connection
            })]
        } else if(connectionsFilter === "New"){
            // Create a timestamp for 3 days ago
            const cutOffTimestamp = Date.now() - 7 * 24 * 60 * 60 * 1000

            // Filter array based on timestamp, while also filtering pending connections for "source" user
            array = [...websiteConnections.filter( connection => { 
                if(new Date(connection.createdAt).getTime() >  cutOffTimestamp){
                    if(role === "source"){
                        return connection.approved !== "pending"
                    }

                    return connection
                }
            })]
        } else if(connectionsFilter === "Completed"){
            array = [...websiteConnections.filter( connection => connection.approved === "finalised")]
        } else if (connectionsFilter === "Approved"){
            array = [...websiteConnections.filter( connection => connection.approved === "approved")]
        }

        // Sort the filtered array by newest timestamp
        const sortedArray = array.sort((a, b) => {
            if (b.createdAt && a.createdAt) {
                return new Date(b.createdAt) - new Date(a.createdAt)
            } if (!b.createdAt && a.createdAt) {
                return -1
            } if (b.createdAt && !a.createdAt) {
                return 1
            }
            return 0;
        })

        // Set state as the sorted array
        setFilteredConnections(sortedArray)
    }, [connectionsFilter, websiteConnections, role])

    // Filter selected & sorted connections depending on search key
    useEffect(()=>{
        if(filteredConnections){
            const searchFilteredConnections = filteredConnections.filter( connection => {
                const target = connection.targetWebsite?.url.toLowerCase()
                const source = connection.sourceWebsite?.url.toLowerCase()
 
                return source.includes(searchKey)
                || target.includes(searchKey)
            })
 
            setConnectionsToRender(searchFilteredConnections)
        }
    }, [searchKey, filteredConnections])

    return [filteredConnections, connectionsToRender]
}

export default useSortFilteredConnections