export default function BacklinksTableItemSinglePendingButtonsClose ({ dontShow, setShowModal }){
    const handleClick = () =>{        
        // Only if user checks the box, save preference
        if(dontShow){
            // Get preferences from localstorage
            const preferences = JSON.parse(localStorage.getItem('userPreferences')) || {}

            // Save changes in storage
            preferences.showBacklinksPendingConnectionsMessage = true

            localStorage.setItem('userPreferences', JSON.stringify(preferences))
        }

        // Close modal
        setShowModal(false)
    }

    return(
        <button onClick={handleClick}>OK, got it</button>
    )
}

