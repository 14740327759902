const BacklinksTableNavOptionsSingle = ({ label, icon, connectionsFilter, setConnectionsFilter }) =>{
    return(
        <button 
            className={`${connectionsFilter===label?"backlinks-table-nav-options-single-active":"backlinks-table-nav-options-single"}`}
            onClick={()=>{ setConnectionsFilter(label) }}
        >
        {label}
        </button>
    )
}

export { BacklinksTableNavOptionsSingle }