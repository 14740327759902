import useWaitlistSubmission from '../../../hooks/waitlist/useWaitlistSubmission';
import '../waitlist.css'

// Icons
import { MdAlternateEmail } from "react-icons/md";

// Components
import FormInputEmail from "../../Forms/FormInputEmail";

export default function WaitlistForm (){
    // Hook for submitting waitlist form
    const { email, setEmail, emailIsValid, setEmailIsValid, success, error, handleSubmit } = useWaitlistSubmission()

    return(
        <form id='waitlist-form' onSubmit={handleSubmit}>
            <FormInputEmail
                icon={<MdAlternateEmail />} 
                placeholder="Enter your email" 
                inputValue={email} 
                setInputValue={setEmail} 
                setEmailIsValid={setEmailIsValid}
            />
           
            {/* Error and success messages */}
            {!emailIsValid? <small className="waitlist-form-error">Please enter a valid email address</small>: ""}
            {error? <small className="waitlist-form-error">{error}</small>: ""}
            {success? <small className="waitlist-form-success">Thank you!</small>: ""}

            <button className='primary-btn' type="submit">Join</button>

            <h6>We will notify all users in the waitlist as soon as the app is launched, via a personal email.</h6>
        </form>
    )
}