import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { initializeNiches } from '../../../../../../../../store/reducers/nicheReducer'
import '../profile-websites-actions.css'

import { ProfileWebsiteCheckboxSingle } from '../ProfileWebsiteCheckboxSingle'
import { ProfileWebsiteNichesSaveButton } from './ProfileWebsiteNichesSaveButton'
import { ProfileWebsiteNichesCloseButton } from './ProfileWebsiteNichesCloseButton'
import { ProfileWebsiteNichesWarning } from './ProfileWebsiteNichesWarning'

const ProfileWebsiteNiches = ({ website, setShowNiches }) =>{
    // Get filterd links from store
    const dispatch = useDispatch()
    const niches = useSelector(({ niches }) => niches.niches)
    const loading = useSelector(({ niches }) => niches.loading)
    const error = useSelector(({ niches }) => niches.error)

    // State
    const [selectedCategories, setSelectedCategories] = useState(website.niche)

    // Initialize all niches in store
    useEffect(()=>{
        dispatch(initializeNiches())
    }, [dispatch])

    // Handle errors and loading
    if(error) return <p>{error}</p>
    if(loading) return null

    return(
        <div className="profile-languages-wrapper">
            <div className="profile-website-languages">
                <h1>Select the niche of your website</h1>
                <p>You can choose as many niche as you want if they represent your website.</p>
                <div className="profile-website-languages-options">
                    {niches.map (niche => 
                        <ProfileWebsiteCheckboxSingle 
                            key={niche.id}
                            checkboxValue={niche.title}
                            selectedItems={selectedCategories}
                            setSelectedItems={setSelectedCategories}
                        />)
                    }
                </div>

                <ProfileWebsiteNichesSaveButton websiteId={website.id} selectedCategories={selectedCategories} setShowNiches={setShowNiches} />
                <ProfileWebsiteNichesCloseButton setShowNiches={setShowNiches} />
                <ProfileWebsiteNichesWarning length={selectedCategories.length} keyword="niches" />
            </div>
        </div>
    )
}

export default ProfileWebsiteNiches