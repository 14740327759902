import { useRef, useEffect } from "react"
import { useSelector } from "react-redux"

// Components
import { BacklinksMessagesSingleTextsMessage } from "./BacklinksMessagesSingleTextsMessage"

const BacklinksMessagesSingleTexts = () =>{
    // Refs
    const messagesWrapperRef = useRef()

    // Store
    const messages = useSelector(({ messages }) => messages.conversationMessages)

    // Scroll at the bottom of the messages
    useEffect(()=>{
        if(messagesWrapperRef.current){
            messagesWrapperRef.current.scrollTop = messagesWrapperRef.current.scrollHeight
        }
    }, [messagesWrapperRef.current? messagesWrapperRef.current.scrollHeight: ""])

    return(
        <div className="conversation-messages-single-text">
            <div className="conversation-messages-single-messages" ref={messagesWrapperRef}>
                {messages.map( message => 
                    <BacklinksMessagesSingleTextsMessage 
                        key={message.id} 
                        message={message} 
                        isLast={messages.length && messages[messages.length-1].id === message.id} 
                    />
                )}
            </div>
        </div>
    )
}

export { BacklinksMessagesSingleTexts }