// Icons
import {AiOutlineCheck} from 'react-icons/ai'
import {AiOutlineClose} from 'react-icons/ai'


/**
 * Toolbar component for existing websites
 */
const DatabaseToolbar = ({showNiche, setShowNiche, showLang, setShowLang,activeSort, setActiveSort}) =>
{
    return(
        <div className='existing-users-toolbar'>
            <div className="existing-users-toolbar-btns">
                <button onClick={()=>{setShowNiche(!showNiche)}}>
                    {showNiche? <AiOutlineCheck style={{color: 'green'}} />: <AiOutlineClose style={{color: 'red'}} />} 
                    {showNiche? "With niche": "Without niche"}
                </button>
                <button onClick={()=>{setShowLang(!showLang)}}>
                    {showLang? <AiOutlineCheck style={{color: 'green'}} />: <AiOutlineClose style={{color: 'red'}} />} 
                    {showLang? "With language": "Without language"}
                </button>
            </div>

            <div className='existing-users-toolbar-sort'>
                <p>Sort by</p>
                <select onChange={({target})=>{setActiveSort(target.value)}} required value={activeSort}>
                    <option value="default" disabled hidden>Category / niche of website</option>
                    <option value="website">Website</option>
                    <option value="user">User</option>
                    <option value="email">Email</option>
                    <option value="dr">DR</option>
                    <option value="niche">Niche</option>
                    <option value="language">Language</option>
                </select>
            </div>
        </div>
    )
}

export default DatabaseToolbar