import { useEffect, useState } from "react"
import { Helmet } from "react-helmet-async"
import './privacy.css'

import Nav from '../../components/Nav/Nav'
import Footer from "../../components/Footer/Footer"
import { PrivacySingle } from './PrivacySingle'

const Privacy = () => {
    // Local state
    const [privacy, setPrivacy] = useState([
        {
            title: 'Information We Collect',
            subtitle: 'We may collect the following types of information',
            list: [
                {
                    text: 'Personal Information: When you create an account or use our services, we may collect personal information such as your name, email address, and contact details.',
                    id: 1
                },
                {
                    text: 'User-Generated Content: Any content, including links and descriptions, that you submit to Linkeby.',
                    id: 2
                },
                {
                    text: 'Log Data: We may collect information about your usage of the web app, including IP addresses, browser type, pages visited, and timestamps.',
                    id: 3
                },
            ],
            id: 1
        },
        {
            title: 'How We Use Your Information',
            subtitle: 'We use your information for the following purposes',
            list: [
                {
                    text: 'Service Provision: To provide, maintain, and improve our web app and its features',
                    id: 1
                },
                {
                    text: 'User Communication: To communicate with you regarding your account, updates, and important information.',
                    id: 2
                },
                {
                    text: 'User-Generated Content: To display and share the content and links you submit on Linkeby.',
                    id: 3
                },
                {
                    text: 'Analytics: To analyze usage patterns, troubleshoot issues, and improve our services.',
                    id: 4
                }
            ],
            id: 2
        },
        {
            title: 'Sharing Your Information',
            subtitle: 'We may share your information in the following circumstances',
            list: [
                {
                    text: 'With Other Users: Your user-generated content, including links, may be shared with other users of Linkeby as part of the service.',
                    id: 1
                },
                {
                    text: 'Legal Obligations: We may disclose your information to comply with legal obligations or protect our rights, privacy, safety, or property.',
                    id: 2
                },
            ],
            id: 3
        },
        {
            title: 'Data Security',
            subtitle: 'We take reasonable measures to protect your personal information from unauthorized access, disclosure, or alteration. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.',
            list: [],
            id: 4
        },
        {
            title: 'Your Choices',
            subtitle: 'You have the following choices regarding your information',
            list: [
                {
                    text: 'Account Information: You can update, correct, or delete your account information by accessing your account settings.',
                    id: 1
                },
                {
                    text: 'Email Communications: You can opt out of receiving promotional emails from us.',
                    id: 2
                },
            ],
            id: 5
        },
        {
            title: "Children's Privacy",
            subtitle: 'Linkeby is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from children.',
            list: [],
            id: 6
        },
        {
            title: "Changes to this Privacy Policy",
            subtitle: 'We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. It is your responsibility to review this Privacy Policy periodically.',
            list: [],
            id: 7
        },
        {
            title: 'Contact Us',
            subtitle: 'If you have any questions or concerns about this Privacy Policy, please contact us at support@linkeby.com.',
            list: [],
            id: 8
        }
    ])

    // Scroll to top when component renders
    useEffect(()=>{
        window.scrollTo(0, 0)
    }, [])

    return(
        <div className="privacy">
            <Helmet>
                <title>Linkeby | Privacy Policy</title>
                <meta name="description" content="Simplifying the hardest part of SEO" />
            </Helmet>

            <Nav />

            <div className="privacy-wrapper">
                <div className="privacy-sidebar">
                    {privacy.map( item => <a href={`#${item.title}`} key={item.id}>{item.title}</a> )}
                </div>

                <div className="privacy-content">
                    <h1>Privacy Policy for Linkeby</h1>                
                    <p>Welcome to Linkeby,<br></br>
                    this Privacy Policy outlines how we collect, use, disclose, and protect your personal information when you use our web app. By accessing or using Linkeby, you consent to the practices described in this Privacy Policy.</p>
                    <small>Last updated: 24/3/2023</small>

                    {privacy.map( item => <PrivacySingle key={item.id} item={item} /> )}
                </div>
            </div>

            <Footer />
        </div>
    )
}

export { Privacy }