import { Link } from "react-router-dom"

import {AiOutlineCopyrightCircle} from 'react-icons/ai'

const DashboardFooter = () =>{
    return(
        <div className="dashboard-footer">
            <div className="dashboard-footer-copyright">
                <p>Copyright <AiOutlineCopyrightCircle /></p>
                <p>2022 Linkeby, All rights reserved</p>
            </div>

            <div className="dashboard-footer-legal">
                <Link className='dashboard-footer-legal-links'>Terms & Conditions</Link>
                <Link className='dashboard-footer-legal-links'>Privacy Policy</Link>
            </div>
        </div>
    )
}

export { DashboardFooter }