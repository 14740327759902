import './forms.css'

const SubmitButton = () =>
{
    return(
        <button className="submit-button" type="submit">
            Save
        </button>
    )
}

export default SubmitButton