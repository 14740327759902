import axios from "axios";
const baseUrl = `${process.env.REACT_APP_API_BASE_URL}conversations`

const getAll = () => axios.get(baseUrl)

const getUserConversations = (id) => axios.get(`${baseUrl}/${id}`)

const postConversation = (data, config) => axios.post(baseUrl, data, config)

const deleteConversation = (id, config) => axios.delete(`${baseUrl}/${id}`, config)

const postMessage = (id, data, config) => axios.post(`${baseUrl}/${id}/messages`, data, config)

const deleteMessage = (id, data, config) => axios.put(`${baseUrl}/${id}/messages`, data, config)

const setMessageOpened = (id, data, config) => axios.put(`${baseUrl}/${id}/messages/opened`, data, config)

const archiveConversation = (id, data, config) => axios.put(`${baseUrl}/status/${id}`, data, config)

const conversationsService = { getAll, getUserConversations, postConversation, deleteConversation, postMessage, deleteMessage, setMessageOpened, archiveConversation }

export default conversationsService