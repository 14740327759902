import { Helmet } from 'react-helmet-async'
import './waitlist.css'

import logo from '../../resources/logo-link.png'
import WaitlistForm from '../../components/Waitlist/WaitlistForm/WaitlistForm'

export default function Waitlist (){
    return(
        <div className="waitlist-page">
             <Helmet>
                <title>Linkeby | Join the waitlist</title>
                <meta name="description" content="Join the linkeby waitlist" />
            </Helmet>

            <img src={logo} alt="logo" />

            <h1>Get early access!</h1>
            <p>Be one of the first users to join <span>Linkeby</span></p>
            <WaitlistForm />
        </div>
    )
}