import { useMemo} from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { getDate, getWebsiteName } from '../../../../helpers/helpers'

// Components
import ProfileLandingChartsPie from '../ProfileLandingCharts/ProfileLandingChartsPie'

const ProfileLandingActivitySingle = ({ website, links }) =>{
    // Store
    const connections = useSelector(({ connections }) => connections.allConnections)

    // Router
    const navigate = useNavigate()

    // Get stats for website outlinks and backlinks
    const linkStats = useMemo(()=>{
        let backlinks = 0
        let outlinks = 0
        let connectionsCount = 0

        // Loop links to find count of outlinks and backlinks
        for (const link of links){
            if(link.targetWebsite && link.targetWebsite.id === website.id){
                backlinks++
            }

            if(link.sourceWebsite && link.sourceWebsite.id === website.id){
                outlinks++
            }
        }

        // Find how many connections for each website
        for(const conn of connections){
            if((conn.targetWebsite && conn.targetWebsite.id === website.id) || (conn.sourceWebsite && conn.sourceWebsite.id === website.id)){
                connectionsCount++
            }
        }

        // Return data
        return {
            backlinks,
            outlinks, 
            connectionsCount
        }
    }, [links])

    // Data for pie chart
    const pieData = [
        { name: 'Connections', value: linkStats.connectionsCount, fill: "#5400ff" },
        { name: 'Outlinks', value: linkStats.outlinks, fill: "#7633FF" },
        { name: 'Backlinks', value: linkStats.backlinks, fill: "#3f00bc" },
    ]

    return(
        <div className="profile-landing-activity-websites-single" onClick={()=>{ navigate(`/profile/website-overview/${website.url}`) }}>
            <div className='profile-landing-activity-websites-single-content'>
                <h1>{getWebsiteName(website.url)}</h1>

                <p>Registered on {getDate(website.createdAt)}</p>

                <div className="profile-landing-activity-websites-single-stats">
                    <small><span>{linkStats.connectionsCount}</span> connections</small>
                    <small><span>{linkStats.outlinks}</span> outlinks</small>
                    <small><span>{linkStats.backlinks}</span> backinks</small>
                </div>
            </div>

            <ProfileLandingChartsPie pieData={pieData} />
        </div>
    )
}

export default ProfileLandingActivitySingle