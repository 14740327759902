import { Link } from 'react-router-dom'

import { MdOutlineKeyboardArrowDown } from 'react-icons/md'

const NavLink = () =>{
    return(
        <div className='nav-menu-links-expand'>
            <Link to="/product" className='nav-menu-links'>Features <MdOutlineKeyboardArrowDown /></Link>

            <div className="nav-menu-links-expand-content">
                <div>
                    <p>SEO</p>
                    <small>Boost your visibility</small>
                </div>
                <div>
                    <p>Statistics</p>
                    <small>View and analyze growth</small>
                </div>
                <div>
                    <p>Instant Messsaging</p>
                    <small>Communicate with your connections</small>
                </div>
            </div>
        </div>
    )
}

export { NavLink }