import { useDispatch } from "react-redux"
import { handleDeleteWebsite } from "../../../../../../../store/reducers/websiteReducer"
import { config } from "../../../../../../../apiConfig"

const ProfileWebsiteActionsDelete = ({currentUser, website}) =>{
    // Store
    const dispatch = useDispatch()

    // Handle delete
    const handleDelete = async (e) =>{
        e.preventDefault()

        dispatch(handleDeleteWebsite(website.id, config(currentUser.token)))
    } 

    return(
        <button className='profile-website-actions-delete-btn' onClick={handleDelete}>Delete</button>
    )
}

export default ProfileWebsiteActionsDelete