// Icons
import {MdModeEditOutline} from 'react-icons/md'
import {AiFillDelete} from 'react-icons/ai'
import {AiOutlineFileAdd} from 'react-icons/ai'

/**
 * Functions component for a single website
 */
const DatabaseSingleActions = ({ website, setShowCommentForm }) =>{
    return(
        <div className="new-users-single-actions">
            <p className="new-users-single-comment">{website.comment? website.comment: "No comments"}</p>

            <div className="new-users-single-actions-btns">
                <button 
                    className={`${website.comment? "new-users-single-actions-comment-btn": "new-users-single-actions-comment-btn-disabled"}`}
                    onClick={()=>{setShowCommentForm(true)}}
                >
                    <MdModeEditOutline />
                </button>
                <button 
                    className={`${website.comment? "new-users-single-actions-comment-btn": "new-users-single-actions-comment-btn-disabled"}`}
                >
                <AiFillDelete />
                </button>
                <button 
                    className={`${!website.comment? "new-users-single-actions-comment-btn": "new-users-single-actions-comment-btn-disabled"}`}
                    onClick={()=>{setShowCommentForm(true)}}
                >
                    <AiOutlineFileAdd />
                </button>
            </div>
        </div>
    )
}

export default DatabaseSingleActions