import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import './backlink-options.css'

import gsap from 'gsap'

// Components
import BacklinkOptionsForm from './Forms/BacklinkOptionsForm'
import BacklinkOptionsTitle from './BacklinkOptionsTitle'
import BacklinkOptionsCollapsed from './BacklinkOptionsCollapsed'

export default function BacklinkOptions (){
    // Refs
    const wrapperRef = useRef()

    // Store
    const showChat = useSelector(({ backlinks }) => backlinks.showChat)
    const activeConnection = useSelector(({ backlinks }) => backlinks.activeConnection)

    // State
    const [showBacklinkSettings, setShowBacklinkSettings] = useState(true)

    // Animate container on render
    useEffect(()=>{
        const animation = gsap.fromTo(wrapperRef.current, { scaleY: 0 }, { scaleY: 1, duration: 0.1 })

        return () => {
            animation.kill()
        }
    }, [showChat])

    // If connection has been agreed and finalised, do not render
    // if(activeConnection?.approved === "finalised") return null

    // If the chat is not open, do not render this component
    if(!showChat) return null

    // If user selects to hide this, show collapsed button instead
    if(!showBacklinkSettings) return <BacklinkOptionsCollapsed setShowBacklinkSettings={setShowBacklinkSettings} />

    return(
        <div className='backlink-options' ref={wrapperRef}>
            <BacklinkOptionsTitle 
                title="Backlink details" 
                text="Decide the details of this backlink. You can compare your choices with your match's choices." 
                setShowBacklinkSettings={setShowBacklinkSettings}
                wrapperRef={wrapperRef}
            />

            <div className="backlink-options-content">
                <div className="backlink-options-form-wrapper">
                    <BacklinkOptionsForm />
                </div>
            </div>      
        </div>
    )
}