import { useState } from "react"

const BillingInvoices = () =>{
    // Local state
    const [invoices, setInvoices] = useState([])

    return(
        <div className="billing-incoices">
            <div className="billing-invoices-list">
                <div className="billing-invoices-list-top">
                    <div>Invoice ID</div>
                    <div>Issue date</div>
                    <div>Total</div>
                    <div>Paid on</div>
                </div>

                {invoices.length === 0 && <p className="billing-invoices-list-empty">No invoices found</p>}
            </div>
        </div>
    )
}

export { BillingInvoices }