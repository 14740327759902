import { createSlice } from "@reduxjs/toolkit";
import planService from "../../services/planService";

const initialState = {
    plans: [],
    activePlan: {},
    loading: true,
    error: null
}

const planSlice = createSlice({
    name: 'plans',
    initialState,
    reducers:{
        setPlans (state, action){
            state.plans = action.payload
        },
        setActivePlan (state, action){
            state.activePlan = action.payload
        },
        setLoading(state, action){
            state.loading = false
        },
        setError(state, action){
            state.error = action.payload
        }
    }
})

export const { setPlans, setActivePlan, setLoading, setError } = planSlice.actions

export const initializePlans = () =>{
    return async dispatch => {
        try {
            const response = await planService.getAll()
            dispatch(setPlans(response.data))
            dispatch(setLoading())
        } catch (error) {
            dispatch(setError(error.message))
        }
    }
}

export default planSlice.reducer