import { useState } from 'react'
import emailService from '../../services/emailService'

import {AiOutlineMail} from 'react-icons/ai'

const ForgotPasswordForm = ({ setCompleteEmail, setShowComplete }) =>{
    // State for input
    const [email, setEmail] = useState("")
    const [error, setError] = useState("")

    // Handle form submission
    const handleSubmit = async (e) =>{
        e.preventDefault()
    
        try {
            // Send password reset email
            const response = await emailService.passwordResetEmail({ email })

            // Check for api or email errors
            if(response.status === 204){
                setError("User was not found")
            } else if(response.data.status !== 200){
                setError(response.data.details)
            } else{
                setCompleteEmail(email)
                setEmail("")
                setShowComplete(true)
            }
        } catch (error) {
            console.log(error)
        }
    }

    return(
        <form id="forgot-password-form" onSubmit={handleSubmit}>
            <div>
                <AiOutlineMail />
                <input 
                    type="email" 
                    placeholder="Email address" 
                    value={email}
                    onChange={({target})=>{setEmail(target.value)}}   
                />
            </div>

            <button type="submit">Send email</button>
            <p className="forgot-password-form-error">{error}</p>
        </form>
    )
}

export default ForgotPasswordForm