import { ResponsiveContainer, LineChart, CartesianGrid, Line } from 'recharts';

const ProfileLandingChartBar = ({ statsData }) => (
    // <ResponsiveContainer width="50%" height="100%">
    //     <BarChart width={200} height={200} data={statsData}>
    //         <Bar dataKey="count" fill="#5400ff" label="count" />
    //     </BarChart>
    // </ResponsiveContainer>
    <ResponsiveContainer width="50%" height="100%">
        <LineChart width={500} height={300} data={statsData}>
        <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
        <Line type="monotone" dataKey="count" stroke="#5400ff" dot={false} />
        </LineChart>
    </ResponsiveContainer>
);

export default ProfileLandingChartBar