import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { handleCreateSuggestion } from '../../../store/reducers/suggestionsReducer';
import { setSuccess } from '../../../store/reducers/successReducer';
import { config } from '../../../apiConfig'

import Options from '../../Forms/Options'
import FormInputText from '../../Forms/FormInputText';
import OutlinkOptionsFormSubmit from './OutlinkOptionsFormSubmit';
import OutlinkOptionsFormHOC from './OutlinkOptionsFormHOC';


export default function OutlinkOptionsForm (){
    // Store
    const dispatch = useDispatch()
    const currentUser = useSelector(({ currentUser }) => currentUser)
    const activeConnection = useSelector(({ backlinks }) => backlinks.activeConnection)

    // Local state
    const [targetUrl, setTargetUrl] = useState("")
    const [backlinkType, setBacklinkType] = useState("")
    const [referringPage, setReferringPage] = useState("")
    const [anchorText, setAnchorText] = useState("")

    // Handle suggestions form 
    const handleSuggestionsSubmit = async (e) =>{
        e.preventDefault()
        
        // Data to send with request
        const data = { 
            targetUrl, backlinkType, referringPage, anchorText, connection: activeConnection.id 
        }

        // Dispatch action for creating a new suggestion
        dispatch(handleCreateSuggestion(data, config(currentUser.token)))

        // Reset fields
        setAnchorText("")
        setBacklinkType("")
        setReferringPage("")
        setTargetUrl("")

        // Show success message
        dispatch(setSuccess("New suggestion for this connection created"))
    }

    return(
        <form id='backlink-options-form' onSubmit={handleSuggestionsSubmit}>
            {/* Input fields */}
            <div className="backlink-options-form-div">
                <OutlinkOptionsFormHOC label="Target URL">
                    <FormInputText placeholder="www.website.com" inputValue={targetUrl} setInputValue={setTargetUrl} />
                </OutlinkOptionsFormHOC>

                <OutlinkOptionsFormHOC label="Referring page">
                    <FormInputText placeholder="www.website.com" inputValue={referringPage} setInputValue={setReferringPage} />
                </OutlinkOptionsFormHOC>
            </div>

            <OutlinkOptionsFormHOC  label="Backlink type">
                <Options title="Backlink type" list={optionsList} inputValue={backlinkType} setInputValue={setBacklinkType} />
            </OutlinkOptionsFormHOC>

            <OutlinkOptionsFormHOC label="Anchor text">
                <FormInputText placeholder="Your anchor text" inputValue={anchorText} setInputValue={setAnchorText} />
            </OutlinkOptionsFormHOC>
            
            {/* Submit button */}
            <OutlinkOptionsFormSubmit />
        </form>
    )
}

const optionsList = [
    {
        id: 1,
        title: "Guest"
    },
    {
        id: 2,
        title: "Embedded"
    },
    {
        id: 3,
        title: "Dofollow"
    },
    {
        id: 4,
        title: "Nofollow"
    },
]