import { useCallback, useEffect, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"

import { updateCurrentUser } from '../../../store/reducers/currentUserReducer'
import tokenService from '../../../services/tokenService'

import { Spinner } from "../../../common/Spinner"

const RegisterEmailVerification = () =>{
    // Store
    const dispatch = useDispatch()

    // Get token from params
    const navigate = useNavigate()
    const token = useParams().token

    // Local state
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    // Callback to send request
    const handleTokenVerification = useCallback(async(isMounted) =>{
        try {
            // Send request
            const response = await tokenService.verifyEmailToken(token)
            
            // If component is mounted perform actions
            if(isMounted){
                setLoading(false)

                // Set verified user in localstorage and store
                localStorage.setItem('currentUser', JSON.stringify(response.data))
                dispatch(updateCurrentUser(response.data))

                // Navigate to profile
                navigate("/profile/settings", { replace: true })
            }
        } catch (error) {
            if(isMounted){
                setError(error.message)
            }
        }
    }, [])

    // Effect to send request
    useEffect(()=>{
        let isMounted = true
        handleTokenVerification(isMounted)

        // Cleanup
        return () => { isMounted = false }
    }, [])

    // Handle error and loading
    if(error) return <p>{error}</p>
    if(loading) return <Spinner />

    return null
}

export { RegisterEmailVerification }