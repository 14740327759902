import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import './not-found.css'

const NotFound = () =>
{
    return(
        <div className="not-found">
            <Helmet>
                <title>Page not found 404</title>
                <meta name='description' content='The page you are looking for was not found' />
            </Helmet>

            <h1>Oops</h1>
            <p>The page you are searching for does not exist</p>
            <small>Return back to <Link to="/">Home page</Link></small>
        </div>
    )
}

export default NotFound