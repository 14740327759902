import { useState } from 'react'
import './website-details-danger-zone.css'

import { WebsiteDetailsDangerZoneAlert } from './WebsiteDetailsDangerZoneAlert'
import { WebsiteDetailsDangerZoneDelete } from './WebsiteDetailsDangerZoneDelete'

const WebsiteDetailsDangerZone = ({ website }) =>{
    // Local state
    const [showEditAlert, setShowEditAlert] = useState(false)
    const [showDeleteAlert, setShowDeleteAlert] = useState(false)
    const [deleteConfirmed, setDeleteConfirmed] = useState(false)

    return(
        <div className="website-details-danger-zone">
            <h1>Danger zone</h1>
            
            <div className="website-details-danger-zone-options">
                <div>
                    <div>
                        <h5>Edit website</h5>
                        <p>Editing a website may affect the way you a matched with potential backlinks</p>
                    </div>
                    <button className='website-details-danger-zone-options-edit-btn'>Alter website</button>
                </div>

                <WebsiteDetailsDangerZoneDelete 
                    websiteId={website.id} 
                    setShowDeleteAlert={setShowDeleteAlert} 
                    deleteConfirmed={deleteConfirmed} 
                    setDeleteConfirmed={setDeleteConfirmed}
                />
            </div>

           {showDeleteAlert &&  
           <WebsiteDetailsDangerZoneAlert 
                setShowDeleteAlert={setShowDeleteAlert} 
                setProceedToAction={setDeleteConfirmed}
                websiteUrl={website.url}
            />}
        </div>
    )
}

export { WebsiteDetailsDangerZone }