import { useState } from "react"
import { useSelector } from "react-redux"

import { config } from "../../../apiConfig"
import websiteService from '../../../services/websiteService'
import registerService from "../../../services/registerService"

// Icons
import {BsFillSaveFill} from 'react-icons/bs'
import {FiFlag} from 'react-icons/fi'

const NewWebsitesSingleActions = ({ website, setShowCommentForm, setWebsiteToComment }) =>{
    const currentUser = useSelector(({ currentUser }) => currentUser )

    // State
    const [DR, setDR] = useState(0)

    // Handle save button
    const handleSave = async (status) =>{
        let data = {}

        if(status === "spam"){
            data = { DR: 0, status: "spam" }
        } else {
            data = { DR, status: 'approved' }
        }

        try {
            // Send requests
            await websiteService.activateWebsite(website.id, data, config(currentUser.token))
            await registerService.activateUser(website.owner.id, data, config(currentUser.token))

            // Open comment pop up for admin
            setWebsiteToComment(website.id)
            setShowCommentForm(true)
        } catch (error) {
            console.log(error.message)
        }
    }

    return(
        <div className="new-users-single-actions">
            <div className="new-users-single-actions-dr-wrapper">
                <p>Give a DR</p>
                <input 
                    type="number" 
                    min={0}  
                    max={100}
                    step={1}
                    value={DR} 
                    onChange={({target})=>{setDR(target.value)}}
                />
            </div>     

            <div className="new-users-single-actions-btns">
                <button onClick={()=>{handleSave("spam")}} className="new-users-single-actions-spam-btn"><FiFlag /> Mark as spam</button>
                <button onClick={()=>{handleSave("ok")}} className="new-users-single-actions-save-btn"><BsFillSaveFill /> Save</button>
            </div>
        </div>
    )
}

export default NewWebsitesSingleActions
