import { getDateFromISO } from '../../../../../helpers/helpers'

import { FaLongArrowAltLeft, FaLongArrowAltRight } from 'react-icons/fa'

const WebsiteDetailsLinksSingle = ({ link, websiteId }) =>{

    return(
        <div className="website-details-links-single">
            <div className='website-details-links-single-direction'>
                {link.sourceWebsite.id === websiteId?
                    <FaLongArrowAltRight style={{ color: '#35009e' }} />
                    :<FaLongArrowAltLeft style={{ color: '#89cd00' }} />
                }
            </div>
            <div>{link.linkFrom}</div>
            <div>{link.linkTo}</div>
            <div>{link.added? "YES": "NO"}</div>
            <div>{getDateFromISO(new Date(link.updatedAt))}</div>
        </div>
    )
}

export { WebsiteDetailsLinksSingle }