import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    showChat: false,
    activeConnection: null,
    searchKey: ""
}

const backlinksReducer = createSlice({
    name: 'backlinks',
    initialState,
    reducers:{
        setShowChat(state, action){
            state.showChat = action.payload
        },
        setActiveConnection(state, action){
            state.activeConnection = action.payload
        },
        setSearchKey(state, action){
            state.searchKey = action.payload
        }
    }
})

export const { setShowChat, setActiveConnection, setSearchKey } = backlinksReducer.actions

export default backlinksReducer.reducer

