import { AiFillEdit, AiFillHeart } from 'react-icons/ai'

import { BacklinksMessagesSingleTextsMessageOptionsDelete } from './BacklinksMessagesSingleTextsMessageOptionsDelete'

const BacklinksMessagesSingleTextsMessageOptions = ({ message, setError }) =>{
    return(
        <div className="conversation-messages-single-messages-options">
            <BacklinksMessagesSingleTextsMessageOptionsDelete message={message} setError={setError} />
            {/* <button><AiFillHeart /></button>
            <button><AiFillEdit /></button> */}
        </div>
    )
}   

export { BacklinksMessagesSingleTextsMessageOptions }