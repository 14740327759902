import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

const ProfileSidebarOptionsDropdownSubButtonNotifications = ({ website, state, userConnections }) =>{
    // Store
    const currentUser = useSelector(({ currentUser }) => currentUser)

    // Local state
    const [notificationsCount, setNotificationsCount] = useState(0)

    // Find how many connections are "pending" for this website as an outlink or as a backlink
    useEffect(()=>{
        // Temp array to hold filtered connections
        let filteredConnections = []

        // Filter connections depending on state
        if(state === "outlink-connections"){
            filteredConnections = userConnections.filter( connection => 
                connection.targetWebsite.owner === currentUser.userForToken.id 
                && connection.targetWebsite.url === website.url
                && connection.approved === 'pending'
            )
        } else if (state === "backlink-connections"){
            filteredConnections = userConnections.filter( connection => 
                connection.sourceWebsite.owner === currentUser.userForToken.id 
                && connection.sourceWebsite.url === website.url
                && connection.approved === 'pending'
            )            
        }

        // Update notitications state
        setNotificationsCount(filteredConnections.length)
    }, [userConnections])

    if(notificationsCount <= 0) return null

    return(
        <div className="profile-sidebar-options-btn-notification">{notificationsCount}</div>
    )
}   

export { ProfileSidebarOptionsDropdownSubButtonNotifications }