import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { WebsiteOverviewListSingle } from './WebsiteOverviewListSingle'
import { Search } from '../../../Forms/Search'

const WebsiteOverviewList = () =>{
    // Store
    const websites = useSelector(({ websites }) => websites.userWebsites)

    // Local state
    const [filteredWebsites, setFilteredwebsites] = useState([])

    // Function to filter website
    const filterWebsites = (e) =>{
        return websites.filter( website => 
            website.url.toLowerCase().includes(e.target.value.toLowerCase())
        )
    }

    // Initialize filtered websites 
    useEffect(()=>{
        setFilteredwebsites([...websites])
    }, [websites])

    return(
        <div className="website-overview-list">
            <div className="website-overview-nav">
                <div className='website-overview-nav-search'>
                    <Search array={websites} filterFunction={filterWebsites} setFilteredArray={setFilteredwebsites} />
                </div>
            </div>

            <div className='website-overview-list-wrapper'>
                <div className="website-overview-list-top">
                    <div></div>
                    <div>Website</div>
                    <div>Outlinks</div>
                    <div>Backlinks</div>
                </div>

                <div className="website-overview-list-items">
                    {filteredWebsites.map( website => <WebsiteOverviewListSingle key={website.id} website={website} />)}
                    {filteredWebsites.length === 0 && <p className='website-overview-list-items-empty'>No websites found</p>}
                </div>
            </div>
        </div>
    )
}

export { WebsiteOverviewList }