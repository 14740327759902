import { createSlice } from "@reduxjs/toolkit";
import logService from "../../services/logService";

const initialState = {
    logs: [],
    loading: false,
    error: null
}

const logSlice = createSlice({
    name: 'logs',
    initialState,
    reducers:{
        setLogs(state, action){
            state.logs = action.payload
        },
        setLoading(state, action){
            state.loading = action.payload
        },
        setError(state, action){
            state.error = action.payload
        }
    }
})

export const { setLogs, setLoading, setError } = logSlice.actions

// Initialize logs
export const initializeLogs = (config) =>{
    return async dispatch => {
        dispatch(setLoading(true))

        try {
            const response = await logService.getLogs(config)
            dispatch(setLogs(response.data))
            dispatch(setLoading(false))

        } catch (error) {
            dispatch(setError(error.message))
        }
    }
}

export default logSlice.reducer

