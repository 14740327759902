const FeaturesSingle = ({ title, content, icon }) =>{
    return(
        <div className="features-single">
            <div className="features-single-top">
                <div className="features-single-top-icon">{icon}</div>
            </div>

            <div className="features-single-content">
                <h5>{title}</h5>
                <p>{content}</p>
            </div>
        </div>
    )
}

export { FeaturesSingle }