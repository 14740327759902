import { useState } from "react"
import { useSelector } from "react-redux"
import './profile-websites.css'

// Components
import ProfileWebsitesSingle from "./ProfileWebsitesSingle"
import ProfileWebsitesConfirmation from "./ProfileWebsitesConfiramtion"
import TokenExpiredMessage from '../../../../../Popups/TokenExpiredMessage'
import ProfileWebsitesAdd from "./ProfileWebsitesAdd"


const ProfileWebsites = () =>{
    // store
    const websites = useSelector(({websites}) => websites.userWebsites )

    // State
    const [showAddMessage, setShowAddMessage] = useState(false)
    const [showDeleteMessage] = useState(false)
    const [showTokenMessage] = useState(false)

    return(
        <div className="profile-websites">
            <p className="profile-info-details-title">Websites ({websites.length} registered websites)</p>

            {/* Websites details */}
            <div className="profile-websites-wrapper">
                {websites.map( website =>
                    <ProfileWebsitesSingle key={website.id} website={website} />
                )}
            </div>
                
            <ProfileWebsitesAdd setShowAddMessage={setShowAddMessage} />

            {/* Pop up messages */}
            {showAddMessage? <ProfileWebsitesConfirmation title="Added website successfully" text="Changes will apply on your next log-in" /> : ""}
            {showDeleteMessage? <ProfileWebsitesConfirmation title="Deleted website successfully" text="Changes will apply on your next log-in" /> : ""}
            {showTokenMessage? <TokenExpiredMessage /> : ""}
        </div>
    )
}

export default ProfileWebsites