import { BacklinksTopSearch } from "./BacklinksTopSearch"

const BacklinksTop = ({ title }) =>{
    return(
        <div className='backlinks-top'>
            <h1>{title}</h1>
            <BacklinksTopSearch />
        </div>
    )
}

export { BacklinksTop }