import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { initializeHelpArticles } from '../../store/reducers/helpReducer'

import './support.css'

import svg from '../../resources/support.svg'

import Nav from '../Nav/Nav'
import SupportFaqs from './SupportFaqs'
import SupportSearch from './SupportSearch/SupportSearch'
import SupportCategories from './SupportCategories/SupportCategories'

const Support = () =>{
    // Store
    const helpArticles = useSelector(({helpArticles}) => helpArticles.helpArticles)
    const helpArticlesLoading = useSelector(({helpArticles}) => helpArticles.loading)
    const dispatch = useDispatch()

    // local state
    const [activeFaq, setActiveFaq] = useState(helpArticles[0])

    // Get help articles from database
    useEffect(()=>{
        dispatch(initializeHelpArticles())
    }, [])

    // Return skeleton if loading isn't complete
    if(helpArticlesLoading) return <p>Loading</p>

    return(
        <div className="support">
            <Nav />
            <SupportSearch />
            <img src={svg} alt="support" /> 
            <SupportFaqs setActiveFaq={setActiveFaq} />      
            <SupportCategories />      
        </div>
    )
}   

export default Support