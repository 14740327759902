import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Helmet } from 'react-helmet-async'
import './dashboard-pages.css'

import { initializeAllWebsites, initializeUserWebsites  } from '../../store/reducers/websiteReducer'
import { initializeUserConversations } from '../../store/reducers/conversationReducer'
import { initializeConnections } from '../../store/reducers/connectionReducer'
import { initialiazePages } from '../../store/reducers/walkthroughReducer'
import { setActiveButton } from '../../store/reducers/sidebarReducer'

import { config } from '../../apiConfig'

// Components
import { Spinner } from '../../common/Spinner'
import ProfileNav from "../../components/Profile/ProfileNav"
import ProfileSideBar from "../../components/Profile/ProfileSidebar/ProfileSidebar"
import { ProfileSidebarMobile } from "../../components/Profile/ProfileSidebar/Mobile/ProfileSidebarMobile"
import Profile from '../../components/Profile/ProfilePages/ProfileInfo/Profile'
import ProfileLanding from '../../components/Profile/ProfileLanding/ProfileLanding'
import { WebsiteOverview } from '../../components/Profile/ProfilePages/WebsiteOverview/WebsiteOverview'
import { WebsiteDetails } from '../../components/Profile/ProfilePages/WebsiteDetails/WebsiteDetails'
import { Onboarding } from '../../components/Profile/Onboarding/Onboarding'
import { InitialConnection } from '../../components/Connections/InitialConnection'
import { Billing } from '../../components/Billing/Billing'
import { Backlinks } from '../../components/Backlinks/Backlinks'
import { Outlinks } from '../../components/Outlinks/Outlinks'
import { Logs } from '../../components/Logs/Logs'
import { DashboardPageError } from './DashboardPageError'
import { Settings } from '../../components/Settings/Settings'
import ErrorToast from '../../common/Error/ErrorToast'
import SuccessToast from '../../common/Success/SuccessToast'

const DashboardPage = ({ page }) =>{
    // Get currentUser from store
    const dispatch = useDispatch()
    const currentUser = useSelector(({currentUser}) => currentUser )
    const allWebsitesLoading = useSelector(({websites}) => websites.allWebsitesloading)
    const allWebsitesError = useSelector(({websites}) => websites.error)
    const userWebsitesLoading = useSelector(({websites}) => websites.userWebsitesLoading)
    const userWebsitesError = useSelector(({websites}) => websites.userWebsitesError)
    const conversationLoading = useSelector(({conversations}) => conversations.userConversationsLoading)
    const conversationsError = useSelector(({conversations}) => conversations.userConversationsError)
    const connectionsLoading = useSelector(({connections}) => connections.loading )
    const connectionsError = useSelector(({connections}) => connections.error )
    const walkthroughLoading = useSelector(({walkthrough}) => walkthrough.loading)
    const walkthroughError = useSelector(({walkthrough}) => walkthrough.error)
    const mobileSidebarIsOpen = useSelector(({ sidebar }) => sidebar.mobileSidebarIsOpen)

    // State for name, sidebar open / close and the active button choice
    const [sidebarIsOpen, setSidebarIsOpen] = useState(true)
    const [showOnboarding, setShowOnboarding] = useState(localStorage.getItem('showOnboarding') || "true")

    // Initialize connections
    useEffect(()=>{ 
        dispatch(initializeConnections())
    }, [dispatch])

    // Initialize conversations in store
    useEffect(()=>{
        dispatch(initializeUserConversations(currentUser.userForToken.id))
    }, [dispatch, currentUser.userForToken.id])

    // Initialize all websites in store
    useEffect(()=>{
        dispatch(initializeAllWebsites())
    }, [dispatch])

    // Initialize user websites in store
    useEffect(()=>{
        dispatch(initializeUserWebsites(currentUser.userForToken.id, config(currentUser.token)))
    },[dispatch, currentUser.userForToken.id])

    // Initialize walkthrough
    useEffect(()=>{
        dispatch(initialiazePages())
    }, [dispatch])

    // Set active button in store to match the page
    useEffect(()=>{
        dispatch(setActiveButton(page))
    }, [dispatch])

    // Render error message if error occurs
    if(allWebsitesError || userWebsitesError || conversationsError || connectionsError || walkthroughError ) return <DashboardPageError />

    // Return skeleton if loading data hasn't finished
    if(allWebsitesLoading || userWebsitesLoading || conversationLoading || connectionsLoading || walkthroughLoading) return <Spinner />
    
    return(
        <div className="dashboard-page">
            <Helmet>
                <title>Linkeby | {page}</title>
                <meta name="description" content="Linkeby dashboard" />
            </Helmet>

            <ProfileNav />
            <InitialConnection />

            {JSON.parse(showOnboarding) && <Onboarding setShowOnboarding={setShowOnboarding} />}  

            <div className="dashboard-page-container">
                {sidebarIsOpen && <ProfileSideBar sidebarIsOpen={sidebarIsOpen} />}
                {mobileSidebarIsOpen && <ProfileSidebarMobile />}

                <div className="dashboard-page-container-content">
                    {page === '' && <ProfileLanding />}
                    {page === 'profile' && <Profile />}
                    {page === 'outlink-connections' && <Outlinks />}
                    {page === 'backlink-connections' && <Backlinks />}
                    {page === 'settings' && <Settings />}
                    {page === 'website-overview' && <WebsiteOverview />}
                    {page === 'website-details' && <WebsiteDetails />}
                    {page === 'billing' && <Billing />}
                    {page === 'logs' && <Logs />}
                </div>

                {/* Global toasts for success and failure on requests */}
                <ErrorToast />
                <SuccessToast />
            </div>
        </div>
    )
}

export { DashboardPage }