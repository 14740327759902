import { useState } from 'react'
import './website-overview.css'

import { DashboardFooter } from '../../../Footer/DashboardFooter'
import { WebsiteOverviewList } from './WebsiteOverviewList'
import ProfileWebsitesAddForm  from '../ProfileInfo/Pages/Websites/ProfileWebsitesAddForm'
import SuccessToast from '../../../Popups/SuccessToast'
import { WebsiteOverviewAddButton } from './WebsiteOverviewAddButton'

const WebsiteOverview = () =>{
    // Local state
    const [formIsOpen, setFormIsOpen] = useState(false)
    const [showAddSuccess, setShowAddSuccess] = useState(false)

    return(
        <div className="website-overview">
            <div className="website-overview-top">
                <h1>Website overview</h1>
                <WebsiteOverviewAddButton setFormIsOpen={setFormIsOpen} />
            </div>

            <WebsiteOverviewList />
            {formIsOpen && <ProfileWebsitesAddForm setFormIsOpen={setFormIsOpen} setSuccess={setShowAddSuccess} />}
            {showAddSuccess && <SuccessToast title="Website added" subtitle="New website was successfully added" setSuccess={setShowAddSuccess} />}

            <DashboardFooter />
        </div>
    )
}

export { WebsiteOverview }