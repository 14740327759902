const CheckBox = () =>
{
    return(
        <div className="login-checkbox">
            <input name="checkbox" type="checkbox" />
            <label htmlFor="checkbox">Keep me logged in</label>
        </div>
    )
}

export default CheckBox