import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import './notifications.css'

// Import components
import NotificationSingle from './NotificationsSingle'

/**
 * All notifications wrapper
 */
const Notifications = () =>{
    // Store 
    const notifications = useSelector(({ notifications }) => notifications.notifications)

    // Sort notifications by date
    const sortedNotifications = useMemo(()=>{
        return [...notifications].sort((a, b) => a.createdAt - b.createdAt).reverse()
    }, [notifications])

    return(
        <div className="notifications">
            <h1 className='notifications-title'>Notifications</h1>

            {notifications.length === 0? 
            <div className="notifications-empty">
                <p>No notifications available.</p>
            </div>
            : ""}

            {sortedNotifications.map( notification =>
                <NotificationSingle key={notification.id} notification={notification} />
            )}
        </div>
    )
}

export default Notifications