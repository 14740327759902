import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { initializeNiches } from '../../../../../../store/reducers/nicheReducer'
import { initializeLanguages } from '../../../../../../store/reducers/languagesReducer'
import { handleAddWebsite } from '../../../../../../store/reducers/websiteReducer'
import { config } from '../../../../../../apiConfig'

// Components
import NicheOptions from '../../../../../Register/FinishRegistration/NicheOptions'
import FormInputText from '../../../../../Forms/FormInputText'
import SubmitButton from '../../../../../Forms/SubmitButton'
import Options from '../../../../../Forms/Options'

// Icons
import {TbWorld} from 'react-icons/tb'
import {IoLanguageOutline} from 'react-icons/io5'

const ProfileWebsitesAddForm = ({ setFormIsOpen, setSuccess }) =>{
    // Store
    const dispatch = useDispatch()
    const currentUser = useSelector(({ currentUser }) => currentUser)
    const languages = useSelector(({ languages }) => languages.languages)
    const languagesLoading = useSelector(({ languages }) => languages.loading)
    const languagesError = useSelector(({ languages }) => languages.error)
    const niches = useSelector(({ niches }) => niches.niches)
    const nichesLoading = useSelector(({ niches }) => niches.loading)
    const nichesError = useSelector(({ niches }) => niches.error)

    // State for input
    const [url, setUrl] = useState("")
    const [niche, setNiche] = useState("")
    const [description, setDescription] = useState("")
    const [languageOfWebsite, setLanguageOfWebsite] = useState("")
    const [ageOfWebsite, setAgeOfWebsite] = useState("")

    // Handle form submission
    const handleFormSubmit = async (e) =>{
        e.preventDefault()

        // Data to be passed
        const data = { url, niche, description, ageOfWebsite, languageOfWebsite }

        // Add new website
        dispatch(handleAddWebsite(data, config(currentUser.token)))

        // Reset fields
        setUrl(""); 
        setNiche(""); 
        setAgeOfWebsite(""); 
        setDescription(""); 
        setLanguageOfWebsite("")

        // Show and hide pop up message
        setFormIsOpen(false)
        setSuccess(true)
        setTimeout(()=>{
            setSuccess(false)
        }, 3000)
    }

    // Initialize categories in store
    useEffect(()=>{
        dispatch(initializeNiches())
    }, [dispatch])

    // Initialize languages
    useEffect(()=>{
        dispatch(initializeLanguages())
    }, [dispatch])

    // Handle errors and loading
    if(nichesError || languagesError) return <p>Error loading data</p>
    if(nichesLoading || languagesLoading) return null

    return(
        <div className="profile-website-form-wrapper">
            <form id="profile-website-form" onSubmit={handleFormSubmit}>
                <p className='profile-website-form-title'>Website details</p>
                <p className='profile-website-form-sub'>Enter the url, category, age of website, language and a short description for the website</p>

                <FormInputText icon={<TbWorld />} placeholder="Url of website" inputValue={url} setInputValue={setUrl} />
                <NicheOptions niche={niche} setNiche={setNiche} niches={niches} />
                {/* <FormInputDate icon={<BsFillCalendarDateFill />} setInputValue={setAgeOfWebsite} /> */}

                <Options title="Choose a language" icon={<IoLanguageOutline />} list={languages} inputValue={languageOfWebsite} setInputValue={setLanguageOfWebsite} />
                {/* <Textarea icon={<MdDescription />} placeholder="Short description" inputValue={description} setInputValue={setDescription} /> */}
                
                <SubmitButton />
                <button className="profile-website-form-close-btn" onClick={()=>{setFormIsOpen(false)}}>X</button>
            </form>
        </div>
    )
}

export default ProfileWebsitesAddForm