export default function BacklinksTableItemSinglePendingModal ({ setShowPendingModal }){    
    return(
        <div className="modal-wrapper">
            <div className="backlinks-table-items-single-pending-modal">
                <h1>Pending connections</h1>
                <p>When a connection is generated, the target user has to first view and approve the connection before you can proceed any further.</p>
                <p>In the case where the user rejects the connection for whatever reason, the connection will be treated as if never existed. Ohterwise
                , if accepted you will see it in your backlinks table.</p>
                <button onClick={()=>{ setShowPendingModal(false) }}>X</button>
            </div>
        </div>
    )
}