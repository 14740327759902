import { useState } from 'react'
import './onboarding.css'

import tutorial from '../../../resources/tutorial.svg'

import { OnboardingStep } from './OnboardingStep'
import { OnboardingCloseButton } from './OnboardingCloseButton'

const Onboarding = ({ setShowOnboarding }) =>{
    // Local state
    const [step, setStep] = useState("0")

    // Steps data
    const steps = [
        {
            title: "Getting started",
            text: "Welcome to Linkeby. This quick tour will help you go through the key points of our platform.",
            subtext: "It will only take seconds but you can skip the tour and access it on the support page any time.",
            stepToSet: "1",
            id: 1
        },
        {
            title: "Backlinks",
            text: "The key ingridient of the platform, backlinks will help your website grow. You will be connected to other websites which will be responsible for hosting your preferred links.",
            subtext: "Once a backlink connection is generated, you can chat with the user on the dedicated section of the platform and come up with an aggreement about the backlink details.",
            stepToSet: "2",
            id: 2
        },
        {
            title: "Outlinks",
            text: "Outlinks are the links you can host on your website. They are generated in the same way as backlinks but with reversed roles.",
            subtext: "Communication is key and similar to backlinks you can discuss and suggest your preferred options for hosting a connection's link.",
            stepToSet: "3",
            id: 3
        },
        {
            title: "Connections",
            text: "Connections are the first step towards a backlink. They are automatically generated twice a week, so you will be connected to two potential websites for a backlink.",
            subtext: "Other users can get connections with your website, in which case you will be their backlink.",
            stepToSet: "4",
            id: 4
        },
    ]
    
    return(
        <div className="onboarding">
            <OnboardingCloseButton className="onboarding-close-btn" label="X" setShowOnboarding={setShowOnboarding} />

            <div className="onboarding-content">
                <div className="onboarding-content-top">
                    <img src={tutorial} alt="tutorial" />
                </div>

                {step === "0" && 
                <OnboardingStep 
                        setShowOnboarding={setShowOnboarding}
                        step={step}
                        setStep={setStep}
                        stepToSet={steps[0].stepToSet}
                        title={steps[0].title}
                        text={steps[0].text}
                        subtext={steps[0].subtext}
                />}
                {step === "1" && 
                <OnboardingStep 
                        setShowOnboarding={setShowOnboarding}
                        step={step}
                        setStep={setStep}
                        stepToSet={steps[1].stepToSet}
                        title={steps[1].title}
                        text={steps[1].text}
                        subtext={steps[1].subtext}
                />}
                {step === "2" && 
                <OnboardingStep 
                        setShowOnboarding={setShowOnboarding}
                        step={step}
                        setStep={setStep}
                        stepToSet={steps[2].stepToSet}
                        title={steps[2].title}
                        text={steps[2].text}
                        subtext={steps[2].subtext}
                />}
                {step === "3" && 
                <OnboardingStep 
                        setShowOnboarding={setShowOnboarding}
                        step={step}
                        setStep={setStep}
                        stepToSet={steps[3].stepToSet}
                        title={steps[3].title}
                        text={steps[3].text}
                        subtext={steps[3].subtext}
                />}
                {step === "4" &&
                <OnboardingStep 
                        setShowOnboarding={setShowOnboarding}
                        step={step}
                        setStep={setStep}
                        title="Great!"
                        text="Thank you for going through the tour. You can now start getting backlinks!"
                />}
            </div>
        </div>
    )
}

export { Onboarding }