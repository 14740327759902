import { createSlice } from "@reduxjs/toolkit";
import linkService from "../../services/linkService";

const initialState = {
    allLinks: [],
    allWebsiteLinks: [],
    allWebsiteLinksLoading: true,
    websiteLinks: [],
    loading: true,
    success: null,
    error: null
}

const linkReducer = createSlice({
    name: 'links',
    initialState,
    reducers: {
        setAllLinks(state, action){
            state.allLinks = action.payload
        },
        setAllWebsiteLinks(state, action){
            state.allWebsiteLinks = action.payload
        },
        setWebsiteLinks(state, action){
            state.websiteLinks = action.payload
        },
        addLink(state, action){
            state.websiteLinks = [...state.websiteLinks, action.payload]
        },
        editLink(state, action){
            const newState = state.websiteLinks.map( link => 
                link.id === action.payload.id? action.payload: link
            )
            state.websiteLinks = [...newState]
        },
        deleteLink(state, action){
            state.websiteLinks = state.websiteLinks.filter( link => link.id !== action.payload.id )
        },
        setLoading(state, action){
            state.loading = action.payload
        },
        setAllWebsiteLinksLoading(state, action){
            state.allWebsiteLinksLoading = false
        },
        setSuccess(state, action){
            state.success = action.payload
        },
        setError(state, action){
            state.error = action.payload
        }
    }
})

export const { 
    setAllLinks, 
    setAllWebsiteLinks, 
    setWebsiteLinks, 
    addLink, 
    editLink, 
    deleteLink, 
    setLoading, 
    setAllWebsiteLinksLoading,
    setBacklinksChatOpen,
    setActiveBacklinksConnection,
    setSuccess, 
    setError 
} = linkReducer.actions

// Get all links in database
export const initializeAllLinks = (config) =>{
    return async dispatch =>{
        try {
            const response = await linkService.getAll(config)
            dispatch(setAllLinks(response.data))
            dispatch(setLoading(false))
        } catch (error) {
            dispatch(setError(error.message))
        }
    }
}

// Get all links for website
export const initializeAllWebsiteLinks = (id, config) =>{
    return async dispatch =>{
        try {
            const response = await linkService.getAllWebsiteLinks(id, config)
            dispatch(setAllWebsiteLinks(response.data))
            dispatch(setAllWebsiteLinksLoading(false))
        } catch (error) {
            dispatch(setError(error.message))
        }
    }
}

// Get all links for given website as source or target
export const initializeWebsiteLinks = (id, option, config) =>{
    return async dispatch => {
        try {
            const response = await linkService.getWebsiteLinks(id, option, config)
            dispatch(setWebsiteLinks(response.data))
            dispatch(setLoading(false))
        } catch (error) {
            dispatch(setError(error.message))
        }
    }
}

// Add a new link
export const handleAddLink = (data, config) =>{
    return async dispatch =>{
        try {
            const response = await linkService.postLink(data, config)
            dispatch(addLink(response.data))
        } catch (error) {
            dispatch(setError(error.message))
        }
    }
}

// Edit a link (mark as completed or reported)
export const handleEditLink = (id, data, config) =>{
    return async dispatch =>{
        try {
            const response = await linkService.editLink(id, data, config)
            dispatch(editLink(response.data))
        } catch (error) {
            dispatch(setError(error.message))
        }
    }
}

// Delete a link
export const handleDeleteLink = (id, config) =>{
    return async dispatch =>{
        try {
            const response = await linkService.deleteLink(id, config)
            dispatch(deleteLink(response.data))
        } catch (error) {
            dispatch(setError(error.message))
        }
    }
}

export default linkReducer.reducer