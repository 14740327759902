import { BacklinksTableNavOptionsSingle } from "./BacklinksTableNavOptionsSingle"

import { BiGridSmall } from 'react-icons/bi'
import { FaLongArrowAltUp } from 'react-icons/fa'
import { AiOutlineCheckCircle } from 'react-icons/ai'
import { MdOutlineReportGmailerrorred } from 'react-icons/md'
import BacklinksTableNavOptionsPendingMessage from "./BacklinksTableNavOptionsPendingMessage"

const BacklinksTableNavOptions = ({ connectionsFilter, setConnectionsFilter, role }) =>{
    return(
        <div className="backlinks-table-nav-options">
                <BacklinksTableNavOptionsSingle 
                    label="All" 
                    icon={<BiGridSmall />} 
                    connectionsFilter={connectionsFilter}
                    setConnectionsFilter={setConnectionsFilter}
                />
                <BacklinksTableNavOptionsSingle 
                    label="New" 
                    icon={<FaLongArrowAltUp />} 
                    connectionsFilter={connectionsFilter}
                    setConnectionsFilter={setConnectionsFilter}
                />
                <BacklinksTableNavOptionsSingle 
                    label="Approved" 
                    icon={<AiOutlineCheckCircle />} 
                    connectionsFilter={connectionsFilter}
                    setConnectionsFilter={setConnectionsFilter}
                />
                <BacklinksTableNavOptionsSingle 
                    label="Completed" 
                    icon={<MdOutlineReportGmailerrorred />} 
                    connectionsFilter={connectionsFilter}
                    setConnectionsFilter={setConnectionsFilter}
                />
                <BacklinksTableNavOptionsPendingMessage role={role} />
            </div>
    )
}

export { BacklinksTableNavOptions }