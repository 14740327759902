import { useSelector } from "react-redux"

import { BillingOverviewPaymentMethodSingle } from "./BillingOverviewPaymentMethodSingle"

const BillingOverviewPaymentMethod = ({ setShowAddPayment }) =>{
    // Store
    const currentUser = useSelector(({ currentUser }) => currentUser)

    return(
        <div className="billing-details">
            <div className="billing-details-content">
                <h5>Payment methods</h5>
                {currentUser.userForToken.paymentMethods.map( payment => <BillingOverviewPaymentMethodSingle key={payment.id} payment={payment} /> )}
                <button onClick={()=>{ setShowAddPayment(true) }}>+ Add payment method</button>
                <small>Set one of your payment methods as default to be automatically charged on the next billing cycle</small>
            </div>

            <div className="billing-details-content">
                <h5>Billing details</h5>
                <li>{currentUser.userForToken.billing?.line1}</li>
                <li>{currentUser.userForToken.billing?.city}</li>
                <li>{currentUser.userForToken.billing?.country}</li>
                <li>{currentUser.userForToken.billing?.postal_code}</li>
            </div>


        </div>
    )
}

export { BillingOverviewPaymentMethod }