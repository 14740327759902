import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { initializeAllWebsites } from '../../store/reducers/websiteReducer'
import './admin.css'


// Import components
import NewWebsites from './NewWebsites/NewWebsites'
import Database from "./Database/Database"
import Disputes from './Disputes/Disputes'
import Support from './Support/Support'


const AdminPanel = ({ option }) =>{
    // Dispatch
    const dispatch = useDispatch()

    // Initialize all websites
    useEffect(()=>{
        dispatch(initializeAllWebsites())
    }, [dispatch])

    return(
        <div className="admin-panel">
            {option==="new-websites"? <NewWebsites />: ""}
            {option==="database"? <Database />: ""}
            {option==="disputes"? <Disputes />: ""}
            {option==="support"? <Support />: ""}
        </div>
    )
}

export default AdminPanel