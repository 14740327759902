import { Helmet } from "react-helmet-async"
import Nav from "../../components/Nav/Nav"

const About = () =>{
    return(
        <div className="about">
            <Helmet>
                <title>Linkeby | About us</title>
                <meta name="description" content="Simplifying the hardest part of SEO" />
            </Helmet>

            <Nav />
        </div>
    )
}

export { About }