import { useMemo } from 'react'
import { getDateFromTimestamp } from '../../helpers/helpers'

const LogSingle = ({ log }) =>{
    const text = useMemo(()=>{
        return log.text.split(':')
    }, [log])

    return(
        <div className="log-single">
            <div>
                <p>{log.category? log.category: "-"}</p>
            </div>

            <div>
                <p>{text[0]} <span>{text[1]}</span></p>
            </div>
        
            <div>
                <small>{log.createdAt? getDateFromTimestamp(log.createdAt): "-"}</small>
            </div>
        </div>
    )
}

export { LogSingle }