import './profile-pages.css'

// Import components
import ProfileInfo from './Pages/Info/ProfileInfo'
import ProfileWebsites from './Pages/Websites/ProfileWebsites'
import { WalkthroughTour } from '../../../../common/WalkthroughTour'
import { DashboardFooter } from '../../../Footer/DashboardFooter'
import { ProfileMessage } from './ProfileMessage'

/**
 *  Profile page
 */
const Profile = () =>{
    return(
        <div className="profile-profile">
            <div className="profile-pages-wrapper">
                <WalkthroughTour pageName="PROFILE" />
                <ProfileMessage />
                <ProfileInfo /> 
                <ProfileWebsites /> 
            </div>

            <DashboardFooter />
        </div>
    )
}

export default Profile