import { useSelector } from "react-redux"

const ProfileMessage = () =>{
    // store
    const websites = useSelector(({websites}) => websites.userWebsites )

    // If niche and language have been added, do not render anything
    if(websites[0].niche.length !== 0 && websites[0].languageOfWebsite.length !== 0) return null

    return(
        <div className="profile-message">
            <h1>Complete your profile</h1>
            <p>Please complete your website details by adding your niche and language. 
            This helps with matching you to more suitable websites. You can select more than one niches or languages.</p>
            <button className="profile-message-btn">Why is this important?</button>
            <button className="profile-message-close-btn">X</button>
        </div>
    )
}

export { ProfileMessage }