import axios from "axios";
const baseUrl = `${process.env.REACT_APP_API_BASE_URL}stripe`

const getStripeKey = () => axios.get(`${baseUrl}/publishable-key`)

const createCustomer = (data) => axios.post(`${baseUrl}/create-customer`, data)

const createPaymentIntent = (data) => axios.post(`${baseUrl}/create-payment-intent`, data)

const createSetupIntent = (data) => axios.post(`${baseUrl}/create-setup-intent`, data)

const createSubscription = (data) => axios.post(`${baseUrl}/create-subscription`, data)

const cancelSubscription = (data, config) => axios.post(`${baseUrl}/cancel-subscription`, data, config)

const retrievePaymentMethod = (data, config) => axios.post(`${baseUrl}/retrieve-customer-payment-method`, data, config)

const detachPaymentMethod = (data, config) => axios.post(`${baseUrl}/detach-payment-method`, data, config)

const paymentService = { 
    getStripeKey, 
    createPaymentIntent, 
    createSetupIntent,
    createCustomer, 
    createSubscription, 
    cancelSubscription,
    retrievePaymentMethod,
    detachPaymentMethod
}

export default paymentService