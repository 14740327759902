import { Link } from "react-router-dom"
import './hero.css'

// Icons
import { HiArrowRight } from 'react-icons/hi'

// Assets
import blob from '../../../resources/svg/blob.svg'
import blob2 from '../../../resources/svg/blob2.svg'

import HeroWaitlist from "./HeroWaitlist"
import HeroUI from "./HeroUI"

const Hero = () =>{
    // Conditionally render waitlist or login section
    const sectionToRender = () =>{
        if(process.env.REACT_APP_API_APP_STAGE === 'waitlist'){
            return <HeroWaitlist />
        }

        return <Link to="/register" className='landing-join-btn'>Start for free <HiArrowRight /></Link>
    }

    return(
        <header>            
            <div className="hero-content">
                <h1>Simplifying the hardest part of <span>SEO</span></h1>
                <p>Connect, link and rank all in one platform. We provide all the tools you need with no extra hassle.</p>
                {sectionToRender()}
            </div>

            <HeroUI />

            <img id="hero-shape" src={blob} alt="blob" />
            <img id="hero-shape2" src={blob2} alt="blob" />
        </header>
    )
}

export { Hero }