import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import { setActiveButton } from "../../../../store/reducers/sidebarReducer"
import { setMobileSidebarIsOpen } from "../../../../store/reducers/sidebarReducer"

import { IoAlert } from "react-icons/io5";

const ProfileSidebarOptionsButtonProfile = ({ icon, label, state }) =>{
    // Store
    const dispatch = useDispatch()
    const userWebsites = useSelector(({ websites }) => websites.userWebsites)
    const activeButton = useSelector(({ sidebar }) => sidebar.activeButton)

    // Router
    const navigate = useNavigate()

    // Local state
    const [showAlert, setShowAlert] = useState(false)

    // Handle link click
    const handleClick = () =>{
        // Set active button in store
        dispatch(setActiveButton(state))

        // Open mobile sidebar (if applicable)
        dispatch(setMobileSidebarIsOpen())

        // Navigate to requested link
        navigate(`/profile/${state}`)
    }

    // If the user's website has missing info, show alert next to button
    useEffect(()=>{
        if((userWebsites[0] && userWebsites[0].niche.length === 0) || (userWebsites[0] && userWebsites[0].languageOfWebsite.length === 0)){
            setShowAlert(true)
        }
    }, [userWebsites])

    return(
        <button 
            className={`${activeButton === state? "profile-sidebar-options-btn-active" : "profile-sidebar-options-btn"}`}
            onClick={handleClick}
        >
            <span className="profile-sidebar-options-btn-icon">{icon}</span>
            {label}
            {showAlert && <div className="profile-sidebar-options-btn-alert"><IoAlert /></div>}
        </button>
    )
}

export { ProfileSidebarOptionsButtonProfile }