import { useEffect, useRef } from 'react'
import gsap from 'gsap'

import './profile-sidebar.css'

import ProfileSideBarOptions from './Options/ProfileSidebarOptions'

const ProfileSideBar = ({ sidebarIsOpen }) =>{
    // Refs
    const ref = useRef()

    // Animate sidebar on close / open
    useEffect(()=>{
        if(!sidebarIsOpen){
            gsap.to(ref.current, {duration:0.3, scaleX: 0.2, transformOrigin: 'left'})
        } else{
            gsap.to(ref.current, {duration:0.3, scaleX: 1, transformOrigin: 'left'})
        }
    },[sidebarIsOpen])

    return(
        <div className="profile-sidebar" ref={ref}>
            {/* <WalkthroughTour pageName="SIDEBAR" /> */}
            <ProfileSideBarOptions />
        </div>
    )
}

export default ProfileSideBar