import { createSlice } from "@reduxjs/toolkit";
import categoriesService from "../../services/categoriesService";

const initialState = {
    niches: [],
    loading: false,
    error: null
}

const nicheSlice = createSlice({
    name: 'niches',
    initialState,
    reducers: {
        setNiches(state, action){
            state.niches = action.payload
        },
        setLoading(state, action){
            state.loading = action.payload
        },
        setError(state, action){
            state.error = action.payload
        }
    }
})

export const { setNiches, setLoading, setError } = nicheSlice.actions

// Handle initializing niches
export const initializeNiches = () =>{
    return async dispatch => {
        dispatch(setLoading(true))

        try {
            const response = await categoriesService.getAll()

            dispatch(setNiches(response.data))
            dispatch(setLoading(false))
        } catch (error) {
            dispatch(setError(error.message))
        }
    }
}

export default nicheSlice.reducer
