import { useEffect } from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async'

import { initializeUsers } from './store/reducers/userReducer';

// Hooks
import useVerifyToken from './hooks/index/useVerifyToken';
import useServerSideEvents from './hooks/index/useServerSideEvents';

import './App.css';

// Import components
import RegisterPage from './components/Register/Register';
import FinishRegistration from './components/Register/FinishRegistration/FinishRegistration';
import Profile from './components/Profile/Profile';
import NotFound from './components/NotFound/NotFound';
import RegisterAwaitConfirmation from './components/Register/RegisterAwaitConfirmation';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import ForgotPasswordReset from './components/ForgotPassword/ForgotPasswordReset';
import AdminPage from './components/Admin/AdminPage';
import { ProtectedRoute } from './common/ProtectedRoute';
import Login from './components/Login/Login';
import SubscriptionPlans from './components/Subscription/SubscriptionPlans/SubscriptionPlans';
import { Landing } from './components/Landing/Landing';
import Support from './components/Support/Support'
import SupportArticle from './components/Support/SupportArticles/SupportArticle';
import { Contact } from './pages/Contact/Contact';
import { Product } from './pages/Product/Product';
import { Company } from './pages/Company/Company';
import { Feedback } from './components/Feedback/Feedback';
import { DashboardPage } from './pages/Dashboard/DashboardPage';
import { Privacy } from './pages/Privacy/Privacy';
import { Terms } from './pages/Terms/Terms';
import { About } from './pages/About/About';
import { RegisterPaymentSuccess } from './components/Register/Payment/Success/RegisterPaymentSuccess'
import { RegisterIncomplete } from './components/Register/Incomplete/RegisterIncomplete';
import { RegisterPlans } from './components/Register/Plans/RegisterPlans';
import { RegisterPayment } from './components/Register/Payment/RegisterPayment';
import { RegisterPaymentFailure } from './components/Register/Payment/RegisterPaymentFailure';
import { RegisterPaymentTrial } from './components/Register/Payment/Trial/RegisterPaymentTrial';
import { BillingStatus } from './components/Billing/BillingStatus';
import { FinishRegistrationFailed } from './components/Register/FinishRegistration/FinishRegistrationFailed';
import { RegisterEmailVerification } from './components/Register/EmailVerification/RegisterEmailVerification';
import Waitlist from './pages/Waitlist/Waitlist';
import WaitlistComplete from './pages/WaitlistComplete/WaitlistComplete'

function App() {
  // Store
  const currentUser = useSelector(({ currentUser }) => currentUser)
  const dispatch = useDispatch()

  // Check if saved user exists and if the token is valid
  useVerifyToken()

  // Handle Server-Sent Events when stripe events are triggered
  // useServerSideEvents()

  // Get users from store
  useEffect(()=>{
      dispatch(initializeUsers())
  }, [dispatch])  

  return (
    <HelmetProvider>
      <div className="app-wrapper">
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<Landing />} />
            <Route path='/privacy' element={<Privacy />} />
            <Route path='/terms' element={<Terms />} />
            <Route path='/about' element={<About />} />

            <Route path='/waitlist' element={<Waitlist />} />
            <Route path='/waitlist/complete/:email' element={<WaitlistComplete />} />
            <Route path='/login' element={<Login />} />
            <Route path='/register' element={<RegisterPage />} />
            <Route path='/register/plans' element={
              <ProtectedRoute isAuthorized={Object.keys(currentUser).length === 0? false: true} redirectPath="/register">
                <RegisterPlans />
              </ProtectedRoute>} 
            />
            <Route path='/register/plans/payment/:price' element={
              <ProtectedRoute isAuthorized={Object.keys(currentUser).length === 0 || currentUser.userForToken.isSubscribed? false: true} redirectPath="/register/plans/payment/failed">
                <RegisterPayment />
              </ProtectedRoute>} 
            />
            <Route path='/register/plans/payment/failed' element={<RegisterPaymentFailure />} />
            <Route path='/register/plans/payment/completion' element={<RegisterPaymentSuccess />} />
            <Route path='/register/complete/:token' element={<FinishRegistration />} />
            <Route path='/register/confirmation' element={<RegisterAwaitConfirmation />} />
            <Route path='/register/email-verification/:token' element={<RegisterEmailVerification />} />
            <Route path='/register/incomplete' element={<RegisterIncomplete />} />
            <Route path='/register/plans/trial/:price' element={<RegisterPaymentTrial />} />
            <Route path='/register/unverified' element={<FinishRegistrationFailed />} />

            <Route path='/profile' element={
              <ProtectedRoute isAuthorized={Object.keys(currentUser).length !== 0 && currentUser.userForToken.verified? true: false} redirectPath="/">
                <DashboardPage page="" />
              </ProtectedRoute>} 
            />
            <Route path='/profile/profile' element={
              <ProtectedRoute isAuthorized={Object.keys(currentUser).length !== 0 && currentUser.userForToken.verified? true: false} redirectPath="/">
                <DashboardPage page="profile" />
              </ProtectedRoute>}
            />
            <Route path='/profile/outlink-connections/:website' element={
              <ProtectedRoute isAuthorized={Object.keys(currentUser).length !== 0 && currentUser.userForToken.verified? true: false} redirectPath="/">
                <DashboardPage page="outlink-connections" />
              </ProtectedRoute>}
            />
            <Route path='/profile/backlink-connections/:website' element={
              <ProtectedRoute isAuthorized={Object.keys(currentUser).length !== 0 && currentUser.userForToken.verified? true: false} redirectPath="/">
                <DashboardPage page="backlink-connections" />
              </ProtectedRoute>}
            />
            <Route path='/profile/settings' element={
              <ProtectedRoute isAuthorized={Object.keys(currentUser).length !== 0 && currentUser.userForToken.verified? true: false} redirectPath="/">
                <DashboardPage page="settings" />
              </ProtectedRoute>}
            />
            <Route path='/profile/logs' element={
              <ProtectedRoute isAuthorized={Object.keys(currentUser).length !== 0 && currentUser.userForToken.verified? true: false} redirectPath="/">
                <DashboardPage page="logs" />
              </ProtectedRoute>}
            />
            {/* <Route path='/profile/billing' element={
              <ProtectedRoute isAuthorized={Object.keys(currentUser).length !== 0 && currentUser.userForToken.isSubscribed? true: false} redirectPath="/">
                <DashboardPage page="billing" />
              </ProtectedRoute>}
            /> */}

            {/* <Route path='/profile/billing/status' element={
              <ProtectedRoute isAuthorized={Object.keys(currentUser).length === 0? false: true} redirectPath="/">
                <BillingStatus />
              </ProtectedRoute>}
            /> */}

            <Route path='/profile/website-overview' element={
              <ProtectedRoute isAuthorized={Object.keys(currentUser).length !== 0 && currentUser.userForToken.verified? true: false} redirectPath="/">
                <DashboardPage page="website-overview" />
              </ProtectedRoute>}
            />
            <Route path='/profile/website-overview/:website' element={
              <ProtectedRoute isAuthorized={Object.keys(currentUser).length !== 0 && currentUser.userForToken.verified? true: false} redirectPath="/">
                <DashboardPage page="website-details" />
              </ProtectedRoute>}
            />

            <Route path='/contact' element={<Contact />} />
            <Route path='/product' element={<Product />} />
            <Route path='/company' element={<Company />} />

            <Route path='/profile/password-reset' element={<ForgotPassword />} />
            <Route path='/profile/password-reset/new/:id' element={<ForgotPasswordReset />} />

            <Route path='/admin' element={
              <ProtectedRoute isAuthorized={Object.keys(currentUser).length !== 0 && currentUser.userForToken.isAdmin? true: false} redirectPath='/'>
                <AdminPage />
              </ProtectedRoute>}             
            />

            <Route path='/plans' element={<SubscriptionPlans />} />

            <Route path='/support' element={<Support />} />
            <Route path='/support/:id' element={<SupportArticle />} />

            <Route path='/feedback' element={<Feedback />} />
            
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </div>
    </HelmetProvider>
  );
}

export default App;