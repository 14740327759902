import './common.css'

const Spinner = () =>{
    return(
        <div className="spinner-wrapper">
            <div className="spinner">
                <div className="dot1"></div>
                <div className="dot2"></div>
            </div>
        </div>
    )
}

export { Spinner }