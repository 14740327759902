import { Link } from 'react-router-dom'

import wave from '../../resources/svg/wave.svg'
import wave2 from '../../resources/svg/wave2.svg'

const About = () =>{
    return(
        <div className="about">
            <img id="about-wave-1" src={wave} alt="wave" />

            <div className="about-content">
                <h1>A fully integrated backlinks platform</h1>
                <div className="about-text">
                    <p>In the ever-evolving landscape of digital marketing, where search engine algorithms are continuously refining their criteria, gaining a competitive edge has never been more crucial. We are a trailblazing online platform meticulously crafted to facilitate meaningful connections among website owners. Our platform serves as the nexus where webmasters unite, exchange valuable backlinks, and collectively enhance their online prominence. This innovative system stands as a testament to the power of collaboration in the digital age, where the whole truly becomes greater than the sum of its parts</p>
                    <p>At the heart of LinkeBy lies the vision of connectivity. We recognize that website owners, regardless of their industry or niche, share a common goal—to improve their website's visibility in search engine results and, in turn, attract more engaged organic traffic. We act as the conduit that bridges the gap between these webmasters, empowering them to forge connections and form a vibrant community dedicated to mutual growth.</p>
                </div>
                <Link to="/about" className='about-link'>Find out more</Link>
            </div>

            <img id="about-wave-2" src={wave2} alt="wave2" />
        </div>
    )
}

export { About }