import { useNavigate } from "react-router-dom"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { setActiveButton } from "../../../../store/reducers/sidebarReducer"
import { setMobileSidebarIsOpen } from "../../../../store/reducers/sidebarReducer"

const ProfileSideBarOptionsButton = ({ icon, label, state, userConnections }) =>{
    // Store
    const currentUser = useSelector(({ currentUser }) => currentUser)
    const activeButton = useSelector(({ sidebar }) => sidebar.activeButton)
    const dispatch = useDispatch()

    // Router
    const navigate = useNavigate()

    // Local state
    const [notificationCount, setNotificationCount] = useState(0)

    // Handle link click
    const handleClick = () =>{
        // Set active button in store
        dispatch(setActiveButton(state))

        // Open mobile sidebar (if applicable)
        dispatch(setMobileSidebarIsOpen())

        // Navigate to requested link
        navigate(`/profile/${state}`)
    }

    // Compare connection timestamps with last time it was checked
    useEffect(()=>{
        if(state === "outlink-connections"){
            const filteredConnections = userConnections.filter( connection => connection.targetWebsite.owner === currentUser.userForToken.id && connection.approved === 'pending')
            setNotificationCount(filteredConnections.length)
        } else if (state === "backlink-connections"){
            const filteredConnections = userConnections.filter( connection => connection.sourceWebsite.owner === currentUser.userForToken.id && connection.approved === 'pending')
            setNotificationCount(filteredConnections.length)
        }
    }, [userConnections])

    return(
        <button 
            className={`${activeButton === state? "profile-sidebar-options-btn-active" : "profile-sidebar-options-btn"}`}
            onClick={handleClick}
        >
            <span className="profile-sidebar-options-btn-icon">{icon}</span>
            {label}
            {notificationCount > 0 && <div className="profile-sidebar-options-btn-notification">{notificationCount}</div>}
        </button>
    )
}   

export default ProfileSideBarOptionsButton