import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from "react-redux"
import './finish-registration.css'

import { initializeNiches } from "../../../store/reducers/nicheReducer"

// Assets
import logo from '../../../resources/logo-link.png'

// Components
import FinishRegistrationForm from './FinishRegistrationForm'
import { DashboardFooter } from "../../Footer/DashboardFooter"
import { Spinner } from "../../../common/Spinner"
import { FinishRegistrationFailed } from "./FinishRegistrationFailed"

const FinishRegistration = () =>{
    // Store
    const dispatch = useDispatch()
    const users = useSelector(({ users }) => users.users)
    const loading = useSelector(({ niches }) => niches.loading)
    const error = useSelector(({ niches }) => niches.error)

    // State
    const [userToBeUpdated, setUserToBeUpdated] = useState(null)

    // Retrieve id from params
    const token = useParams().token

    // Initialize niches for store
    useEffect(()=>{
        dispatch(initializeNiches())
    }, [dispatch])


    // Find user from params token
    useEffect(()=>{
        if(users){
            const foundUser = users.find( user => user.temporaryToken === token)
            setUserToBeUpdated(foundUser)
        }
    }, [users, token])

    // Handle errors and loading
    if(error) return <p>{error}</p>
    if (userToBeUpdated === undefined) return <FinishRegistrationFailed />
    if (userToBeUpdated === null || loading) return <Spinner />

    return(
        <div className="finish-registration">
            <Helmet>
                <title>Complete your profile</title>
                <meta name="description" content="Fill in the additional info to finish your registration" />
            </Helmet>
            
            <div className="register-content-wrapper">
                <img id='logo' src={logo} alt="logo" />
                <h5>Great, your account is almost complete</h5>  
                <p>Your email adress <span style={{ fontWeight: '600' }}>{userToBeUpdated.email}</span> was verified. Please tell us about your website.</p>

                <FinishRegistrationForm userToBeUpdated={userToBeUpdated} />

                <div className="register-terms">By signing up you agree to our terms of service and privacy policy.</div>
            </div>

            <DashboardFooter />
        </div>
    )
}

export default FinishRegistration