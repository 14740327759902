import { useState } from 'react'

// Icons
import {TbWorld} from 'react-icons/tb'
import {AiFillCopy} from 'react-icons/ai'
import {FiExternalLink} from 'react-icons/fi'
import {MdOutlineExpandMore} from 'react-icons/md'

// Components
import NewWebsitesSingleActions from './NewWebsitesSingleActions'

/**
 * New users single component
 */
const NewWebsitesSingle = ({website, setShowCommentForm, setWebsiteToComment}) =>
{
    // State
    const [showLinkOptions, setShowLinkOptions] = useState(false)
    const [showActions, setShowActions] = useState(false)

    if(!website.owner) return null

    return(
        <div className="new-users-single">
            <div className="new-users-single-content">
                <div className='new-users-single-link'>
                    <TbWorld className='new-users-icon' />
                    <a href='#' onMouseEnter={()=>{setShowLinkOptions(true)}} onMouseLeave={()=>{setShowLinkOptions(false)}}>{website.url}</a>

                    {showLinkOptions && 
                    <div className="new-users-single-link-options">
                        <button><FiExternalLink /></button>
                        <button><AiFillCopy /></button>
                    </div>}
                </div>

                <div>
                    <p style={{textTransform: 'capitalize'}}>{website.owner.name+ " "+website.owner.surname}</p>
                </div>
            
                <div>
                    <p>{website.owner.email}</p>
                </div>

                <div>
                    <span className={`${!website.owner.isNewlyRegistered? "new-user-single-status-green": "new-user-single-status-red"}`}></span>
                </div>

                <div className='new-users-single-actions-btn'><button onClick={()=>{setShowActions(!showActions)}}><MdOutlineExpandMore /></button></div>
            </div>

            {showActions && <NewWebsitesSingleActions website={website} setShowCommentForm={setShowCommentForm} setWebsiteToComment={setWebsiteToComment} />}
        </div>
    )
}

export default NewWebsitesSingle