import {AiOutlineCopyrightCircle} from 'react-icons/ai'

const Copyright = () =>{
    return(
        <div className="footer-bottom-copyright">
            <p>Copyright <AiOutlineCopyrightCircle /></p>
            <p>2022 Linkeby, All rights reserved</p>
        </div>
    )
}

export { Copyright }