import { useState } from 'react'
import { useSelector } from 'react-redux'

// Icons
import {IoIosArrowForward} from 'react-icons/io'

// Components
import ProfileWebsiteActionsDelete from './Actions/ProfileWebsiteActionsDelete'
import ProfileWebsiteLanguages from './Actions/Languages/ProfileWebsiteLanguages'
import ProfileWebsiteNiches from './Actions/Niches/ProfileWebsiteNiches'

/**
 * Single website component
 */
const ProfileWebsitesSingle = ({ website }) =>{      
    // Get currentuser from store
    const currentUser = useSelector(({ currentUser }) => currentUser)

    // Local state
    const [showOptions, setShowOptions] = useState(false)
    const [showNiches, setShowNiches] = useState(false)
    const [showLanguages, setShowLanguages] = useState(false)

    return(
        <div className="profile-website">
            <div className='profile-website-div'>
                <div className="profile-website-div-content">
                    <div className="profile-website-div-title">
                        <h4>Url of website</h4>
                    </div>
                    <a href={`http://${website.url}`} rel='noreferrer' target="_blank">{website.url}</a>
                    <div className='profile-website-div-options'>
                        <button className='profile-website-div-options-btn' onClick={()=>{setShowOptions(!showOptions)}}>Manage website <IoIosArrowForward className='profile-website-div-options-btn-icon' /></button>
                    </div>
                </div>

                {showOptions &&
                <div className="profile-website-div-expand">
                    <p className='profile-website-div-expand-p'>Remove this website. This will automatically remove everything linked with this website, such as connections and messages</p>
                    <ProfileWebsiteActionsDelete currentUser={currentUser} website={website} />
                </div>}
            </div>

            <div className='profile-website-div'>
                <div className={`${website.niche.length !== 0? "profile-website-div-content": "profile-website-div-content-missing"}`}>
                    <div className="profile-website-div-title">
                        <h4>Niche / category *</h4>
                        <small>This field is mandatory. You can add multiple niches for your website</small>
                    </div>
                    <div className='profile-website-div-languages'>
                        {website.niche? website.niche.map((niche, i) => 
                            <div key={i}>{niche}</div>) : "-"
                        }
                    </div>
                    <div className='profile-website-div-options'>
                        <button className='profile-website-div-add-field-btn' onClick={()=>{setShowNiches(true)}}>{!website.niche.length !== 0? "Add +": "Edit"}</button>
                    </div>
                </div>
            </div>
                
            <div className='profile-website-div'>
                <div className={`${website.languageOfWebsite.length !== 0? "profile-website-div-content": "profile-website-div-content-missing"}`}>
                    <div className="profile-website-div-title">
                        <h4>Language of website *</h4>
                    <small>This field is mandatory. You can add multiple languages for your website</small>
                    </div>
                    <div className='profile-website-div-languages'>
                        {website.languageOfWebsite? website.languageOfWebsite.map((lang, i) => 
                            <div key={i}>{lang}</div>) : "-"
                        }
                    </div>
                    <div className='profile-website-div-options'>
                        <button className='profile-website-div-add-field-btn' onClick={()=>{setShowLanguages(true)}}>{website.languageOfWebsite.length === 0? "Add +": "Edit"}</button>
                    </div>
                </div>
            </div>
                
            {/* Pop up forms */}
            {showNiches && <ProfileWebsiteNiches website={website} setShowNiches={setShowNiches} />}
            {showLanguages && <ProfileWebsiteLanguages website={website} setShowLanguages={setShowLanguages} />}
        </div>
    )
}

export default ProfileWebsitesSingle