import { createSlice } from '@reduxjs/toolkit'
import registerService from '../../services/registerService'

const initialState = {
    users: [],
    loading: false,
    error: null
}

const userSlice = createSlice({
    name: 'users',
    initialState,
    reducers:{
        setUsers(state, action){
            state.users = action.payload
        },
        setLoading(state, action){
            state.loading = action.payload
        },
        setError(state, action){
            state.error = action.payload
        }
    }
})

export const { setUsers, setLoading, setError } = userSlice.actions

export const initializeUsers = () =>{
    return async dispatch => {
        dispatch(setLoading(true))

        try {
            const response = await registerService.getAll()
            dispatch(setUsers(response.data))
            dispatch(setLoading(false))

            console.log("RAn")
        } catch (error) {
            dispatch(setError(error.messsage))
        }
    }
}

export default userSlice.reducer
