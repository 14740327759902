import { useSelector, useDispatch } from "react-redux"
import { getFirstLetter } from "../../../helpers/helpers"
import { setShowChat } from "../../../store/reducers/backlinksReducer"

const NavUserProfile = ({  showOptions, setShowOptions }) =>{
    // Store
    const dispatch = useDispatch()
    const currentUser = useSelector(({ currentUser }) => currentUser )

    // Handle clicking the user profile icon
    const handleClick = () =>{
        setShowOptions(!showOptions)
        dispatch(setShowChat(false))
    }

    return(
        <div className="nav-user-profile" onClick={handleClick}>
            <p>{currentUser && getFirstLetter(currentUser.userForToken.name)}</p>
        </div>
    )
}

export { NavUserProfile }