import { useEffect, useState } from "react";
import './backlinks-table-items-pending.css'

import useFilterPendingConnections from "../../../../../hooks/backlinks/useFilterPendingConnections";

import BacklinksTableItemSinglePendingButtons from "./BacklinksTableItemSinglePendingButtons";

export default function BacklinksTableItemSinglePending ({ role, setShowPendingModal }){
    // State
    const [showModal, setShowModal] = useState(true)

    // Get pending connections count from custom hook
    const [notApprovedConnectionsCount] = useFilterPendingConnections(role)

    // Get user preference for showing this modal
    useEffect(()=>{
        // Get preferences from localstorage
        const preferences = JSON.parse(localStorage.getItem('userPreferences'))

        // If "showBacklinksPendingConnectionsMessage" is saved to true, hide the modal
        if(preferences && preferences.showBacklinksPendingConnectionsMessage){
            setShowModal(false)
        }
    }, [])

    // If user clicks close button, do not render
    if(!showModal) return null

    // If there are no non-approved connections, do not render
    if(notApprovedConnectionsCount === 0) return null

    return(
        <div className="backlinks-table-items-single-pending">
            <p>
                <span>{notApprovedConnectionsCount} connection(s)</span> {" "}
                have not been approved yet. You will be able to see the details when they are approved.{" "}
                <button className="backlinks-table-items-single-pending-link" onClick={()=>{ setShowPendingModal(true) }}>Read more</button>
            </p>

            <BacklinksTableItemSinglePendingButtons setShowModal={setShowModal} />
        </div>
    )
}