import useFilterPendingConnections from "../../../../hooks/backlinks/useFilterPendingConnections"

export default function BacklinksTableNavOptionsPendingMessage ({ role }){
    // Get pending connections count from custom hook
    const [notApprovedConnectionsCount] = useFilterPendingConnections(role)

    // If role is target (for outlinks), or there are 0 pending connections do not render
    if(role === 'target' || notApprovedConnectionsCount === 0) return null

    return(
        <div id="backlinks-table-nav-options-pending-message">({notApprovedConnectionsCount} pending approval)</div>
    )
}