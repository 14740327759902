import { useState } from 'react'

import {BsSearch} from 'react-icons/bs'
import {AiOutlineCloseCircle} from 'react-icons/ai'


const Search = ({ array, filterFunction, setFilteredArray }) =>{
    // Local state
    const [input, setInput] = useState("")

    const handleChange = (e) =>{
        // Filter results based on input, using the filter function passed from parent
        const filterResult = filterFunction(e)

        // Update input
        setInput(e.target.value)

        // Update the array to diplay in parent component
        setFilteredArray([...filterResult])
    }

    // Handle clear button
    const handleClearButton = (e) =>{
        e.preventDefault()
        setInput("")

        // Reset array to display as original
        setFilteredArray(array)
    }

    return(
        <div className="search">
            <BsSearch />
            <input 
                type="text" 
                placeholder="Search"
                value={input}
                onChange={ handleChange }
            />
            <button onClick={ handleClearButton }><AiOutlineCloseCircle /></button>
        </div>
    )
}

export { Search }