import { createSlice } from "@reduxjs/toolkit";
import languagesService from '../../services/languagesService'

const initialState = {
    languages: [],
    loading: false,
    error: null
}

const languagesSlice = createSlice({
    name: "languages",
    initialState,
    reducers:{
        setLanguages(state, action){
            state.languages = action.payload
        },
        setLoading(state, action){
            state.loading = action.payload
        },
        setError(state, action){
            state.error = action.payload
        }
    }
})

export const { setLanguages, setLoading, setError } = languagesSlice.actions

// Get all links in database
export const initializeLanguages = () =>{
    return async dispatch =>{
        dispatch(setLoading(true))

        try {
            const response = await languagesService.getAll()
            dispatch(setLanguages(response.data))
            dispatch(setLoading(false))
        } catch (error) {
            dispatch(setError(error.message))
        }
    }
}

export default languagesSlice.reducer