import { useParams } from "react-router-dom"
import { Helmet } from "react-helmet-async"
import './waitlist-complete.css'

import logo from '../../resources/logo-link.png'

import WaitlistCompleteForm from "../../components/Waitlist/WaitlistCompleteForm/WaitlistCompleteForm"

export default function WaitlistComplete (){
    // Get email from params
    const email = useParams().email
    
    return(
        <div className="wailist-complete">
            <Helmet>
                <title>Linkeby | Join Linkeby</title>
                <meta name="description" content="Start using Linkeby for free" />
            </Helmet>

            <img src={logo} alt="logo" />

            <h1>Join Linkeby</h1>
            <p>Our app is now ready! Finish up the details and start elevating your website.</p>

            <WaitlistCompleteForm email={email} />
        </div>
    )
}