import axios from "axios";
const baseUrl = `${process.env.REACT_APP_API_BASE_URL}messages`

const getConversationMessages = (id, config) => axios.get(`${baseUrl}/conversation/${id}`, config)

const getUserMessages = (id, config) => axios.get(`${baseUrl}/user/${id}`, config)

const getNewMessagesCount = (id, config) => axios.get(`${baseUrl}/conversation-new-messages/${id}`, config)

const postFirsStageAutomated = (role, data, config) => axios.post(`${baseUrl}/first-stage-automated/${role}`, data, config)

const postFirstStageMessage = (role, connection, data, config) => axios.post(`${baseUrl}/first-stage/${role}/${connection}`, data, config)

const postMessage = (data, config) => axios.post(baseUrl, data, config)

const deleteMessage = (id, config) => axios.delete(`${baseUrl}/${id}`, config)

const updateMessagesAsOpened = (conversationId, data, config) => axios.put(`${baseUrl}/set-messages-opened/${conversationId}`, data, config)

const messagesService = { 
    getConversationMessages, 
    getUserMessages, 
    getNewMessagesCount,
    postFirsStageAutomated,
    postFirstStageMessage,
    postMessage, 
    deleteMessage, 
    updateMessagesAsOpened 
}

export default messagesService