const TokenExpiredMessage = () =>{
    return(
        <div className="token-expired-message">
            <div>
                <p>Token has expired. Try logging in again</p>
            </div>
        </div>
    )
}

export default TokenExpiredMessage