import './forms.css'

const FormInputText = ({icon, placeholder, inputValue, setInputValue}) =>{
    return(
        <div className="form-input-text">
            {icon}
            <input 
                type="text" 
                placeholder={placeholder} 
                value={inputValue} 
                onChange={({target})=>{setInputValue(target.value)}}
                required 
            />
        </div>
    )
}

export default FormInputText