import { useDispatch, useSelector } from 'react-redux'
import { setError } from '../../store/reducers/errorReducer'
import './error.css'

import { BiErrorCircle } from 'react-icons/bi'
import { AiOutlineClose } from 'react-icons/ai' 

const ErrorToast = () =>{
    // Store 
    const dispatch = useDispatch()
    const error = useSelector(({ error }) => error)

    const handleResetError = () =>{
        dispatch(setError(null))
    }

    if(!error) return null

    return(
        <div className="toast">
            <span className='toast-line' style={{background: 'red'}}></span>

            <div className='toast-icon' style={{color: 'red'}}><BiErrorCircle /></div>

            <div className="toast-text">
                <p>Something unexpected happened</p>
                <p>{error}</p>
            </div>

            <button onClick={handleResetError}><AiOutlineClose /></button>
        </div>
    )
}

export default ErrorToast