import { BiError } from 'react-icons/bi'

const ErrorMessageChat = ({ error }) =>{
    return(
        <div className="error-chat">
            <BiError id='error-icon' />
            <p style={{ color: '#ff3333' }}>Something undexpected occured: {error}</p>
        </div>
    )
}

export { ErrorMessageChat }