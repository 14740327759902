import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import './register-incomplete.css'

const RegisterIncomplete = () =>{
    const currentUser = useSelector(({ currentUser }) => currentUser)

    return(
        <div className="register-incomplete">
            <h1>Oops, your registration has not been finilized</h1>
            <p>It seems like your registration for <span>{currentUser.userForToken.email}</span> was not completed 
            successfully as your subscription payment is pending. In order to gain access to our app you need to finish your subscription.
            </p>
            <Link to="/register/plans" className='register-incomplete-link'>Go to payment</Link>
        </div>
    )
}

export { RegisterIncomplete }