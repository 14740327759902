import { useState } from 'react'
import './billing-overview.css'

import { BillingOverviewPlan } from './BillingOverviewPlan'
import { BillingOverviewPaymentMethod } from './Payment/BillingOverviewPaymentMethod'
import { BillingOverviewPaymentMethodAdd } from './Payment/BillingOverviewPaymentMethodAdd'

const BillingOverview = () =>{
    // Local state
    const [showAddPayment, setShowAddPayment] = useState(false)

    return(
        <div className="billing-overview">
            <BillingOverviewPlan  />
            <BillingOverviewPaymentMethod setShowAddPayment={setShowAddPayment} />
            {showAddPayment && <BillingOverviewPaymentMethodAdd setShowAddPayment={setShowAddPayment} />}
        </div>
    )
}

export { BillingOverview }