import './company.css'

import Nav from "../../components/Nav/Nav"
import Footer from '../../components/Footer/Footer'

const Company = () =>{
    return(
        <div className="company">
            <Nav />
            <section className="company-hero">
                <div className="company-hero-content">
                    <h1>We are Linkeby</h1>
                    <p>We believe in the power of collaboration and mutual growth. We understand that in the dynamic world of online presence, building and maintaining a strong digital footprint is essential. That's why we've created a platform that empowers website owners and digital marketers to connect, collaborate, and boost their online visibility through backlink exchanges.</p>
                </div>
            </section>

            <div className='company-mission'>
                <div>
                    <div></div>
                    <div>
                        <h2>Our mission</h2>
                        <p>Our mission is simple: to help businesses and individuals thrive in the digital realm. We understand that success online is often about who you know and who knows you. Backlinks play a crucial role in improving your website's search engine ranking and driving organic traffic. With Linkeby, we aim to make this process seamless and effective.</p>
                    </div>
                </div>
            </div>

            <div className="company-content">
                <h2>Why Choose Linkeby?</h2>
                <div>
                    <div>
                        <h5>Community-Driven</h5>
                        <p>We're more than just a platform; we're a community of like-minded individuals and businesses looking to support each other's growth. When you join [Your Startup Name], you become part of a network dedicated to success.</p>
                    </div>
                    <div>
                        <h5>Quality Control</h5>
                        <p>We prioritize quality over quantity. Our platform ensures that you connect with partners who are relevant to your niche, which means more relevant and effective backlinks for your website.</p>
                    </div>
                    <div>
                        <h5>Easy to Use</h5>
                        <p>We've designed our platform to be user-friendly and intuitive. You don't need to be a tech expert to get started. Whether you're a seasoned digital marketer or new to the world of SEO, [Your Startup Name] has you covered.</p>
                    </div>
                    <div>
                        <h5>Safe and Secure</h5>
                        <p>We take privacy and security seriously. Your data and personal information are protected, and we've implemented measures to prevent spam and ensure a safe environment for backlink exchanges.</p>
                    </div>
                </div>
            </div>

            <div className="company-join">
                <h5>Join Our Community Today</h5>
                <p>Ready to take your online presence to the next level? Join the Linkeby community and start reaping the benefits of strategic backlink exchanges. Whether you're looking to boost your SEO, increase website traffic, or simply connect with others in your industry, we're here to help.
                Our team is dedicated to providing you with the tools and support you need to succeed online. Let's grow together!
                If you have any questions or want to get in touch, feel free to reach out to us at [Contact Information].</p>
            </div>

            <Footer />
        </div>
    )
}

export { Company }