import { useState } from "react"
import { useStripe, useElements, PaymentElement, AddressElement } from "@stripe/react-stripe-js"
import useCopyToClipboard from "../../../hooks/useCopyToClipboard"

import { AiFillInfoCircle } from 'react-icons/ai'

import SuccessToast from "../../Popups/SuccessToast"

const RegisterPaymentForm = () =>{
    // Stripe
    const stripe = useStripe()
    const elements = useElements()

    // Local state
    const [message, setMessage] = useState(null)
    const [isProcessing, setIsProcessing] = useState(false)
    const [textIsCopied, setTextIsCopied] = useState(false)

    // Hooks
    const [value, copy] = useCopyToClipboard()

    // Handle copying text
    const handleCopyText = () =>{
        // Run hook to copy text
        copy("4242 4242 4242 4242")

        // Show success toast and hide it again
        setTextIsCopied(true)
        
        setTimeout(() => {
            setTextIsCopied(false)
        }, 2000);
    }

    // Handle form submission
    const handleSubmit = async (e) =>{
        e.preventDefault()

        // Check if stripe and elements are initialised
        if(!stripe || !elements){
            return;
        }

        setIsProcessing(true)

        // Handle payment
        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: `${window.location.origin}/register/plans/payment/completion`
            }
        })

        // Set error if error occurs
        if(error){
            setMessage(error.message)
        } else{
            setMessage("Unexpected state")
        }

        setIsProcessing(false)
    }

    return(
        <div className="checkout-form">    

            {/* Warning info for test users */}
            <div className="checkout-form-warning">
                <div><AiFillInfoCircle /></div>
                <p>Please use testing credentials to complete your registration. For the card number you can 
                use <button id="copy-btn" onClick={handleCopyText}>4242 4242 4242 4242</button>. Use 
                <span> any valid date for the expiry</span> and <span>any digits for the CVC</span>.
                </p>
            </div>

            {/* Payment method form */}
            <form onSubmit={handleSubmit}>   
                <div className="checkout-form-wrapper">
                    <h4>Billing details</h4>
                    <AddressElement options={{mode: 'shipping'}} />
                </div>

                <div className="checkout-form-wrapper">
                    <h4>Payment details</h4>
                    <PaymentElement />
                </div>          

                <button type="submit" className="checkout-form-submit-btn" disabled={isProcessing}>
                    <span>
                        {isProcessing? "Processing...": "Pay"}
                    </span>
                </button>

                <small style={{ color: "#ff3333" }}>{message}</small>
            </form>

            {/* Success toast for copying a text to clipboard */}
            {textIsCopied && <SuccessToast title="Text copied" subtitle="The selected text was successfully copied to clipboard." setSuccess={setTextIsCopied} />}
        </div>
    )
}

export { RegisterPaymentForm }