import { useMemo } from 'react'
import { getLastWeekStats } from '../../../../helpers/getLastWeekStats'

import ProfileLandingChartBar from '../ProfileLandingCharts/ProfileLandingChartBar'

const ProfileLandingStatsSingle = ({ data, title, text }) =>{
    // Get time now, one-week mark & Timestamp of one week ago
    const oneWeek = 1000 * 60 * 60 * 24 * 7
    const now = new Date().getTime()  

    // Get array with daily stats for last week
    const countsArray= getLastWeekStats(data)

    const recentEntries = useMemo(()=>{
        // Filter the users that were created less than a week ago
        const filteredArray = data.filter( d => (now - new Date(d.createdAt).getTime()) < oneWeek)

        return filteredArray.length
    }, [data, now])

    return(
        <div className="profile-landing-stats-single">
            <div className='profile-landing-stats-single-top'>
                <div className='profile-landing-stats-single-top-content'>
                    <h1>{title}</h1>
                    <p><span>{data.length}</span></p>
                </div>

                <ProfileLandingChartBar statsData={countsArray} />
            </div>
            
            <small>Last week, <span>{recentEntries}</span> new {text}</small>
        </div>
    )
}

export default ProfileLandingStatsSingle