import { useSelector } from "react-redux"
import { useState, useEffect } from "react"

function useFilterSidebarConnections(){
    // Store
    const connections = useSelector(({ connections }) => connections.allConnections)
    const currentUser = useSelector(({ currentUser }) => currentUser)

    // State
    const [userConnections, setUserConnections] = useState([])

    useEffect(()=>{
        const filteredConnections = connections.filter( connection =>
            (connection.targetWebsite?.owner === currentUser.userForToken.id) ||
            (connection.sourceWebsite?.owner === currentUser.userForToken.id)
        )

        setUserConnections([...filteredConnections])
    }, [connections])

    return userConnections
}

export default useFilterSidebarConnections