import { createSlice } from "@reduxjs/toolkit";
import connectionService from "../../services/connectionService";

const initialState = {
    allConnections: [],
    websiteConnections: [],
    loading: false,
    websiteConnectionsLoading: false,
    error: null,
    updateConnectioError: null,
    websiteConnectionsError: null,
    deleteConnectionError: null,
    approveConnectionError: null
}

const connectionSlice = createSlice({
    name: 'connections',
    initialState,
    reducers:{
        setConnections (state, action){
            state.allConnections = action.payload
        },
        setWebsiteConnections (state, action){
            state.websiteConnections = action.payload
        },
        createConnection (state, action){
            state.websiteConnections = [...state.websiteConnections, action.payload]
        },
        approveConnection (state, action){
            const newState = state.websiteConnections.map( 
                connection => connection.id === action.payload.id? action.payload: connection
            )
            state.websiteConnections = newState
        },
        updateConnection(state, action){
            const newState = state.websiteConnections.map( 
                connection => connection.id === action.payload.id? action.payload: connection
            )
            state.websiteConnections = newState
        },
        deleteConnection (state, action){
            state.websiteConnections = state.websiteConnections.filter( connection => connection.id !== action.payload )
        },
        setLoading (state, action){
            state.loading = action.payload
        },
        setWebsiteConnectionsLoading (state, action){
            state.websiteConnectionsLoading = action.payload
        },
        setError(state, action) {
            state.error = action.payload
        },
        setUpdateConnectionError(state, action){
            state.updateConnectioError = action.payload
        },
        setWebsiteConnectionsError(state, action) {
            state.websiteConnectionsError = action.payload
        },
        setDeleteConnectionError(state, action){
            state.deleteConnectionError = action.payload
        },
        setApproveConnectionError(state, action){
            state.approveConnectionError = action.payload
        }
    }
})

export const { 
    setConnections, 
    setWebsiteConnections, 
    createConnection, 
    approveConnection, 
    updateConnection,
    deleteConnection, 
    setLoading, 
    setWebsiteConnectionsLoading, 
    setError,
    setWebsiteConnectionsError,
    setUpdateConnectionError,
    setDeleteConnectionError,
    setApproveConnectionError
} = connectionSlice.actions

// Initialize all connections
export const initializeConnections = () =>{
    return async dispatch => {
        dispatch(setLoading(true))

        try {
            const response = await connectionService.getAll()
            dispatch(setConnections(response.data))
            dispatch(setLoading(false))

        } catch (error) {
            dispatch(setError(error.message))
        }
    }
}

// Initialize website connections
export const initializeWebsiteConnections = (id, role, config) =>{
    return async dispatch => {
        dispatch(setWebsiteConnectionsLoading(true))

        try {
            const response = await connectionService.getWebsiteConnections(id, role, config)
            dispatch(setWebsiteConnections(response.data))
            dispatch(setWebsiteConnectionsLoading(false))
        } catch (error) {
            dispatch(setWebsiteConnectionsError(error.message))
        }
    }
}

// Create new connection
export const postNewConnection = (data, config) =>{
    return async dispatch => {
        try {
            const response = await connectionService.postConnection(data, config)
            dispatch(createConnection(response.data))
            console.log("Connection created")
        } catch (error) {
            dispatch(setError(error.message))
        }
    }
}

// Approve connection
export const handleApproveConnection = (id, data, config) =>{
    return async dispatch => {
        try {
            const response = await connectionService.approveConnection(id, data, config)
            dispatch(approveConnection(response.data))
        } catch (error) {
            dispatch(setApproveConnectionError(error.message))
        }
    }
}

// Update a connection with any data
export const handleUpdateConnection = (id, data, config) =>{    
    return async dispatch => {
        try {
            const response = await connectionService.updateConnection(id, data, config)
            dispatch(updateConnection(response.data))
        } catch (error) {
            dispatch(setUpdateConnectionError(error.message))
        }
    }
}

// Delete / reject a connection
export const handleDeleteConnection = (connectionId, config) =>{
    return async dispatch =>{
        try {
            await connectionService.deleteConnection(connectionId, config)
            dispatch(deleteConnection(connectionId))
        } catch (error) {
            dispatch(setDeleteConnectionError(error.message))
        }
    }
}

export default connectionSlice.reducer