import { useRef } from 'react'

import {AiOutlineEyeInvisible} from 'react-icons/ai'
import {AiOutlineEye} from 'react-icons/ai'
import {RiLockPasswordFill} from 'react-icons/ri'

const LoginPassword = ({password, setPassword, showPass, setShowPass}) =>
{    
    // Ref for password input
    const inputRef = useRef()

    // Handle button click
    const handleClick = (e) =>{
        e.preventDefault()

        // Change state for showing password
        setShowPass(!showPass)

        // Change the input type according to state
        if(!showPass){
            inputRef.current.type = 'text'
        } else{
            inputRef.current.type = 'password'
        }
    }

    return(
        <div className='login-input-wrapper'>
            <RiLockPasswordFill className='login-icons' />
                
            <input 
                ref={inputRef}
                className='login-password-input'
                type="password" 
                placeholder='Password' 
                value={password} 
                onChange={({target})=>{setPassword(target.value)}}
            />

            <button 
                className="show-password-btn"
                onClick={handleClick}
            >
            {showPass? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
            </button>
        </div>
        
    )
}   

export default LoginPassword