import { useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { gsap } from 'gsap'
import './profile-sidebar-mobile.css'

// Hooks
import useFilterSidebarConnections from '../../../../hooks/sidebar/useFilterSidebarConnections'

import { setMobileSidebarIsOpen } from '../../../../store/reducers/sidebarReducer'

// Components
import ProfileSideBarOptionsButton from '../Options/ProfileSidebarOptionsButton'
import { ProfileSidebarOptionsDropdown } from '../Options/Dropdown/ProfileSidebarOptionsDropdown'

// import icons
import {BiHelpCircle} from 'react-icons/bi'
import {FiSettings} from 'react-icons/fi'
import { AiOutlineLink } from 'react-icons/ai'
import { BsLink } from 'react-icons/bs'
import { MdOutlineDashboard } from 'react-icons/md'
import { FaGripLines } from 'react-icons/fa'

const ProfileSidebarMobile = () =>{
    // Store
    const dispatch = useDispatch()

    // Get connections count to show in notifications, from custom hook
    const userConnections = useFilterSidebarConnections()

    // Refs
    const ref = useRef()
    const menuRef = useRef(null)

    // Animate sidebar on render
    useEffect(()=>{
        if(menuRef.current){
            gsap.fromTo(menuRef.current, {y: 200}, {duration:0.2, y:0})
        }
    }, [])

    return(
        <div className="profile-sidebar-mobile" ref={ref}>
            <div className="profile-sidebar-mobile-btns" ref={menuRef}>
                <button 
                    className='profile-sidebar-mobile-close-btn' 
                    onClick={()=>{dispatch(setMobileSidebarIsOpen(false))}}> 
                    <FaGripLines />
                </button>

                <ProfileSideBarOptionsButton 
                    icon={<MdOutlineDashboard className='profile-sidebar-icons' />}
                    label="Dashboard"
                    state=""
                    userConnections={userConnections}
                />

               <ProfileSidebarOptionsDropdown
                    icon={<BsLink className='profile-sidebar-icons'  />}
                    title="Backlinks"
                    state="backlink-connections"
                    userConnections={userConnections}
                />

                <ProfileSidebarOptionsDropdown
                    icon={<AiOutlineLink className='profile-sidebar-icons'  />}
                    title="Outlinks"
                    state="outlink-connections"
                    userConnections={userConnections}
                />
            
                <ProfileSideBarOptionsButton 
                    icon={<FiSettings className='profile-sidebar-icons' />}
                    label="Settings"
                    state="settings"
                />
                
                <Link to="/support" className='profile-sidebar-options-btn'>
                    <span className="profile-sidebar-options-btn-icon"><BiHelpCircle className='profile-sidebar-icons'  /></span>
                    Support
                </Link>
            </div>
        </div>
    )
}

export { ProfileSidebarMobile }