import useWaitlistSubmission from '../../../hooks/waitlist/useWaitlistSubmission'

// Components
import FormInputEmail from "../../Forms/FormInputEmail"

// Icons
import { MdAlternateEmail } from 'react-icons/md'
import { FaCheck } from "react-icons/fa";

export default function HeroWaitlist (){ 
    // Hook for submitting waitlist form
    const { email, setEmail, setEmailIsValid, success, error, validationError, handleSubmit } = useWaitlistSubmission()

    return(
        <form id="hero-waitlist-form" onSubmit={handleSubmit}>
            <div>
                <FormInputEmail
                    icon={<MdAlternateEmail />} 
                    placeholder="Enter your email" 
                    inputValue={email} 
                    setInputValue={setEmail} 
                    setEmailIsValid={setEmailIsValid}
                />

                <button type="submit">Get early access</button>

                {/* Success and error messages */}
                {success? <span id="hero-waitlist-form-success"><FaCheck /></span>: ""}
            </div>

            {/* Messages for failing email and errors  */}
            {error? <span id="hero-waitlist-form-error">{error}</span>: ""}
            {validationError? <span id="hero-waitlist-email-error">{validationError}</span>: ""}

            <p>Join 100+ others on the waitlist.</p>
        </form>
    )
}