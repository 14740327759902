import { useParams } from 'react-router-dom'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import './support-articles.css'
import Nav from '../../Nav/Nav'

const SupportArticle = () =>{
    // Store 
    const articles = useSelector(({helpArticles}) => helpArticles.helpArticles)

    // Retrieve id from params
    const id = useParams().id

    // Find article that matches id
    const article = useMemo(()=>{
        return articles.find( a => a.id === id)
    }, [id])

    return(
        <div className="support-articles">
            <Nav />
            
            <div className="support-articles-content">
                <h1>{article.title}</h1>
                {article.content.map( (a, i) => <p key={i}>{a}</p> )}
            </div>

            <div className="support-articles-contact">
                <p>Can't find what you are looking for? <button>Contact us</button> us instead.</p>
            </div>
        </div>
    )
}

export default SupportArticle