import { useState } from "react"
import { AddressElement, PaymentElement, useStripe, useElements } from "@stripe/react-stripe-js"

const BillingOverviewPaymentMethodAddForm = () =>{
    const stripe = useStripe()
    const elements = useElements()

    // Local state
    const [isProcessing, setIsProcessing] = useState(false)
    const [errorMessage, setErrorMessage] = useState("")

    // Handle form submit
    const handleSubmit = async (e) =>{
        e.preventDefault()

        if(!stripe || !elements){
            return;
        }

        setIsProcessing(true)

        const { error } = await stripe.confirmSetup({
            elements,
            confirmParams: {
                return_url: `${window.location.origin}/profile/billing/status`
            }
        })

        if (error){
            setErrorMessage(error)
        } else {
            setErrorMessage("OK")
        }

        setIsProcessing(false)
    }

    return(
        <form id="billing-add-payment-form" onSubmit={handleSubmit}>            
            <PaymentElement />

            <button 
                type="submit" 
                id="billing-add-payment-form-btn" 
                className="primary-btn" 
                disabled={isProcessing}
            >
                Confirm
            </button>

            <small>{errorMessage}</small>
        </form>
    )
}

export { BillingOverviewPaymentMethodAddForm }