import './popups.css'

const ErrorMessage = ({message}) =>
{
    return(
        <div className="error-message">
            <p className='error-p'>{message}</p>
        </div>
    )
}

export default ErrorMessage