import { useSelector } from "react-redux"

const BacklinksMessagesSingleTextsAutomated = ({ message }) =>{
    // Store
    const currentUser = useSelector( state => state.currentUser )

    // If the sender of automated message is not the currentuser do not render
    if(message.sender.id !== currentUser.userForToken.id) return null

    return(
        <div className="backlink-messages-automated">
            <div className='backlink-messages-automated-icon'>L</div>
            <div className='backlink-messages-automated-message'>
                <p>{message.text}</p>
            </div>
        </div>
    )
}

export { BacklinksMessagesSingleTextsAutomated }