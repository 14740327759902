import { useRef, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import gsap from "gsap"

import BacklinkOptionsTitle from '../BacklinkOptions/BacklinkOptionsTitle'
import OutlinkOptionsForm from "./Form/OutlinkOptionsForm"
import BacklinkOptionsCollapsed from "../BacklinkOptions/BacklinkOptionsCollapsed"

export default function OutlinkOptions (){
    // Refs
    const wrapperRef = useRef()

    // Store
    const showChat = useSelector(({ backlinks }) => backlinks.showChat)
    const activeConnection = useSelector(({ backlinks }) => backlinks.activeConnection)

    // State
    const [showBacklinkSettings, setShowBacklinkSettings] = useState(true)

    // Animate container on render
    useEffect(()=>{
        const animation = gsap.fromTo(wrapperRef.current, { scaleY: 0 }, { scaleY: 1, duration: 0.1 })

        return () => {
            animation.kill()
        }
    }, [showChat])

    // If connection has been agreed and finalised, do not render
    // if(activeConnection?.approved === "finalised") return null

    // If the chat is not open, do not render this component
    if(!showChat) return null

    // If user selects to hide this, show collapsed button instead
    if(!showBacklinkSettings) return <BacklinkOptionsCollapsed setShowBacklinkSettings={setShowBacklinkSettings} />

    return(
        <div className='backlink-options' ref={wrapperRef}>
            <BacklinkOptionsTitle 
                title="Outlink details" 
                text="Suggest your preferred options for this backlink. Your match will be able to see your preferences."
                setShowBacklinkSettings={setShowBacklinkSettings} 
                wrapperRef={wrapperRef}
            />

            <div className="backlink-options-content">
                <div className="backlink-options-form-wrapper" style={{ width: '100%' }}>
                    <OutlinkOptionsForm />
                </div>
            </div> 
        </div>
    )
}