import React from 'react';
import ReactDOM from 'react-dom/client';
import store from './store/store'
import { Provider } from 'react-redux';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

const renderApp = () =>{
  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  );
}
renderApp()

store.subscribe(renderApp)