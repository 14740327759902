import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import './profile-landing-activity.css'

// Icons
import {IoIosArrowForward} from 'react-icons/io'

// Components
import ProfileLandingActivitySingle from './ProfileLandingActivitySingle'

const ProfileLandingActivity = ({ links }) =>{   
    // Get user websites from store
    const websites = useSelector(({ websites }) => websites.userWebsites )

    return(
        <div className="profile-landing-activity">
            <h1>Your website activity ({websites.length})</h1>
            
            <div className="profile-landing-activity-websites">
                {websites.map( website =>
                    <ProfileLandingActivitySingle key={website.id} website={website} links={links} />
                )}
            </div>
                
            <div className='profile-landing-activity-desc'>
                <p>View and manage all your backlink and outlink connections.
                </p>
                <Link to="/profile/website-overview" className='profile-landing-activity-btn'>Manage websites <IoIosArrowForward /></Link>
            </div>   
        </div>
    )
}

export default ProfileLandingActivity