import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const BacklinksTableSkeleton = () =>{
    return(
        <SkeletonTheme>
            <div className='backlinks-table'>
                <div className='backlinks-table-nav'>
                    <div className="backlinks-table-nav-options">
                        <div><Skeleton width={80} height={20} /></div>
                        <div><Skeleton width={80} height={20} /></div>
                        <div><Skeleton width={90} height={20} /></div>
                        <div><Skeleton width={90} height={20} /></div>
                    </div>
                    <div className="backlinks-table-nav-websites"><Skeleton width={130} height={30} /></div>
                </div>

                <div className='backlinks-table-items-wrapper'>
                    <div><Skeleton width="100%" height={30} /></div>
                    <div><Skeleton width="100%" height={30} /></div>
                    <div><Skeleton width="100%" height={30} /></div>
                    <div><Skeleton width="100%" height={30} /></div>
                    <div><Skeleton width="100%" height={30} /></div>
                    <div><Skeleton width="100%" height={30} /></div>
                    <div><Skeleton width="100%" height={30} /></div>
                    <div><Skeleton width="100%" height={30} /></div>
                    <div><Skeleton width="100%" height={30} /></div>
                    <div><Skeleton width="100%" height={30} /></div>
                </div>
            </div>
        </SkeletonTheme>
    )
}

export { BacklinksTableSkeleton }