import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useSelector, useDispatch } from 'react-redux'
import { setUsers } from '../../store/reducers/userReducer'

import registerService from '../../services/registerService'
import websiteService from '../../services/websiteService'
import emailService from '../../services/emailService'


// Import Icons
import { CgProfile } from 'react-icons/cg'
import {TbWorld} from 'react-icons/tb'

// Import components
import FormInputText from '../Forms/FormInputText'
import ErrorMessage from '../Popups/ErrorMessage'
import { RegisterFormEmailInput } from './RegisterFormEmailInput'
import FormInputPassword from '../Forms/FormInputPassword'


const RegisterForm = () =>{
    // Get users from store
    const dispatch = useDispatch()
    const niches = useSelector(({ niches }) => niches.niches)

    // Navigate
    const navigate = useNavigate()

    // State for input and components
    const [name, setName] = useState("")
    const [surname, setSurname] = useState("")
    const [email, setEmail] = useState("")
    const [url, setUrl] = useState("")
    const [pass, setPass] = useState("")
    const [showEmailError, setShowEmailError] = useState(false)
    const [passwordValidityError, setPasswordValidityError] = useState(null)
    const [validationError, setValidationError] = useState("")
    const [isProcessing, setIsProcessing] = useState(false)
    const [error, setError] = useState(null)

    // Handle form submission
    const handleSubmit = async (e) =>{
        e.preventDefault()

        // Create user object with the retrieved input
        const newUser = { name, surname, email, password: pass, filteredBacklinkCategories: niches, filteredOutlinkCategories: niches }
        
        try {
            setIsProcessing(true)

            // Create a new user
            const response = await registerService.createUser(newUser)

            // Create first website for user
            await websiteService.postFirstWebsite({ owner: response.data.id, url })

            // Send verification email
            const emailResponse = await emailService.verificationEmail({ email: response.data.email, token: response.data.temporaryToken })

            // Check if email was sent successfully
            if(emailResponse.data.status !== 200){
                setValidationError(emailResponse.data.details)
            } else{
                // Add new user to users state
                dispatch(setUsers(response.data))

                // Navigate to next page
                navigate('/register/confirmation', {replace: true})
            }

            // Reset fields
            setIsProcessing(false)
            setEmail("")
            setUrl("")
            setPass("")
        } catch (error) {            
            if(error.response && error.response.status === 403){
                setError("Website cannot be added as it already exists.");
                setUrl("");

                // Rollback user creation
                await registerService.deleteFailedUser(email)

                setIsProcessing(false) // Stop processing here
            return; // Exit the function
            } else{
                setError(error.message)
                setIsProcessing(false)
            }
        }
    }

    return(
        <form id="register-form" onSubmit={handleSubmit}>
            {/* Name and surname input */}
            <div className='finish-registration-name'>
                <FormInputText 
                    icon={<CgProfile className='finish-form-icon' />} 
                    placeholder="Name"
                    inputValue={name}
                    setInputValue={setName}
                />

                <FormInputText 
                    placeholder="Name"
                    inputValue={surname}
                    setInputValue={setSurname}
                />      
            </div>

            {/* Email input and error message */}
            <RegisterFormEmailInput email={email} setEmail={setEmail} showEmailError={showEmailError} setShowEmailError={setShowEmailError} />
            {showEmailError? <ErrorMessage message="This email is already being used" /> : ""}

            {/* Website input */}
            <div className="register-form-input">
                <TbWorld className='finish-form-icon' />
                <input 
                    type="text" 
                    placeholder='Website (URL)' 
                    value={url}
                    onChange={({target})=>{setUrl(target.value)}}   
                    required
                />
            </div>

            {/* Password and error message */}
            <FormInputPassword
                inputValue={pass}
                setInputValue={setPass}
                passwordValidityError={passwordValidityError}
                setPasswordValidityError={setPasswordValidityError}
            />
            {passwordValidityError? <p className='register-email-error'>{passwordValidityError}</p>: ""}

            {/* Submit button */}
            <button 
                type="submit" 
                className={`${showEmailError ? "register-btn-disabled" : "register-btn"}`} 
                disabled={isProcessing || passwordValidityError}
            >
                <span>
                    {isProcessing? "Saving...": "Create account"}
                </span>
            </button>

            {/* Error messages */}
            <p className='register-email-error'>{validationError}</p>
            <p className='register-email-error'>{error}</p>
        </form>
    )
}

export default RegisterForm