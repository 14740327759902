import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const ProfileLandingSkeleton = () =>
{
    return(
        <SkeletonTheme>
        <div className="profile-landing">
            <div className="profile-landing-content">
                <div className="profile-landing-header">
                    <div><Skeleton width={100} /></div>
                </div>

                <div className="profile-landing-stats">
                    <div className="profile-landing-stats-single">
                        <div className='profile-landing-stats-single-top'>
                            <div className='profile-landing-stats-single-top-content'>
                                <h1><Skeleton width={100} /></h1>
                                <p><Skeleton width={100} /></p>
                            </div>
                        </div>
            
                        <small><Skeleton width={100} /></small>
                    </div>
                    <div className="profile-landing-stats-single">
                        <div className='profile-landing-stats-single-top'>
                            <div className='profile-landing-stats-single-top-content'>
                                <h1><Skeleton width={100} /></h1>
                                <p><Skeleton width={100} /></p>
                            </div>
                        </div>
            
                        <small><Skeleton width={100} /></small>
                    </div>
                    <div className="profile-landing-stats-single">
                        <div className='profile-landing-stats-single-top'>
                            <div className='profile-landing-stats-single-top-content'>
                                <h1><Skeleton width={100} /></h1>
                                <p><Skeleton width={100} /></p>
                            </div>
                        </div>
            
                        <small><Skeleton width={100} /></small>
                    </div>
                </div>

                <div className="profile-landing-activity">
                    <h1><Skeleton width={100} /></h1>
            
                    <div className="profile-landing-activity-websites">
                        <div className="profile-landing-activity-websites-single">
                            <div className='profile-landing-activity-websites-single-content'>
                                <h1><Skeleton width={100} /></h1>
                                <p><Skeleton width={100} /></p>
                                <div className="profile-landing-activity-websites-single-stats">
                                    <small><Skeleton width={100} /></small>
                                    <small><Skeleton width={100} /></small>
                                    <small><Skeleton width={100} /></small>
                                </div>
                                <small><Skeleton width={100} /></small>
                            </div>                            
                            <div><Skeleton width={200} height={200} /></div>
                        </div>
                        <div className="profile-landing-activity-websites-single">
                            <div className='profile-landing-activity-websites-single-content'>
                                <h1><Skeleton width={100} /></h1>
                                <p><Skeleton width={100} /></p>
                                <div className="profile-landing-activity-websites-single-stats">
                                    <small><Skeleton width={100} /></small>
                                    <small><Skeleton width={100} /></small>
                                    <small><Skeleton width={100} /></small>
                                </div>
                                <small><Skeleton width={100} /></small>
                            </div>                            
                            <div><Skeleton width={200} height={200} /></div>
                        </div>
                        <div className="profile-landing-activity-websites-single">
                            <div className='profile-landing-activity-websites-single-content'>
                                <h1><Skeleton width={100} /></h1>
                                <p><Skeleton width={100} /></p>
                                <div className="profile-landing-activity-websites-single-stats">
                                    <small><Skeleton width={100} /></small>
                                    <small><Skeleton width={100} /></small>
                                    <small><Skeleton width={100} /></small>
                                </div>
                                <small><Skeleton width={100} /></small>
                            </div>                            
                            <div><Skeleton width={200} height={200} /></div>
                        </div>
                    </div>
                
                    <div className='profile-landing-activity-desc'>
                        <p><Skeleton width={100} /></p>
                        <button><Skeleton width={100} /></button>
                    </div>   
                </div>
            </div>
        </div>
        </SkeletonTheme>
    )
}

export default ProfileLandingSkeleton