import axios from "axios";
const baseUrl = `${process.env.REACT_APP_API_BASE_URL}notifications`


const getAll = (config) => axios.get(baseUrl, config)

const getUserNotifications = (id, config) => axios.get(`${baseUrl}/${id}`, config)

const postNotification = (data) => axios.post(baseUrl, data)

const setNotificationOpen = (data, config) => axios.put(`${baseUrl}/open-notifications`, data, config)

const deleteNotification = (id, config) => axios.delete(`${baseUrl}/${id}`, config)

const notificationsService = { getAll, getUserNotifications, postNotification, setNotificationOpen, deleteNotification }

export default notificationsService
