import { Link } from 'react-router-dom'
import './coming-soon.css'

const ComingSoon = () =>{
    return(
        <div className="coming-soon">
            <h1>Coming Soon</h1>
            <p>This page is under construction. In the meantime you can explore all our other feaures!</p>
            <Link className='coming-soon-link' to="/">Return home</Link>
        </div>
    )
}

export { ComingSoon }