import { useEffect } from "react"
import { useSelector } from "react-redux"

import { AiOutlineMail } from 'react-icons/ai'

export default function WaitlistCompleteFormEmail ({ email, setEmail, setShowEmailError }){
    // Store
    const users = useSelector(({ users }) => users.users )

    // Check email validity
    const checkEmailValidity = () =>{
        const emailExists = users.find(user => user.email === email)

        if(emailExists){
            setShowEmailError(true)
        } else{
            setShowEmailError(false)
        }
    }

    // Check if email already exists in database on render
    useEffect(()=>{
        const emailExists = users.find(user => user.email === email)

        if(emailExists){
            setShowEmailError(true)
        } else{
            setShowEmailError(false)
        }
    }, [])

    return(
        <div className="register-form-input">
            <AiOutlineMail className='register-icons' />
            <input 
                type="email" 
                placeholder='Email' 
                value={email}
                onKeyUp={checkEmailValidity}    
                onChange={({target})=>{setEmail(target.value)}}
                required
            />
        </div>
    )
}