import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import { initializeWebsiteConnections } from "../../../store/reducers/connectionReducer"
import { config } from '../../../apiConfig'

// Components
import { BacklinksTableItems } from './Items/BacklinksTableItems'
import { BacklinksTableNav } from './Nav/BacklinksTableNav'
import { BacklinksTableSkeleton } from '../BacklinksTableSkeleton'
import { ErrorMessage } from "../../../common/ErrorMessage"

const BacklinksTable = ({ role }) =>{
    // Get website url from params
    const activeWebsiteUrl = useParams().website

    // Store
    const dispatch = useDispatch()
    const currentUser = useSelector(({ currentUser }) => currentUser)
    const websites = useSelector(({ websites }) => websites.userWebsites)
    const loading = useSelector(({ connections }) => connections.websiteConnectionsLoading)
    const error = useSelector(({ connections }) => connections.websiteConnectionsError)

    // Local state
    const [selectedWebsite, setSelectedWebsite] = useState(null)
    const [connectionsFilter, setConnectionsFilter] = useState("All")

    // Find which user website to show depending on url params
    useEffect(()=>{
        const foundWebsite = websites.find( website => website.url === activeWebsiteUrl)

        setSelectedWebsite(foundWebsite)
    }, [websites, activeWebsiteUrl])

    // Get connection for selected website as source / target (backlinks / outlinks)
    useEffect(()=>{
        if(selectedWebsite){
            dispatch(initializeWebsiteConnections(selectedWebsite.id, role, config(currentUser.token)))
        }
    }, [dispatch, selectedWebsite])

    // If there's an error fetching the data, display a message
    if(error) return <ErrorMessage error={error} />

    // If data is still loading, return skeleton
    if(loading) return <BacklinksTableSkeleton />

    return(
        <div className='backlinks-table'>
            <BacklinksTableNav 
                connectionsFilter={connectionsFilter} 
                setConnectionsFilter={setConnectionsFilter} 
                role={role} 
            />
            <BacklinksTableItems 
                connectionsFilter={connectionsFilter} 
                role={role} 
            />
        </div>
    )
}

export { BacklinksTable }