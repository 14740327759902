import { useEffect, useRef } from "react"
import { Link } from "react-router-dom"

import { gsap } from "gsap"
import './nav-mobile.css'

// resources
import logo from '../../../resources/logo-link.png'
import { AiOutlineClose } from 'react-icons/ai'

// Components
import { NavMenuMobileLinks } from "./NavMenuMobileLinks"
import { Copyright } from "../../Footer/Copyright"

const NavMenuMobile = ({ setMobileMenuIsOpen }) =>{
    // Refs
    const wrapperRef = useRef(null)

    // Animate menu on closing
    const handleCloseMenu = () =>{
        if(wrapperRef.current){
            gsap.to(wrapperRef.current, { duration: 0.2, x: -window.innerWidth })

            // When animation has finished, hide the menu
            setTimeout(() => {
                setMobileMenuIsOpen(false)
            }, 200)
        }
    }

    // Animate component on render
    useEffect(()=>{
        if(wrapperRef.current){
            gsap.fromTo(wrapperRef.current, { x: -window.innerWidth }, { duration: 0.2, x: 0 })
        }
    }, [])

    return(
        <div className="nav-menu-mobile" ref={wrapperRef}>
            <div className="nav-menu-mobile-top">
                <Link to="/" className="logo">
                    <img src={logo} alt="logo" />
                </Link>
                <button className="nav-menu-mobile-close-btn" onClick={handleCloseMenu}><AiOutlineClose /></button>
            </div>

            <NavMenuMobileLinks setMobileMenuIsOpen={setMobileMenuIsOpen} />

            <div className="nav-mobile-copyright"><Copyright /></div>
        </div>
    )
}

export { NavMenuMobile }