import { useState } from "react"

const WebsiteDetailsDangerZoneAlert = ({ setShowDeleteAlert, setProceedToAction, websiteUrl }) =>{
    // Local state
    const [url, setUrl] = useState("")
    const [error, setError] = useState(false)

    // Handle confirm button click
    const handleConfirm = (e) =>{
        e.preventDefault()

        // Check if the typed website matches the website url
        if(websiteUrl !== url){
            setError(true)
        } else{
            setProceedToAction(true)
            setShowDeleteAlert(false)
        }

        // Reset field
        setUrl("")
    }

    // Handle input change
    const handleInputChange = (e) =>{
        // Change input value and reset error
        setUrl(e.target.value)
        setError(false)
    }

    return(
        <div className="website-details-danger-zone-alert">
            <form onSubmit={handleConfirm} className="website-details-danger-zone-alert-form">
                <p>Confirm action by entering the url of the website</p>
                <small>Remember this action is irreversible</small>
                <input 
                    className={`${error? "website-details-danger-zone-alert-input-error": "website-details-danger-zone-alert-input"}`}
                    type="text" 
                    value={url} 
                    placeholder="www.website.com" 
                    onChange={handleInputChange} 
                    required
                />
                {error && <span>The websites don't match</span>}
                
                <div>
                    <button onClick={()=>{ setShowDeleteAlert(false) }}>Cancel</button>
                    <button type="submit">Confirm</button>
                </div>
            </form>
        </div>
    )
}

export { WebsiteDetailsDangerZoneAlert }
