import { createSlice } from "@reduxjs/toolkit";

const successSlice = createSlice({
    name: 'success',
    initialState: null,
    reducers: {
        setSuccess(state, action){
            return action.payload
        },
        resetSuccess(state, action){
            return null
        }
    }
})

export const { setSuccess, resetSuccess } = successSlice.actions

export default successSlice.reducer