import axios from "axios";
const baseUrl = `${process.env.REACT_APP_API_BASE_URL}links`

const getAll = (config) => axios.get(baseUrl, config)

const getAllWebsiteLinks = (id, config) => axios.get(`${baseUrl}/all/${id}`, config)

const getWebsiteLinks = (id, option, config) => axios.get(`${baseUrl}/${id}?websiteOption=${option}`, config)

const postLink = (data, config) => axios.post(baseUrl, data, config)

const editLink = (id, data, config) => axios.put(`${baseUrl}/${id}`, data, config)

const deleteLink = (id, config) => axios.delete(`${baseUrl}/${id}`, config)

const linkService = { getAllWebsiteLinks, getWebsiteLinks, postLink, editLink, deleteLink, getAll }
export default linkService