import { useSelector } from 'react-redux';
import { ResponsiveContainer, LineChart, CartesianGrid, Line, XAxis, YAxis } from 'recharts';
import { getLastWeekStats } from '../../../../helpers/getLastWeekStats';


const WebsiteDetailsStats = () =>{
    // Store
    const allWebsiteLinks = useSelector(({ links }) => links.allWebsiteLinks)

    // Get array with daily stats for last week
    const statsData = getLastWeekStats(allWebsiteLinks)

    return(
        <div className="website-details-stats">
            <ResponsiveContainer width="100%" height="100%">
                <LineChart width={500} height={300} data={statsData}>
                    <XAxis />
                    <YAxis/>
                    <CartesianGrid stroke="#eee" strokeDasharray="5 5"/>
                    <Line type="monotone" dataKey="count" stroke="#5400ff" dot={false} />
                </LineChart>
            </ResponsiveContainer>
        </div>
    )
}

export { WebsiteDetailsStats }