import { Link, useNavigate } from "react-router-dom"

const NavMenuMobileLinks = ({ setMobileMenuIsOpen }) =>{
    // Router
    const navigate = useNavigate()

    // Animate and close menu before jumping on new page
    const handleLinkClick = (link) =>{
        setMobileMenuIsOpen(false)
        navigate(link)
    }

    return(
        <div className="nav-menu-mobile-links">
            <button className="nav-menu-mobile-link" onClick={()=>{ handleLinkClick("/product") }}>Product</button>
            <button className="nav-menu-mobile-link" onClick={()=>{ handleLinkClick("/company") }}>Company</button>
            <button className="nav-menu-mobile-link" onClick={()=>{ handleLinkClick("/plans") }}>Pricing</button>
            <button className="nav-menu-mobile-link" onClick={()=>{ handleLinkClick("/contact") }}>Contact</button>
            {process.env.REACT_APP_API_APP_STAGE !== 'waitlist'?
                <Link to="/register" className="nav-menu-mobile-link-signup">Sign up</Link>
                : ""
            }
        </div>
    )
}

export { NavMenuMobileLinks }