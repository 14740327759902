import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getFirstLetter } from '../../../helpers/helpers'

// import icons
import { AiOutlineKey } from 'react-icons/ai'
import { MdOutlineDashboard } from 'react-icons/md'
import { LogoutButton } from '../../Logout/LogoutButton'

const NavUserOptions = () => {
    // Store
    const currentUser = useSelector(({ currentUser }) => currentUser )

    return(
        <div className="nav-user-options">
            <div className="nav-options-top">
                <div className="nav-options-top-img">
                    <p>{currentUser? getFirstLetter(currentUser.userForToken.name) : ""}</p>
                </div>
                
                <div>
                    <p>{currentUser? currentUser.userForToken.name : ""}</p>
                    <small>{currentUser? currentUser.userForToken.email : ""}</small>
                </div>
            </div>

            <div className="nav-options-btns">
                <Link to="/profile" className='nav-options-btn'><MdOutlineDashboard /> Dashboard</Link>
                {/* <Link to="/profile/billing" className='nav-options-btn'><MdOutlineMonetizationOn /> Billing</Link> */}
                {currentUser.userForToken.isAdmin? <Link to="/admin" className='nav-options-btn'><AiOutlineKey /> Admin panel</Link> : ""}
                <LogoutButton />
            </div>
        </div>
    )
}

export default NavUserOptions