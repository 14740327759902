import { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux"

import { setBacklinksDropdownOpen, setOutlinksDropdownOpen } from "../../../../../store/reducers/sidebarReducer";

import { ProfileSidebarOptionsDropdownMainButtonArrow } from "./ProfileSidebarOptionsDropdownMainButtonArrow";

const ProfileSidebarOptionsDropdownMainButton = ({ icon, title, state }) =>{
    // Store
    const dispatch = useDispatch()
    const activeButton = useSelector(({ sidebar }) => sidebar.activeButton)

    // Handle button click by expanding list
    const handleClick = useCallback(()=>{
        if(state === 'outlink-connections'){
            dispatch(setOutlinksDropdownOpen())
        } else{
            dispatch(setBacklinksDropdownOpen())
        }
    }, [dispatch, state])

    return(
        <button 
            className={`${activeButton.includes(state)? "profile-sidebar-options-nested-btn-active": "profile-sidebar-options-nested-btn"}`}
            onClick={handleClick}
        >
            {icon} 
            {title} 
            <ProfileSidebarOptionsDropdownMainButtonArrow state={state} />
        </button>
    )
}   

export { ProfileSidebarOptionsDropdownMainButton }