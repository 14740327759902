import './backlinks.css'

// Components
import { BacklinksTable } from './Table/BacklinksTable'
import { BacklinksTop } from './BacklinksTop/BacklinksTop'
import { BacklinksChatButton } from './BacklinksChatButton'
import { BacklinksMessages } from './Messages/BacklinksMessages'
import BacklinkOptions from '../BacklinkOptions/BacklinkOptions'

const Backlinks = () =>{
    return(
        <div className="backlinks-page">
            <BacklinksTop title="Backlinks" />
            <BacklinksTable role="source" />
            <BacklinksChatButton />
            <BacklinksMessages role="source" />
            <BacklinkOptions />
        </div>
    )
}

export { Backlinks }