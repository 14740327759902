import { createSlice } from "@reduxjs/toolkit";
import walkthoughService from "../../services/walkthroughService";

const initialState = {
    pages: [],
    loading: false,
    error: null
}

const walkthroughSlice = createSlice({
    name: 'walkthrough',
    initialState,
    reducers:{
        setPages(state, action){
            state.pages = action.payload
        },
        setLoading(state, action){
            state.loading = action.payload
        },
        setError(state, action){
            state.error = action.payload
        }
    }
})

export const { setPages, setLoading, setError } = walkthroughSlice.actions

export const initialiazePages = () =>{
    return async dispatch => {
        dispatch(setLoading(true))

        try {
            const response = await walkthoughService.getAll()
            dispatch(setPages(response.data))
            dispatch(setLoading(false))
        } catch (error) {
            dispatch(setError(error.message))
        }
    }
}

export default walkthroughSlice.reducer