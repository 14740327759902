const PrivacySingle = ({ item }) =>{
    return(
        <div className="privacy-content-single">
            <h5><span>{item.id}</span>{item.title}</h5>
            <p>{item.subtitle}</p>
            {item.list.map( li => <li key={li.id}><span>{item.id}.{li.id}</span> {li.text}</li> )}
        </div>
    )
}

export { PrivacySingle }