const BillingNav = ({ activeButton, setActiveButton }) =>{
    return(
        <div className="billing-nav">
            <button 
                className={`${activeButton === "overview"? "billing-nav-btn-active": "billing-nav-btn"}`}
                onClick={()=>{setActiveButton("overview")}}
            >
                Overview
            </button>
            
            <button 
                className={`${activeButton === "invoices"? "billing-nav-btn-active": "billing-nav-btn"}`}
                onClick={()=>{setActiveButton("invoices")}}
            >
                Invoices
            </button>
        </div>
    )
}

export { BillingNav }