import axios from "axios";
import api from './api'
const baseUrl = `${process.env.REACT_APP_API_BASE_URL}connection`


const getAll = () => axios.get(baseUrl)

const getWebsiteConnections = (id, role, config) => axios.get(`${baseUrl}/${id}/${role}`, config)

const postConnection = (data, config) => axios.post(baseUrl, data, config)

const approveConnection = (id, data, config) => api.put(`${baseUrl}/${id}`, data, config)

const updateConnection = (id, data, config) => axios.put(`${baseUrl}/update/${id}`, data, config)

const deleteConnection = (connectionId, config) => api.delete(`${baseUrl}/${connectionId}`, config)

const connectionService = { 
    getAll, 
    postConnection, 
    getWebsiteConnections, 
    approveConnection,
    updateConnection, 
    deleteConnection 
}
export default connectionService