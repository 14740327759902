import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

const SettingsWarningMessage = () =>{
    // store
    const websites = useSelector(({ websites }) => websites.userWebsites )

    // Local state
    const [userPreferences, setUserPreferences] = useState({})

    // Handle do not show click
    const handleDoNotShow = () =>{
        // Update user preferences and save them in state and localstorage
        const updatePreferences = {...userPreferences, showSettingsMissingInfoWarning: false}
        setUserPreferences(updatePreferences)
        localStorage.setItem('userPreferences', JSON.stringify(updatePreferences))
    }

    // Retrieve user preferences
    useEffect(()=>{
        const storedPreferences = JSON.parse(localStorage.getItem('userPreferences')) || {}
        setUserPreferences(storedPreferences)
    }, [])

    // If niche and language have been added, do not render anything
    if(websites[0].niche.length !== 0 && websites[0].languageOfWebsite.length !== 0) return null
    
    // If user chooses to hide this message, do not render
    if(userPreferences.showSettingsMissingInfoWarning === false) return null

    return(
        <div className="profile-message-wrapper">
            <div className="profile-message">
                <h1>Complete your profile</h1>
                <p>Please complete your website details by adding your niche and language. 
                This helps with matching you to more suitable websites. You can select more than one niches or languages.
                </p>

                <div className="profile-message-btns">
                    <button className="profile-message-close-btn" onClick={ handleDoNotShow }>Don't show again</button>
                </div>
            </div>
        </div>
    )
}

export { SettingsWarningMessage }