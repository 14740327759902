import { useSelector, useDispatch } from 'react-redux'
import './success.css'

import { resetSuccess } from '../../store/reducers/successReducer'

import {AiFillCheckCircle} from 'react-icons/ai'
import CloseModalButton from '../CloseModalButton'

export default function SuccessToast (){
    // Store
    const dispatch = useDispatch()
    const success = useSelector(({ success }) => success)

    // Handle function to pass on close button
    const closeHandler = () =>{
        dispatch(resetSuccess())
    }

    // If success message is empty, do not render
    if(!success) return null

    return(
        <div className="toast">
            <span className='toast-line'></span>

            <div className='toast-icon'><AiFillCheckCircle /></div>

            <div className="toast-text">
                <p>Completed successfully</p>
                <p>{success}</p>
            </div>

            <CloseModalButton handler={closeHandler} />
        </div>
    )
}