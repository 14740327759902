import { useState } from "react"
import { useSelector } from "react-redux"

import { config } from "../../../apiConfig"
import websiteService from "../../../services/websiteService"

const NewWebsitesComments = ({ setShowCommentForm, id, value }) =>{
    // Store
    const currentUser = useSelector(({ currentUser }) => currentUser)

    // State
    const [comment, setComment] = useState(value)

    // Handle form submit
    const handleSubmit = async (e) =>{
        e.preventDefault()

        // Header and data to send with request
        const data = { comment }
        
        try {
            // Send request
            await websiteService.activateWebsite(id, data, config(currentUser.token))

            // Reset input and close pop up
            setComment("")
            setShowCommentForm(false)
        } catch (error) {
            console.log(error)
        }
    }

    return(
        <div className="new-users-comments">
            <form className="new-users-comments-form" onSubmit={handleSubmit}>
                <h1>Comment / note</h1>
                <p>Write a comment for this website</p>
                <textarea 
                    value={comment} 
                    cols="30" 
                    rows="10" 
                    placeholder="Leave a comment" 
                    onChange={({target})=>{setComment(target.value)}}>    
                </textarea>
                <button className="new-users-comments-submit-btn" type="submit">Confirm</button>
                <button className="new-users-comments-close-btn" onClick={()=>{setShowCommentForm(false)}}>X</button>
            </form>
        </div>
    )
}

export default NewWebsitesComments