import { useState } from 'react'
import { useSelector } from 'react-redux'
import './settings.css'

import { SettingsContent } from './SettingsContent'
import { SettingsSidebar } from './Sidebar/SettingsSidebar'
import { SettingsWarningMessage } from './SettingsWarningMessage'

const Settings = () =>{
    // Store
    const userWebsites = useSelector(({ websites }) => websites.userWebsites)

    // Local state
    const [activeSetting, setActiveSetting] = useState('websites')
    const [selectedWebsite, setSelectedWebsite] = useState(userWebsites[0].url)

    return(
        <div className="settings">
            <div className="settings-alert">
                <SettingsWarningMessage />
            </div>

            <div className='settings-content-wrapper'>
                <SettingsSidebar 
                    activeSetting={activeSetting}
                    setActiveSetting={setActiveSetting} 
                    selectedWebsite={selectedWebsite} 
                    setSelectedWebsite={setSelectedWebsite} 
                />
                <SettingsContent 
                    activeSetting={activeSetting} 
                    selectedWebsite={selectedWebsite} 
                />
            </div>
        </div>
    )
}

export { Settings }