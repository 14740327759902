import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import './logs.css'

import { initializeLogs } from "../../store/reducers/logReducer"
import { config } from '../../apiConfig'

import { ErrorMessage } from '../../common/ErrorMessage'
import { Spinner } from '../../common/Spinner'
import { LogSingle } from "./LogSingle"

const Logs = () =>{
    // Store 
    const dispatch = useDispatch()
    const currentUser = useSelector(({ currentUser }) => currentUser)
    const logs = useSelector(({ logs }) => logs.logs)
    const loading = useSelector(({ logs }) => logs.loading)
    const error = useSelector(({ logs }) => logs.error) 

    // Get all logs request
    useEffect(()=>{
        dispatch(initializeLogs(config(currentUser.token)))
    }, [])

    if(error) return <ErrorMessage error={error} />
    if(loading) return <Spinner />

    return(
        <div className="logs">
            <h1>Logs</h1>
            <div className="logs-wrapper">
                <div className="logs-titles">
                    <div><p>Category</p></div>
                    <div><p>Message</p></div>
                    <div><p>Created</p></div>
                </div>
                {logs.map( log => <LogSingle key={log.id} log={log} />)}
            </div>
        </div>
    )
}

export { Logs }