import { useCallback, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import './nav.css'

import logo from '../../resources/logo-link.png'

import { NavMenuMobile } from './Mobile/NavMenuMobile'
import { NavLink } from './NavLink'
import NavAuth from './NavAuth'


const Nav = () =>{
    // local state
    const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false)

    // Ref for nav component
    const ref = useRef(null)

    // Callback to use in event listener
    const handleWheel = useCallback((e)=>{
        if(ref.current){
            if(e.deltaY>0){
                ref.current.style.background = 'white'
            } else if(e.deltaY <0){
                ref.current.style.background = 'transparent'
            }
        }
    })

    // Change background color on wheel up and down
    useEffect(()=>{
        window.addEventListener('wheel', handleWheel)

        return () => window.removeEventListener('wheel', handleWheel)
    }, [])

    return(
        <nav className="nav" ref={ref}>
            <Link to="/" className="nav-logo">
                <img src={logo} alt="logo" />
                <h1>Linkeby</h1>
            </Link>

            <div className="nav-menu">
                <NavLink />
                <Link to="/company" className='nav-menu-links'>Company</Link>
                <Link to="/plans" className='nav-menu-links'>Pricing</Link>
                <Link to="/contact" className='nav-menu-links'>Contact</Link>
            </div>

            {mobileMenuIsOpen && <NavMenuMobile setMobileMenuIsOpen={setMobileMenuIsOpen} />}

            <NavAuth setMobileMenuIsOpen={setMobileMenuIsOpen} />
        </nav>
    )
}

export default Nav