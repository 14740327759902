import { useSelector } from 'react-redux'
import './website-details-summary.css'

import {BiLinkAlt, BiLink} from 'react-icons/bi'
import { useMemo } from 'react'

const WebsiteDetailsSummary = ({ websiteId }) =>{
    // Store
    const allWebsiteLinks = useSelector(({ links }) => links.allWebsiteLinks)

    // Find backlinks and outlinks count for this website
    const linksData = useMemo(()=>{
        const outlinks = allWebsiteLinks.filter( link => link.targetWebsite.id === websiteId)
        const backlinks = allWebsiteLinks.filter( link => link.sourceWebsite.id === websiteId)

        return { outlinks: outlinks.length, backlinks: backlinks.length }
    }, [allWebsiteLinks])

    return(
        <div className="website-details-summary">
            <div className='website-details-summary-card'>
                <BiLink className='website-details-summary-card-icon' />
                <div>
                <p>Backlinks</p>
                <small>{linksData.backlinks}</small>
                </div>
            </div>
            <div className='website-details-summary-card'>
                <BiLinkAlt className='website-details-summary-card-icon' />
                <div>
                <p>Outlinks</p>
                <small>{linksData.outlinks}</small>
                </div>
            </div>
        </div>
    )
}

export { WebsiteDetailsSummary }