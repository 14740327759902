import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { updateCurrentUser } from '../../../../store/reducers/currentUserReducer'
import paymentService from '../../../../services/paymentService'
import { config } from '../../../../apiConfig'

// Components
import SuccessToast from '../../../Popups/SuccessToast'

const BillingOverviewPaymentMethodSingle = ({ payment }) =>{
    // Store
    const dispatch = useDispatch()
    const currentUser = useSelector(({ currentUser }) => currentUser)

    // Local state
    const [showToast, setShowToast] = useState(false)
    const [isProcessing, setIsProcessing] = useState(false)

    // Handle remove payment button
    const handleRemovePayment = async () =>{
        try {
            setIsProcessing(true)

            const response = await paymentService.detachPaymentMethod({ paymentMethodId: payment.methodId }, config(currentUser.token))

            // Retrieve currentUser from locastorage and update
            const storedUser = JSON.parse(localStorage.getItem('currentUser' || '{}'))
            storedUser.userForToken = response.data

            // Save updated currentUser in localstorage and store
            localStorage.setItem('currentUser', JSON.stringify(storedUser))
            dispatch(updateCurrentUser(storedUser))

            setShowToast(true)
            setIsProcessing(false)
        } catch (error) {
            console.log(error)
            setIsProcessing(false)
        }
    }

    return(
        <div className='billing-details-content-single'>
            <div className="billing-details-content-single-icon">
                <img 
                    // src={require(`../../../../resources/cards/${payment.brand}.png`)} 
                    src={require(`../../../../resources/cards/visa.png`)} 
                    alt={`${payment.brand}`} 
                />
            </div>

            <div>
                <p>{payment? `Ending in ${payment.last4}`: "Paypal"}</p>
                {payment && <small>Expires {payment.exp_month} / {payment.exp_year}</small>}
            </div>

            <button 
                id='billing-details-content-single-remove-btn' 
                onClick={handleRemovePayment}
                disabled={isProcessing}
            >Remove
            </button>

            {showToast && <SuccessToast title="Removed successfully" subtitle="Payment method was successfully detached." setSuccess={setShowToast} />}
        </div>
    )
}
 
export { BillingOverviewPaymentMethodSingle }