import { useEffect } from "react"
import { Link, useParams } from "react-router-dom"

import { useSelector, useDispatch } from "react-redux"
import { initializeAllWebsiteLinks } from "../../../../store/reducers/linksReducer"

import { config } from '../../../../apiConfig'
import './website-details.css'

import { DashboardFooter } from "../../../Footer/DashboardFooter"
import { WebsiteDetailsStats } from "./WebsiteDetailsStats"
import { WebsiteDetailsLinks } from "./WebsiteDetailsLinks/WebsiteDetailsLinks"
import { WebsiteDetailsSummary } from "./WebsiteDetailsSummary/WebsiteDetailsSummary"

import {IoMdArrowBack} from 'react-icons/io'
import { WebsiteDetailsSkeleton } from "./WebsiteDetailsSkeleton"
import { WebsiteDetailsDangerZone } from "./DangerZone/WebsiteDetailsDangerZone"

const WebsiteDetails = () =>{
    // Get website url from params
    const websiteUrl = useParams().website

    // Store
    const userWebsites = useSelector(({ websites }) => websites.userWebsites)
    const currentUser = useSelector(({ currentUser }) => currentUser)
    const loading = useSelector(({ links }) => links.allWebsiteLinksLoading)
    const dispatch = useDispatch()

    // Find matching website
    const matchingWebsite = userWebsites.find((website) => website.url === websiteUrl)

    // Scroll to top when component renders
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    // Get all links for this website
    useEffect(()=>{
        dispatch(initializeAllWebsiteLinks(matchingWebsite .id, config(currentUser.token)))
    }, [dispatch])

    // Show skeleton while links are being fetched
    if(loading) return <WebsiteDetailsSkeleton />

    // If no website is found return a message
    if (!matchingWebsite) return <p>No matching website found</p>

    return(
        <div className="website-details">
            <div className="website-details-header">
                <h1>Website details <span>({websiteUrl})</span></h1>
                <Link to="/profile/website-overview" className="website-details-header-btn"><IoMdArrowBack /> All websites</Link>
            </div>

            <WebsiteDetailsSummary websiteId={matchingWebsite.id} />
            <WebsiteDetailsStats />
            <WebsiteDetailsLinks websiteId={matchingWebsite.id} />
            <WebsiteDetailsDangerZone website={matchingWebsite} />

            <DashboardFooter />
        </div>
    )
}

export { WebsiteDetails }