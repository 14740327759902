import { useState } from "react";

import { IoMdHelpCircleOutline } from "react-icons/io";

import BacklinkOptionsCloseButton from './BacklinkOptionsCloseButton'

export default function BacklinkOptionsTitle ({ title, text, setShowBacklinkSettings, wrapperRef }){
    // State
    const [showTooltip, setShowTooltip] = useState(false)

    return(
        <div className="backlink-options-title">
            <h1 
                onMouseEnter={()=>{ setShowTooltip(true) }}
                onMouseLeave={()=>{ setShowTooltip(false) }}    
            >
                {title} <IoMdHelpCircleOutline />
            </h1>

            {/* Tooltip shown when hovering over title */}
            {showTooltip?
            <div>{text}</div>
            : ""}

            <BacklinkOptionsCloseButton setShowBacklinkSettings={setShowBacklinkSettings} wrapperRef={wrapperRef} />      
        </div>
    )
}