import { Link } from 'react-router-dom'

import useFilterSidebarConnections from '../../../../hooks/sidebar/useFilterSidebarConnections'

// import icons
import {BiHelpCircle} from 'react-icons/bi'
import {FiSettings} from 'react-icons/fi'
import { AiOutlineLink } from 'react-icons/ai'
import { BsLink } from 'react-icons/bs'
import { MdOutlineDashboard } from 'react-icons/md'

// Import components
import ProfileSideBarOptionsButton from './ProfileSidebarOptionsButton'
import { ProfileSidebarOptionsButtonProfile } from './ProfileSidebarOptionsButtonProfile'
import { ProfileSidebarOptionsDropdown } from './Dropdown/ProfileSidebarOptionsDropdown'

const ProfileSideBarOptions = () =>{
    // Get connections count to show in notifications, from custom hook
    const userConnections = useFilterSidebarConnections()

    return(
        <div className="profile-sidebar-options">
            <div className="profile-sidebar-options-top">
                <small>My workspace</small>
                <ProfileSideBarOptionsButton 
                    icon={<MdOutlineDashboard className='profile-sidebar-icons' />}
                    label="Dashboard"
                    state=""
                    userConnections={userConnections}
                />
                <ProfileSidebarOptionsDropdown
                    icon={<BsLink className='profile-sidebar-icons'  />}
                    title="Backlinks"
                    state="backlink-connections"
                    userConnections={userConnections}
                />
                <ProfileSidebarOptionsDropdown
                    icon={<AiOutlineLink className='profile-sidebar-icons'  />}
                    title="Outlinks"
                    state="outlink-connections"
                    userConnections={userConnections}
                />
            </div>
        
            <div className="profile-sidebar-options-bottom">
                <ProfileSidebarOptionsButtonProfile
                    icon={<FiSettings className='profile-sidebar-icons' />}
                    label="Settings"
                    state="settings"
                />
                <Link to="/support" className='profile-sidebar-options-btn'>
                    <span className="profile-sidebar-options-btn-icon"><BiHelpCircle className='profile-sidebar-icons'  /></span>
                    Support
                </Link>
            </div>
        </div>
    )
}

export default ProfileSideBarOptions