import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import './profile-landing-stats.css'

// Icons
import {FiUsers} from 'react-icons/fi'

// Components
import ProfileLandingStatsSingle from './ProfileLandingStatsSingle'

const ProfileLandingStats = ({ links }) =>{
    // Get all websites from store
    const currentUser = useSelector(({ currentUser }) => currentUser)
    const connections = useSelector(({ connections }) => connections.allConnections)

    // Find all backlinks for this user
    const backlinksFiltered = useMemo(()=>{
        return links.filter( link =>
            link.targetWebsite && link.targetWebsite.owner === currentUser.userForToken.id 
        )
    }, [links])

    // Find all outlinks for this user
    const outlinksFiltered = useMemo(()=>{
        return links.filter( link =>
            link.sourceWebsite && link.sourceWebsite.owner === currentUser.userForToken.id
        )
    }, [links])

    // Find all connections for all websites of user
    const connectedWebsites = useMemo(()=>{
        return connections.filter( conn => 
            (conn.targetWebsite && conn.targetWebsite.owner === currentUser.userForToken.id)
            || (conn.sourceWebsite && conn.sourceWebsite.owner === currentUser.userForToken.id)
            )
    }, [connections])

    return(
        <div className="profile-landing-stats">
            <ProfileLandingStatsSingle 
                data={connectedWebsites}
                icon={<FiUsers className='profile-landing-stats-icon' />} 
                title="Websites" 
                text="websites were connected" 
            />
            <ProfileLandingStatsSingle 
                data={outlinksFiltered}
                icon={<FiUsers className='profile-landing-stats-icon' />} 
                title="Outlinks" 
                text="outlinks were generated" 
            />
            <ProfileLandingStatsSingle 
                data={backlinksFiltered}
                icon={<FiUsers className='profile-landing-stats-icon' />} 
                title="Backlinks" 
                text="backlinks were generated" 
            />
        </div>
    )
}

export default ProfileLandingStats