const BacklinksTableItemSingleTarget = ({ connection }) =>{
    return(
        <div>
            <p>{connection.targetAnchorText}</p>

            <a 
                href={`https://${connection.targetAnchorUrl}`}
                className="backlinks-table-items-single-link"
                target="_blank"
                rel="noopener noreferrer"
            >
                {connection.targetAnchorUrl}
            </a>
        </div>
    )
}

export { BacklinksTableItemSingleTarget }