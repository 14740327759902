import './disputes.css'

const Disputes = () =>
{
    return(
        <div className="disputes">
            Disputes
        </div>
    )
}

export default Disputes