export default function OutlinkOptionsFormHOC ({ label, children }){
    return(
        <div className="backlink-options-form-input-container">
            <label>{label}</label>

            <div>
                {children}
            </div>
        </div>
    )
}