import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    outlinks: 0,
    backlinks: 0,
    activeButton: "",
    mobileSidebarIsOpen: false,
    backlinksDropdownOpen: true,
    outlinksDropdownOpen: true,
}

const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState,
    reducers:{
        setActiveButton(state, action){
            state.activeButton = action.payload
        },
        setBacklinksDropdownOpen(state, action){
            state.backlinksDropdownOpen = !state.backlinksDropdownOpen
        },
        setOutlinksDropdownOpen(state, action){
            state.outlinksDropdownOpen = !state.outlinksDropdownOpen
        },
        resetActiveButton(state, action){
            state.activeButton = "overview"
        },
        updateOutlinks(state, action){
            state.outlinks = action.payload
        },
        updateBacklinks(state, action){
            state.backlinks = action.payload
        },
        setMobileSidebarIsOpen(state, action){
            state.mobileSidebarIsOpen = action.payload
        }
    }
})

export const { 
    setActiveButton, 
    setBacklinksDropdownOpen,
    setOutlinksDropdownOpen,
    resetActiveButton, 
    updateOutlinks, 
    updateBacklinks, 
    setMobileSidebarIsOpen 
} = sidebarSlice.actions

export default sidebarSlice.reducer