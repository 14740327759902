import { useState } from "react"
import emailService from "../../services/emailService"

import FormInputText from "../../components/Forms/FormInputText"
import Textarea from "../../components/Forms/Textarea"

import {AiOutlineMail, AiOutlineUser, AiOutlineFileText} from 'react-icons/ai'
import {MdSubject} from 'react-icons/md'

const ContactForm = () =>{
    // Local state
    const [email, setEmail] = useState("")
    const [name, setName] = useState("")
    const [subject, setSubject] = useState("")
    const [text, setText] = useState("")
    const [error, setError] = useState("")
    const [success, setSuccess] = useState(false)

    // Handle form submit
    const handleSubmit = async (e) =>{
        e.preventDefault()
        
        try {
            // Send email
            const response = await emailService.contactEmail({ email, name, subject, text })

            // Check for email errors
            if(response.data.status !== 200){
                setError(response.data.details)
            } else{
                setSuccess(true)

                setEmail("")
                setName("")
                setSubject("")
                setText("")

                setInterval(() => {
                    setSuccess(false)
                }, 2000);
            }
        } catch (error) {
            console.log(error)
        }
    }

    return(
        <form className="contact-form" onSubmit={handleSubmit}>
            <p>How can we help?</p>

            <FormInputText icon={<AiOutlineMail />} placeholder="What's your email?" inputValue={email} setInputValue={setEmail} />
            <FormInputText icon={<AiOutlineUser />} placeholder="What's your name?" inputValue={name} setInputValue={setName} />
            <FormInputText icon={<MdSubject />} placeholder="What would you like to ask?" inputValue={subject} setInputValue={setSubject} />
            <Textarea icon={<AiOutlineFileText />} placeholder="Tell us some more detail" inputValue={text} setInputValue={setText} />

            <button type="submit">Send</button>
            {success && <div className="contact-success">Your email was sent. Thank you!</div>}
            <small>{error}</small>
        </form>
    )
}

export { ContactForm }