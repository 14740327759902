import { useEffect, useState } from "react"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import paymentService from "../../../../services/paymentService"

import { BillingOverviewPaymentMethodAddForm } from "./BillingOverviewPaymentMethodAddForm"
import { Spinner } from "../../../../common/Spinner"
import { useSelector } from "react-redux"

const BillingOverviewPaymentMethodAdd = ({ setShowAddPayment }) =>{
    // Store
    const currentUser = useSelector(({ currentUser }) => currentUser)

    // Local state
    const [stripePromise, setStripePromise] = useState(null)
    const [clientSecret, setClientSecret] = useState(null)
    const [error, setError] = useState(null)

    // Get stripe keys from server
    useEffect(()=>{
        let isMounted = true
        
        // Async function to load keys and initialize stripe
        async function initializeStripe (){
            try {
                const response = await paymentService.getStripeKey()

                if(isMounted){
                    setStripePromise(loadStripe(response.data.publishableKey))
                }
            } catch (error) {
                if(isMounted){
                    console.log(error.name)
                }
            }
        }
        // Run function
        initializeStripe()

        // Cleanup effect
        return () => { isMounted = false }
    }, [])

    // Create a setup intent
    useEffect(()=>{
        let isMounted = true

        // Function to make request
        async function createSetupIntent (){
            try {
                const response = await paymentService.createSetupIntent({ customerId: currentUser.userForToken.customerId })

                if(isMounted){
                    setClientSecret(response.data.clientSecret)
                }
            } catch (error) {
                if(isMounted){
                    setError(error.message)
                }
            }
        }
        createSetupIntent()

        // Claenup
        return () => { isMounted = false }
    }, [])

    if(!clientSecret || !stripePromise) return <Spinner />
    if(error) return <p>{error}</p>

    return(
        <div className="billing-overview-payment-add">
            <div className="billing-overview-payment-add-form">
                <button className="billing-overview-payment-add-close-btn" onClick={()=>{setShowAddPayment(false)}}>X</button>

                <Elements stripe={stripePromise} options={{ clientSecret }}>
                    <BillingOverviewPaymentMethodAddForm />
                </Elements>
            </div>
        </div>
    )
}

export { BillingOverviewPaymentMethodAdd }