export default function FormInputEmail ({icon, placeholder, inputValue, setInputValue, setEmailIsValid}){   
    // Handle change 
    const handleChange = (e) =>{
        setInputValue(e.target.value)

        if(emailRegEx.test(e.target.value)){
            setEmailIsValid(true)
        } else{
            setEmailIsValid(false)
        }
    }

    return(
        <div className="form-input-text">
            {icon}
            <input 
                type="email" 
                placeholder={placeholder} 
                value={inputValue} 
                onChange={handleChange}
                required 
            />
        </div>
    )
}

// Regural expression for validating email
const emailRegEx = /(?:[a-z0-9+!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i