import { useState } from 'react'
import { useSelector } from 'react-redux'
import './settings-sidebar.css'

import { SettingsSidebarOption } from './SettingsSidebarOption'
import { SettingsSidebarWebsiteOption } from './SettingsSidebarWebsiteOption'
import { SettingsSidebarAddWebsite } from './SettingsSidebarAddWebsite'
import ProfileWebsitesConfirmation from '../../Profile/ProfilePages/ProfileInfo/Pages/Websites/ProfileWebsitesConfiramtion'

const SettingsSidebar = ({ activeSetting, setActiveSetting, selectedWebsite, setSelectedWebsite }) =>{
    // Store
    const userWebsites = useSelector(({ websites }) => websites.userWebsites)

    // Local state
    const [showAddMessage, setShowAddMessage] = useState(false)

    return(
        <div className="settings-sidebar">
            <SettingsSidebarOption 
                activeSetting={activeSetting} 
                setActiveSetting={setActiveSetting} 
                state="account" 
            />
            
            {userWebsites.map( website => 
                <SettingsSidebarWebsiteOption 
                    key={website.id} 
                    activeSetting={activeSetting} 
                    setActiveSetting={setActiveSetting}
                    selectedWebsite={selectedWebsite}
                    setSelectedWebsite={setSelectedWebsite} 
                    state={website.url} 
                />
            )}
            
            <SettingsSidebarAddWebsite setShowAddMessage={setShowAddMessage} />
            
            {/* Popup messages */}
            {showAddMessage && <ProfileWebsitesConfirmation title="Added website successful" text="Changes will apply on your next log-in" />}
        </div>
    )
}

export { SettingsSidebar }