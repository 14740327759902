import { useState } from "react"

const FeedbackRating = () =>{
    const [activeScore, setActiveScore] = useState(7)

    return(
        <div className="feedback-rating">
            <label>Based solely on this visit to the website, how would you rate your experience based on easy of use?</label>
                
            <div>
                <div className="feedback-rating-score-wrapper">
                    {Array.from({ length: 10 }, (_, index) => (
                        <div key={index + 1} className="feedback-rating-score">
                            {index + 1}
                        </div>
                    ))}
                </div>

                <div className="feedback-rating-score-desc">
                    <small>Not satisfied</small>
                    <small>Extremely satisfied</small>
                </div>
            </div>
        </div>
    )
}

export { FeedbackRating }