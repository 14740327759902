import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { loadStripe } from "@stripe/stripe-js"
import paymentService from "../../../../services/paymentService"
import { Elements } from "@stripe/react-stripe-js"
import { useSelector } from "react-redux"

import { Spinner } from "../../../../common/Spinner"
import { RegisterPaymentTrialForm } from "./RegisterPaymentTrialForm"

const RegisterPaymentTrial = () =>{
    const priceId = useParams().price

    const currentUser = useSelector(({ currentUser }) => currentUser)

    // Local state
    const [stripePromise, setStripePromise] = useState(null)
    const [clientSecret, setClientSecret] = useState(null)
    const [error, setError] = useState(null)

    // Get stripe keys from server
    useEffect(()=>{
        let isMounted = true
        
        // Async function to load keys and initialize stripe
        async function initializeStripe (){
            try {
                const response = await paymentService.getStripeKey()

                if(isMounted){
                    setStripePromise(loadStripe(response.data.publishableKey))
                }
            } catch (error) {
                if(isMounted){
                    console.log(error.name)
                }
            }
        }
        // Run function
        initializeStripe()

        // Cleanup effect
        return () => { isMounted = false }
    }, [])

    // Create setup intent
    useEffect(()=>{
        let isMounted = true

        async function createPaymentIntent(){
            try {
                const response = await paymentService.createSetupIntent({ customerId: currentUser.userForToken.customerId })
            
                if(isMounted){
                    setClientSecret(response.data.clientSecret)
                }
            } catch (error) {
                if(isMounted){
                    setError(error.message)
                }
            }
        }
        createPaymentIntent()

        return () => { isMounted = false }
    }, [])

    if(!clientSecret || !stripePromise) return <Spinner />
    if(error) return <p>{error}</p>

    return(
        <div className="register-payment-trial">
            <Elements stripe={stripePromise} options={{ clientSecret }}>
                <RegisterPaymentTrialForm />
            </Elements>
        </div>
    )
}

export { RegisterPaymentTrial }