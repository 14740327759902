import { useState, useEffect } from "react"
import { Helmet } from "react-helmet-async"
import './terms.css'

import Nav from "../../components/Nav/Nav"
import Footer from '../../components/Footer/Footer'
import { TermsSingle } from "./TermsSingle"

const Terms = () =>{
    // Local state
    const [terms, setTerms] = useState([
        {
            title: 'User Responsibilities',
            subtitle: 'You are solely responsible for the content and links you submit to Linkeby. You agree to',
            list: [
                {
                    text: 'Share only legitimate, non-malicious links',
                    id: 1
                },
                {
                    text: 'Abide by all applicable laws and regulations',
                    id: 2
                },
                {
                    text: 'Not engage in any activity that could harm Linkeby or its users.',
                    id: 3
                },
                {
                    text: 'Ensure that your submitted content does not infringe on the intellectual property rights of others.',
                    id: 4
                },
            ],
            id: 1
        },
        {
            title: 'Link Exchange Guidelines',
            subtitle: 'Linkeby facilitates the exchange of backlinks among users. However, we have specific guidelines in place',
            list: [
                {
                    text: 'Only websites of certain categories or types are allowed.',
                    id: 1
                },
                {
                    text: 'All submitted links are subject to review, and we reserve the right to approve or reject them based on our criteria.',
                    id: 2
                },
                {
                    text: 'Links that violate our guidelines or contain harmful or illegal content will be removed.',
                    id: 3
                }
            ],
            id: 2
        },
        {
            title: 'Intellectual Property',
            subtitle: 'Linkeby does not claim ownership of the content and links you submit. You retain full ownership of your submitted content. By using Linkeby, you grant us a non-exclusive license to display and share your content on the platform.',
            list: [],
            id: 3
        },
        {
            title: 'Privacy Policy',
            subtitle: 'Please review our Privacy Policy to understand how we collect, use, and protect your personal information and data on Linkeby. Your use of the service is also governed by our Privacy Policy.',
            list: [],
            id: 4
        },
        {
            title: 'User Conduct',
            subtitle: 'While using Linkeby, you agree to',
            list: [
                {
                    text: 'Respect other users and communicate courteously.',
                    id: 1
                },
                {
                    text: 'Refrain from spamming, harassing, or engaging in any malicious activities.',
                    id: 2
                },
                {
                    text: 'Abide by the rules and guidelines set forth in these Terms.',
                    id: 3
                }
            ],
            id: 5
        },
        {
            title: 'Termination',
            subtitle: 'Linkeby reserves the right to terminate or suspend your account and access to the service at our discretion, with or without notice, for any reason, including if we believe you have violated these Terms or engaged in activities harmful to Linkeby or its users.',
            list: [],
            id: 6
        },
        {
            title: 'Dispute Resolution',
            subtitle: 'Any disputes arising from your use of Linkeby shall be resolved through arbitration or mediation, in accordance with the dispute resolution process outlined in our separate Dispute Resolution Policy (if applicable).',
            list: [],
            id: 7
        },
        {
            title: 'Limitation of Liability',
            subtitle: 'Linkeby and its affiliates, partners, and employees shall not be liable for any damages, losses, or liabilities arising from your use of the service. You use Linkeby at your own risk.',
            list: [],
            id: 8
        },
    ])

    // Scroll to top when component renders
    useEffect(()=>{
        window.scrollTo(0, 0)
    }, [])

    return(
        <div className="terms">
            <Helmet>
                <title>Linkeby | Terms of use</title>
                <meta name="description" content="Terms and conditions of using Linkeby" />
            </Helmet>
            <Nav />

            <div className="terms-wrapper">
                <div className="terms-sidebar">
                    {terms.map( term => <a href={`#${term.title}`} key={term.id}>{term.title}</a> )}
                </div>

                <div className="terms-content">
                    <h1>Linkeby terms of use</h1>                
                    <p>Welcome to Linkeby,<br></br>
                    These Terms and Conditions govern your use of Linkeby and its related services. By accessing or using Linkeby, you agree to comply with and be bound by these Terms. If you do not agree to these Terms, please do not use the service.
                    If you are using Linkeby on behalf of an organization, you also represent that you have the authority to bind the organization to these Terms.</p>
                    <small>Last updated: 24/3/2023</small>

                    {terms.map( term => <TermsSingle key={term.id} item={term} />)}
                </div>
            </div>

            <Footer />
        </div>
    )
}

export { Terms }