import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet-async'

import './subscription-plans.css'

import SubscriptionPlansOptions from './SubscriptionPlansOptions'
import Nav from '../../Nav/Nav'
import SubscriptionPlansTop from './SubscriptionPlansTop'
import Footer from '../../Footer/Footer'

const SubscriptionPlans = () =>{
    const [activePlan, setActivePlan] = useState("monthly")

    // Scroll to top when component renders
    useEffect(()=>{
        window.scrollTo(0, 0)
    }, [])

    return(
        <div className="subscription-plans">
            <Helmet>
                <title>Linkeby | Pricing and plans</title>
                <meta name='description' content='Pricing and plans with Linkeby' />
            </Helmet>

            <Nav />
            <h1>Choose Your Plan</h1>
            <div className="subscription-plans-bg"></div>
            <SubscriptionPlansTop activePlan={activePlan} setActivePlan={setActivePlan} />
            <SubscriptionPlansOptions activePlan={activePlan} />

            <Footer />
        </div>
    )
}

export default SubscriptionPlans