import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import { setActiveConnection, setShowChat } from '../../../../../store/reducers/backlinksReducer'
import { handleMessageOpen } from "../../../../../store/reducers/messageReducer"
import messagesService from "../../../../../services/messagesService"
import { config } from "../../../../../apiConfig"

import { BsFillChatDotsFill } from 'react-icons/bs'
import { BiError } from 'react-icons/bi'

export default function BacklinksTableItemSingleChat ({ connection }){
    // Store
    const dispatch = useDispatch()
    const currentUser = useSelector(({ currentUser }) => currentUser)
    const conversations = useSelector(({ conversations }) => conversations.userConversations)
    const messages = useSelector(({ messages }) => messages.conversationMessages)

    // Local state
    const [processing, setProcessing] = useState(false)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [newMessagesCount, setNewMessagesCount] = useState(0)

    // Find the conversation that matches the connection
    const foundConversation = conversations.find( conversation =>
        (conversation.targetWebsite?.id === connection.targetWebsite?.id)
        && (conversation.sourceWebsite?.id === connection.sourceWebsite?.id)
    )

    // Handle open chat button click for each connection
    const handleChatOpen = () =>{
        // Set processing state
        setProcessing(true)

        // Change store status for opening chat and active connection
        dispatch(setActiveConnection(connection))
        dispatch(setShowChat(true))

        // Set all messages as opened
        dispatch(handleMessageOpen(foundConversation.id, config(currentUser.token)))
    
        // Reset processing state
        setProcessing(false)
    }

    // Get the number of new messages for this connection / conversation
    useEffect(()=>{
        let isMounted = true

        // Function to get the data
        async function getNewMessagesCount (){
            try {
                // Send request for this conversation
                const response = await messagesService.getNewMessagesCount(foundConversation.id, config(currentUser.token))
                if(isMounted){
                    setNewMessagesCount(response.data)
                    setLoading(false)
                }
            } catch (error) {
                if(isMounted){
                    setError(error.message)
                    setLoading(false)
                }
            }
        }
        getNewMessagesCount()

        // Cleanup
        return () =>{ isMounted = false }
    }, [messages])

    // Return cases for error and loading
    if(error) return <div><BiError style={{ color: 'red' }} /></div>
    if(loading) return <p>...</p>
    if(connection.approved === "pending") return <div><BsFillChatDotsFill className="backlinks-table-items-icon-disabled" /></div>

    return(
        <div>
            <button 
                onClick={handleChatOpen} 
                className="backlinks-table-items-single-chat-btn"
                disabled={processing}
            >
                <BsFillChatDotsFill />

                {newMessagesCount !== 0 &&
                <span className="backlinks-table-items-single-chat-btn-span">
                    {newMessagesCount}
                </span>}
            </button>
        </div>
    )
}
