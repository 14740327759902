import { Link } from 'react-router-dom'
import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import './forgot-password.css'

import lock from '../../resources/lock.png'

import ForgotPasswordForm from './ForgotPasswordForm'

const ForgotPassword = () =>{
    // State
    const [completeEmail, setCompleteEmail] = useState("")
    const [showComplete, setShowComplete] = useState(false)

    return(
        <div className="forgot-password-wrapper">
            <Helmet>
                <title>Forgot your password</title>
                <meta name='description' content='Next steps if you forgot your password' />
            </Helmet>

            <div className="forgot-password-top">
                <div className='forgot-password-icon'>
                    <img src={lock} alt="lock" />
                </div>

                <p>Trouble with you account?</p>
                <small>Enter your email address below and we'll send you a link to reset your password.</small>
            </div>

            <ForgotPasswordForm setCompleteEmail={setCompleteEmail} setShowComplete={setShowComplete} />

            <div className="forgot-password-bottom">
                <p>OR</p>
                <Link to="/register" className='forgot-password-new-link'>Create new account</Link>
            </div>

            {showComplete? 
            <div className="forgot-password-complete">
                <p>Email sent</p>
                <small>We've sent you an email {completeEmail} with a link to get back into your account.</small>
                <button className='forgot-password-complete-btn' onClick={()=>{setShowComplete(false)}}>OK</button>
            </div>
            : ""}
        </div>
    )
}

export default ForgotPassword