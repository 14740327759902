import { useEffect, useState } from "react"

import { loadStripe } from "@stripe/stripe-js"
import { Elements } from "@stripe/react-stripe-js"
import paymentService from "../../../../services/paymentService"

import { Spinner } from "../../../../common/Spinner"

import { RegisterPaymentSuccessIntent } from "./RegisterPaymentSuccessIntent"

const RegisterPaymentSuccess = () =>{
    // Local state
    const [stripePromise, setStripePromise] = useState(null)

    // Get client secret from return url
    const clientSecret = new URLSearchParams(window.location.search).get(
        'payment_intent_client_secret'
    )
    
    // Get stripe keys from server
    useEffect(()=>{
        let isMounted = true
        
        // Async function to load keys and initialize stripe
        async function initializeStripe (){
            try {
                const response = await paymentService.getStripeKey()

                if(isMounted){
                    setStripePromise(loadStripe(response.data.publishableKey))
                }
            } catch (error) {
                if(isMounted){
                    console.log(error.name)
                }
            }
        }
        // Run function
        initializeStripe()

        // Cleanup effect
        return () => { isMounted = false }
    }, [])

    if(!clientSecret || !stripePromise) return <Spinner />

    return(
        <Elements stripe={stripePromise}>
            <RegisterPaymentSuccessIntent clientSecret={clientSecret} />
        </Elements>
    )
}

export { RegisterPaymentSuccess }