import emailService from '../../../services/emailService'

import { Tooltip } from '../../../common/Tooptip/Tooltip'

export default function AdminSidebarWaitlistButton (){
    const handleClick = async () =>{
        try {
            const emailResponse = await emailService.launchUpdateEmail()
            
            // Check if email was sent successfully
            if(emailResponse.data.status !== 200){
                alert(emailResponse.data.details)
            } else{
                alert("Emails sent successfully!")
            }
        } catch (error) {
            console.log(error.message)
        }
    }

    return(
        <Tooltip text="Send email to all waitlist users" direction="top">
            <button className='waitlist-btn' onClick={handleClick}>Send waitlist</button>
        </Tooltip>
    )
}