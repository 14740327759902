import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { clearCurrentUser } from '../../store/reducers/currentUserReducer'
import { clearUserWebsites } from '../../store/reducers/websiteReducer'
import { resetActiveButton } from '../../store/reducers/sidebarReducer'

import {BiLogOut} from 'react-icons/bi'

const LogoutButton = () =>{
    // Store
    const dispatch = useDispatch()

    // Navigate
    const navigate = useNavigate()

    // Handle logout
    const handleLogout = () =>{
        // Dispatch actions
        dispatch(clearCurrentUser())
        dispatch(clearUserWebsites())
        dispatch(resetActiveButton())

        // Clear localstorage and session
        localStorage.removeItem('currentUser')
        sessionStorage.removeItem('token')

        // Navigate to main page
        navigate("/", {replace: true})
    }

    return(
        <button className='nav-options-btn' onClick={handleLogout}><BiLogOut /> Sign out</button>
    )
}

export { LogoutButton }