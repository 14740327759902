import { Link } from "react-router-dom"

import { AiOutlineCheck, AiOutlineCrown } from 'react-icons/ai'

const SubscriptionPlansOptionsSingle = ({ plan, activePlan }) =>{  
    return(
        <div className='subscription-plans-options-single'>
            <div className={`${plan.active? "subscription-plans-options-single-container-active": "subscription-plans-options-single-container"}`}>
                <div className="subscription-plans-options-single-top">
                    <AiOutlineCrown className='subscription-plans-options-single-icon' />
                    <h5>{plan.name}</h5>

                    <div className="subscription-plans-options-single-list">
                        {plan.details.map( (detail, i) =>
                            <li key={i}>
                                <AiOutlineCheck className="subscription-plans-options-single-list-icon" /> 
                                {detail}
                            </li>
                        )}
                    </div>
                </div>

                <div className="subscription-plans-options-single-bottom">
                    {activePlan==="monthly"? <h6>{plan.monthlyPrice}<span>/mo</span></h6>: <h6>{plan.yearlyPrice}<span>/yr</span></h6>}
                    
                    <div className="subscription-plans-options-single-bottom-btns">
                        <Link to="/register/plans" className="subscription-plans-options-single-free-btn">Free Trial</Link>
                        <Link to="/register/plans" className="subscription-plans-options-single-btn">Buy now</Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubscriptionPlansOptionsSingle