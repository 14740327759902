import { useMemo } from "react"
import { useSelector } from "react-redux"

import { IoAlert } from "react-icons/io5";

const SettingsSidebarWebsiteOption = ({ activeSetting, setActiveSetting, selectedWebsite, setSelectedWebsite, state }) =>{
    // store
    const websites = useSelector(({websites}) => websites.userWebsites )

    // Handle button click
    const handleClick = () =>{
        setActiveSetting("websites")
        setSelectedWebsite(state)
    }

    // Find which website corresponds to this url
    const websiteToShow = useMemo(()=>{
        return websites.find( website => website.url === state)
    }, [websites])

    return(
        <button 
            onClick={handleClick}
            className={`${activeSetting==="websites" && selectedWebsite===state? "settings-sidebar-website-option-active": "settings-sidebar-website-option"}`}
        >
            {state}
            <small>View details</small>
            {(websiteToShow.niche.length === 0 || websiteToShow.languageOfWebsite.length === 0) && <div className="profile-sidebar-options-btn-alert"><IoAlert /></div>}
        </button>
    )
}   

export { SettingsSidebarWebsiteOption }