import { createSlice } from "@reduxjs/toolkit";
import notificationsService from "../../services/notificationsService"

const initialState = {
    notifications: [],
    loading: false,
    error: null
}
 
// Slice
const notificationSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers:{
        setNotifications (state, action){
            state.notifications = action.payload
        },
        postNotification(state, action){
            state.notifications = [...state.notifications, action.payload]
        },
        deleteNotification(state, action){
            state.notifications = state.notifications.filter( n => n.id !== action.payload.id )
        },
        setNotificationsOpen(state, action){
            state.notifications = action.payload
        },
        setLoading(state, action){
            state.loading = action.payload
        },
        setError(state,action){
            state.error = action.payload
        }
    }
})

export const { setNotifications, postNotification, deleteNotification, setNotificationsOpen, setError, setLoading } = notificationSlice.actions

// Handle initializing notification
export const initializeNotifications = (id, config) =>{
    return async dispatch => {
        dispatch(setLoading(true))

        try {
            const response = await notificationsService.getUserNotifications(id, config)
            dispatch(setNotifications(response.data))
            dispatch(setLoading(false))
        } catch (error) {
            dispatch(setError(error.message))
        }
    }
}

// Post new notification
export const handlePostNotification = (data) =>{
    return async dispatch =>{
        try {
            const response = await notificationsService.postNotification(data)
            dispatch(postNotification(response.data))
        } catch (error) {
            dispatch(setError(error.name))
        }
    }
}

// Handle notifications opened
export const handleNotificationsOpen = (config) =>{
    return async dispatch =>{
        try {
            const response = await notificationsService.setNotificationOpen({}, config)
            dispatch(setNotificationsOpen(response.data))
        } catch (error) {
            setError(error.message)
        }
    }
}

// Handle notification delete
export const handleNotificationDelete = (id, config) =>{
    return async dispatch =>{
        try {
            const response = await notificationsService.deleteNotification(id, config)
            dispatch(deleteNotification(response.data))
        } catch (error) {
            dispatch(setError(error.message))
        }
    }
}

export default notificationSlice.reducer
