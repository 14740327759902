import './admin-sidebar.css'

import {ImDatabase} from 'react-icons/im'
import {VscLaw} from 'react-icons/vsc'
import {MdSupportAgent} from 'react-icons/md'
import {RiFileAddFill} from 'react-icons/ri'
import {ImList} from 'react-icons/im'
import AdminSidebarWaitlistButton from './AdminSidebarWaitlistButton'


const AdminSidebar = ({option, setOption}) =>{
    return(
        <div className="admin-sidebar">
            <div className="admin-sidebar-bottom">
                <h1>Users</h1>
                <button
                    onClick={()=>{setOption("new-websites")}}
                    className={`${option==="new-websites"? "admin-sibebar-btn-active": "admin-sidebar-btn"}`}
                >
                    <RiFileAddFill /> New Websites
                </button>
                <button
                    onClick={()=>{setOption("database")}}
                    className={`${option==="database"? "admin-sibebar-btn-active": "admin-sidebar-btn"}`}
                >
                    <ImList /> Database
                </button>
                <button
                    onClick={()=>{setOption("")}}
                    className={`${option===""? "admin-sibebar-btn-active": "admin-sidebar-btn"}`}
                >
                    <ImDatabase /> Database Export
                </button>
                <button
                    onClick={()=>{setOption("disputes")}}
                    className={`${option==="disputes"? "admin-sibebar-btn-active": "admin-sidebar-btn"}`}
                >
                    <VscLaw /> Disputes
                </button>
                <button
                    onClick={()=>{setOption("support")}}
                    className={`${option==="support"? "admin-sibebar-btn-active": "admin-sidebar-btn"}`}
                >
                    <MdSupportAgent /> Support tickets
                </button>

                <AdminSidebarWaitlistButton />
            </div>
        </div>
    )
}

export default AdminSidebar